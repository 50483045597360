import { API_URL, publicSite } from "../config";

export const homeUrl = "/";
export const catalogOfCompaniesUrl = "/catalog-of-companies";
export const siteArticlesUrl = "/site-articles";
export const aboutUrl = "/about";
export const contactsUrl = "/contacts";
export const addPostUrl = "/add-post";
export const lookingForAJobUrl = "/looking-for-job";
export const iOfferAVacancyUrl = "/оffer-vacancy";
export const rentUrl = "/rent";
export const carrierUrl = "/carrier";
export const privateAnnouncementUrl = "/privateAnnouncementUrl";
export const permitWorkUrl = "/permit-work";
export const addBlackListUrl = "/black-list-add";
export const complaintSentBlackListUrl = "/complaint-sent";
export const blackListUrl = "/black-list";
export const questionnaireAddUrl = "/questionnaire-add";
export const questionnairesListUrl = "/questionnaires";
export const termsOfUseUrl = "/terms-of-use";
export const publicationRulesUrl = "/publication-rules";
export const faqUrl = "/faq";
export const loginUrl = "/login";
export const registrationUrl = "/registration";
export const myOfficeUrl = "/my-office";
export const forgotPasswordUrl = "/forgot-password";
export const restorePasswordUrl = "/restore-password";
export const authorizationEmailUrl = "/authorization-email";
export const subscriptionToSiteNewsUrl = "/my-office/subscription-to-site-news";
export const adminUrl = "/admin";
export const advertisementUrl = "/advertisement";
export const surveyUrl = "/survey";
export const companyUrl = "/company";
export const adminSettingTelegramChannelUrl = `${adminUrl}/setting-telegram-channel`;
export const adminListLinksUrl = `${adminUrl}/list-links`;
export const adminImportBlackListUrl = `${adminUrl}/import/black-list`;

export const getSiteArticleUrl = (siteArticleId) =>
  `/site-article/${siteArticleId}`;
export const getComplaintSentBlackListUrl = (code) => `/complaint-sent/${code}`;
export const getPostUrl = (postId, seo) => `/${seo}/${postId}.html`;
export const getQuestionnaireUrl = (questionnaireId) =>
  `/questionnaire/${questionnaireId}`;
export const getGlobalPostUrl = (postId, seo) =>
  `${publicSite}${getPostUrl(postId, seo)}`;

export const apiUrl = API_URL;

const routes = {
  homeUrl,
  apiUrl,
  catalogOfCompaniesUrl,
  aboutUrl,
  contactsUrl,
  getPostUrl,
  lookingForAJobUrl,
  iOfferAVacancyUrl
};

export default routes;
