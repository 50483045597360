/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { pageSize } from "../../../graphql/enum";
import { getOffset } from "../../../utility";
import Page from "../../layout/Page";
import PostTable from "../../layout/PostTable";
import Sidebar from "./SideBar";

export const postsQuery = gql`
  query posts($filters: PostFilters, $pagination: PaginationInput) {
    posts {
      total(filters: $filters)
      data(pagination: $pagination, filters: $filters) {
        id
        title
        seo
        type
        city {
          id
          title
        }
        expireInPriorityDate
        priority
      }
    }
  }
`;
const UserOfficeDashboard = ({ tabsHeader }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const parameters = new URLSearchParams(window.location.search);
  const paid = parameters.getAll("paid");
  const free = parameters.getAll("free");

  const defaultFilter = {
    paid,
    free
  };

  const [filters, setFilters] = useState(defaultFilter);
  const [totalPosts, setTotalPosts] = useState(0);

  const search = parameters.getAll("search")[0];

  const { loading, data, refetch } = useQuery(postsQuery, {
    variables: {
      filters: {
        search: search || null,
        isOwner: true,
        isPaid: filters.paid.length ? true : null,
        isFree: filters.free.length ? true : null
      },
      pagination: {
        limit: pageSize,
        offset: getOffset({ currentPage, pageSize })
      }
    }
  });
  useEffect(() => {
    if (data?.posts?.data) {
      setTotalPosts(data.posts.total);
    }
  }, [data?.posts?.data]);

  return (
    <Page
      sidebar={{
        mobileTextButton: t("filter"),
        render: () => <Sidebar filters={filters} setFilters={setFilters} />
      }}
      beforeContent={tabsHeader}
      titleAfter={
        <p>
          {t("post.totalPosts")} {totalPosts}
        </p>
      }
      pageName={t("header.personalArea")}
    >
      <PostTable
        pageSize={pageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        posts={data?.posts?.data || []}
        total={data?.posts?.total || 0}
        loading={loading}
        refetch={refetch}
      />
    </Page>
  );
};

UserOfficeDashboard.propTypes = {
  tabsHeader: PropTypes.any
};
export default UserOfficeDashboard;
