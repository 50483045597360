import { gql } from "@apollo/client";

const createQuestion = gql`
  mutation createQuestion($input: CreateQuestionInput) {
    createQuestion(input: $input) {
      success
      code
    }
  }
`;

export default createQuestion;
