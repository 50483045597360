/* eslint-disable max-statements */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  homeUrl,
  questionnaireAddUrl,
  questionnairesListUrl
} from "../../../routes";
import Link from "../../layout/Link";
import Page from "../../layout/Page";
import QuestionnairesListSideBar from "./QuestionnairesListSideBar";
import QuestionnairesListTable from "./QuestionnairesTable";

const QuestionnairesList = () => {
  const { t } = useTranslation();
  const parameters = new URLSearchParams(window.location.search);

  const cityId = parameters.getAll("cityId")[0];
  const documentsForWork = parameters.getAll("documentsForWork");
  const isCar = parameters.getAll("isCar");
  const isKnowledgeOfEnglish = parameters.getAll("isKnowledgeOfEnglish");
  const sex = parameters.getAll("sex");
  const isNonSmoker = parameters.getAll("isNonSmoker");
  const isTool = parameters.getAll("isTool");
  const isWomen = parameters.getAll("isWomen");
  const isWorksOnWeekends = parameters.getAll("isWorksOnWeekends");
  const isAssistantMaster = parameters.getAll("isAssistantMaster");
  const isDriverLicense = parameters.getAll("isDriverLicense");
  const lookingForJobForCouple = parameters.getAll("lookingForJobForCouple");
  const lookingForJobForWomen = parameters.getAll("lookingForJobForWomen");
  const postIndexIds = parameters.getAll("postIndexIds");
  const professionIds = parameters.getAll("professionIds");
  const categoryProfessionId = parameters.getAll("categoryProfessionId");
  const currentPage = parameters.getAll("currentPage")[0];

  const search = parameters.getAll("search")[0];

  const defaultFilter = {
    cityId,
    documentsForWork,
    isCar,
    isKnowledgeOfEnglish,
    sex,
    isNonSmoker,
    isTool,
    isWomen,
    isWorksOnWeekends,
    isDriverLicense,
    isAssistantMaster,
    lookingForJobForCouple,
    lookingForJobForWomen,
    professionIds,
    search,
    postIndexIds,
    categoryProfessionId,
    currentPage: parseInt(currentPage, 10) || 1
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [totalPosts, setTotalPosts] = useState(0);

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "questionnaireSingle",
      title: t("header.questionnairesOfWorkersUrl"),
      link: questionnairesListUrl
    }
  ];
  return (
    <Page
      pageName={t("header.questionnairesOfWorkersUrl")}
      breadcrumbs={breadcrumbs}
      isClassNamePageSingle={false}
      headerRight="dsfgs"
      titleAfter={
        <Link to={questionnaireAddUrl} className="btn btn-primary">
          {t("questionnaire.addYourCV")}
        </Link>
      }
      sidebar={{
        mobileTextButton: t("filter"),
        render: () => (
          <QuestionnairesListSideBar
            filters={filters}
            totalPosts={totalPosts}
            setFilters={setFilters}
          />
        )
      }}
    >
      <QuestionnairesListTable
        {...{
          filters,
          currentPage: filters.currentPage,
          setCurrentPage: (newPage) => {
            setFilters({ ...filters, currentPage: newPage });
          },
          setTotalPosts
        }}
      />
    </Page>
  );
};

QuestionnairesList.propTypes = {};

QuestionnairesList.defaultProps = {};
export default QuestionnairesList;
