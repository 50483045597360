import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { homeUrl, permitWorkUrl } from "../../routes";
import Page from "../layout/Page";

const PermitWork = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "permitWork",
      title: t("permitWork.title"),
      link: permitWorkUrl
    }
  ];
  return (
    <Page pageName={t("permitWork.title")} breadcrumbs={breadcrumbs}>
      <div className="page-section page-permit-work">
        <div className="page-image-left">
          <img src="/assets/img/permit-work-image.jpg" alt="" />
        </div>

        <h4>{t("permitWork.title")}</h4>
        <p>{t("aboutTheProject.text")}</p>
        <h6>{t("permitWork.title")}</h6>
        <p>{t("aboutTheProject.text")}</p>
        <p>{t("aboutTheProject.text")}</p>
        <p>{t("aboutTheProject.text")}</p>
      </div>
    </Page>
  );
};

PermitWork.propTypes = {
  filter: PropTypes.object
};
export default PermitWork;
