/* eslint-disable max-lines */
/* eslint-disable complexity */
import PropTypes from "prop-types";
import { useState } from "react";

import { classNames } from "../../../../utility";

const QuestionnaireDropdownBlock = ({
  title,
  defaultIsShow = true,
  children,
  isDisplay = true
}) => {
  const [isShow, setIsShow] = useState(defaultIsShow);

  return isDisplay ? (
    <div
      className={classNames("accordion-my-resume", isShow ? "active" : null)}
    >
      <div
        className="accordion-title-my-resume"
        onClick={() => setIsShow(!isShow)}
      >
        <h4>{title}</h4>
      </div>
      <div className="accordion-content-my-resume">{children}</div>
    </div>
  ) : null;
};

QuestionnaireDropdownBlock.propTypes = {
  title: PropTypes.string,
  defaultIsShow: PropTypes.bool,
  children: PropTypes.node,
  isDisplay: PropTypes.bool
};
export default QuestionnaireDropdownBlock;
