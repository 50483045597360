/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { contactsUrl } from "../../../routes";
import DropDownBlock from "../../layout/DropDownBlock";
import LinkView from "../../layout/Link";

const DoneResponseForQestions = ({ setIsShowNoRegistration }) => {
  const { t } = useTranslation();

  const doneResponseForQestionsStub = [
    {
      title: t("faq.title_0"),
      description: (
        <ul>
          <li>{t("faq.description_0_0")}</li>
          <li>
            {t("faq.description_0_1")}
            <a
              onClick={() => {
                setIsShowNoRegistration(true);
              }}
              href="#" // to={myOfficeUrl}
            >
              {t("faq.description_0_2")}
            </a>
            {t("faq.description_0_3")}
            <a
              onClick={() => {
                setIsShowNoRegistration(true);
              }}
              href="#" // to={registrationUrl}
            >
              {t("faq.description_0_4")}
            </a>
            {t("faq.description_0_5")}
          </li>
        </ul>
      )
    },
    {
      title: t("faq.title_1"),
      description: (
        <ul>
          <li>{t("faq.description_1_0")}</li>
          <li>
            <Trans
              i18nKey="faq.description_1_1"
              tOptions={{
                input_1: t("faq.description_1_1_1"),
                input_2: t("faq.description_1_1_2")
              }}
              components={{
                linkToMyOffice: (
                  <a
                    onClick={() => {
                      setIsShowNoRegistration(true);
                    }}
                    href="#" // to={myOfficeUrl}
                  />
                ),
                linkToContacts: <LinkView to={contactsUrl} />
              }}
            />
          </li>
        </ul>
      )
    },
    {
      title: t("faq.title_2"),
      description: (
        <>
          {t("faq.description_2_0")}
          <br />
          {t("faq.description_2_1")}
          <ul>
            <li>{t("faq.description_2_2_1")}</li>
            <li>{t("faq.description_2_2_2")}</li>
            <li>{t("faq.description_2_2_3")}</li>
            <li>{t("faq.description_2_2_4")}</li>
            <li>{t("faq.description_2_2_5")}</li>
            <li>{t("faq.description_2_2_6")}</li>
          </ul>
        </>
      )
    },
    {
      title: t("faq.title_3"),
      description: (
        <>
          {t("faq.description_3_0")}
          <br />
          {t("faq.description_3_1")}
          <br />

          {t("faq.description_3_2")}
        </>
      )
    },
    {
      title: t("faq.title_4"),
      description: (
        <>
          {t("faq.description_4_0")}
          <br />
          {t("faq.description_4_1")}
        </>
      )
    },
    {
      title: t("faq.title_5"),
      description: (
        <ul>
          <li>
            <Trans
              i18nKey="faq.description_5_0"
              tOptions={{
                input_1: t("faq.description_5_0_1")
              }}
              components={{
                linkRegistration: (
                  <a
                    onClick={() => {
                      setIsShowNoRegistration(true);
                    }}
                    href="#" // to={registrationUrl}
                  />
                )
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="faq.description_5_1"
              tOptions={{
                input_1: t("faq.description_5_1_1")
              }}
              components={{
                linkToContacts: <LinkView to={contactsUrl} />
              }}
            />
          </li>
        </ul>
      )
    },
    {
      title: t("faq.title_6"),
      description: <>{t("faq.description_6_0")}</>
    },
    {
      title: t("faq.title_7"),
      description: (
        <Trans
          i18nKey="faq.description_7_0"
          tOptions={{
            input_1: t("faq.description_7_0_1")
          }}
          components={{
            linkToContacts: <LinkView to={contactsUrl} />
          }}
        />
      )
    },
    {
      title: t("faq.title_8"),
      description: (
        <Trans
          i18nKey="faq.description_8_0"
          tOptions={{
            input_1: t("faq.description_8_0_1")
          }}
          components={{
            linkToContacts: <LinkView to={contactsUrl} />
          }}
        />
      )
    }
  ];

  return (
    <>
      <div className="page-section">
        <h4>{t("contacts.readyAnswersToFrequentlyAskedQuestions")}</h4>
        <DropDownBlock array={doneResponseForQestionsStub} />
      </div>
    </>
  );
};

DoneResponseForQestions.propTypes = {
  setIsShowNoRegistration: PropTypes.func
};
export default DoneResponseForQestions;
