import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Maps from "../../Maps";

const MapsBlock = ({ address }) => {
  const { t } = useTranslation();
  if (!address || !Object.keys(address).length) return null;
  return (
    <div className="page-preview-wrap page-preview-wrap__map">
      <label className="preview-label">{t("post.addressOnTheMap")}</label>
      <Maps coordinates={address} />
    </div>
  );
};

MapsBlock.propTypes = {
  address: PropTypes.object
};
export default MapsBlock;
