import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { handlePhobePress } from "../../../../../../utility";
import Hint from "../../../../../layout/Hint";
import Input from "../../../../../layout/Input/Input";

const Field = ({ errors, value, valuesFormik, setValuesFormik }) => {
  const { t } = useTranslation();

  return (
    <Input
      type="tel"
      id="inputPhone"
      name="phone"
      placeholder="+"
      error={errors?.phone}
      required="1"
      maxLength="13"
      value={value}
      onKeyUp={handlePhobePress({ valuesFormik, setValuesFormik })}
      label={
        <>
          {t("createPost.phone")}
          <Hint components={t("hint.phone")} />
        </>
      }
    />
  );
};

Field.propTypes = {
  errors: PropTypes.object,
  value: PropTypes.string,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};
export default Field;
