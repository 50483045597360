import ImportBlackList from "../../../components/Admin/Imports/BlackList";
import Layout from "../../Layout/Layout";

const ImportBlackListAdminView = () => (
  <Layout>
    <ImportBlackList />
  </Layout>
);

export default ImportBlackListAdminView;
