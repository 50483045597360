import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import {
  aboutUrl,
  addBlackListUrl,
  addPostUrl,
  adminImportBlackListUrl,
  adminListLinksUrl,
  adminSettingTelegramChannelUrl,
  authorizationEmailUrl,
  blackListUrl,
  carrierUrl,
  catalogOfCompaniesUrl,
  contactsUrl,
  faqUrl,
  forgotPasswordUrl,
  getComplaintSentBlackListUrl,
  getPostUrl,
  getQuestionnaireUrl,
  getSiteArticleUrl,
  homeUrl,
  iOfferAVacancyUrl,
  loginUrl,
  lookingForAJobUrl,
  myOfficeUrl,
  permitWorkUrl,
  privateAnnouncementUrl,
  publicationRulesUrl,
  questionnaireAddUrl,
  questionnairesListUrl,
  registrationUrl,
  rentUrl,
  restorePasswordUrl,
  siteArticlesUrl,
  subscriptionToSiteNewsUrl,
  termsOfUseUrl
} from "./routes";
import {
  AuthorizedAppRoute,
  NotAuthorizedAppRoute,
  PublicAppRoute
} from "./routes/AppRoutes/AppRoutes";
import views from "./views";
import viewsAdmin from "./views/Admin";

const {
  Home,
  CreatePost,
  Post,
  LookingJob,
  OfferVacancy,
  RentProperty,
  Carrier,
  Announcements,
  Contacts,
  About,
  PermitWork,
  AddBlackList,
  ComplaintSentBlackList,
  BlackList,
  QuestionnaireCreate,
  QuestionnaireSingle,
  QuestionnairesList,
  Login,
  SiteArticles,
  SiteArticle,
  TermsOfUse,
  FAQ,
  PublicationRules,
  Registration,
  ForgotPassword,
  RestorePassword,
  AuthorizationEmail,
  UserOffice,
  CatalogOfCompanies
} = views;

const { CategoryWithTelegramChannel, ListLinks, ImportBlackList } = viewsAdmin;

const AppRouter = () => (
  <Router>
    <Switch>
      {/* AUTHORIZED USER ROUTES */}
      <NotAuthorizedAppRoute
        exact
        path={adminSettingTelegramChannelUrl}
        component={CategoryWithTelegramChannel}
      />
      <NotAuthorizedAppRoute
        exact
        path={adminImportBlackListUrl}
        component={ImportBlackList}
      />
      <NotAuthorizedAppRoute
        exact
        path={adminListLinksUrl}
        component={ListLinks}
      />
      <PublicAppRoute exact path={homeUrl} component={Home} />
      <PublicAppRoute exact path={addPostUrl} component={CreatePost} />
      <PublicAppRoute path={siteArticlesUrl} component={SiteArticles} />
      <PublicAppRoute
        path={getSiteArticleUrl(":siteArticleId")}
        component={SiteArticle}
      />
      <PublicAppRoute
        path={getComplaintSentBlackListUrl(":code")}
        component={ComplaintSentBlackList}
      />
      <PublicAppRoute
        path={getQuestionnaireUrl(":questionnaireId")}
        component={QuestionnaireSingle}
      />
      <PublicAppRoute path={getPostUrl(":postId", ":seo")} component={Post} />

      <PublicAppRoute
        exact
        path={privateAnnouncementUrl}
        component={Announcements}
      />
      <PublicAppRoute exact path={iOfferAVacancyUrl} component={OfferVacancy} />
      <PublicAppRoute exact path={lookingForAJobUrl} component={LookingJob} />
      <PublicAppRoute exact path={rentUrl} component={RentProperty} />
      <PublicAppRoute exact path={carrierUrl} component={Carrier} />
      <PublicAppRoute exact path={contactsUrl} component={Contacts} />
      <PublicAppRoute exact path={aboutUrl} component={About} />
      <PublicAppRoute exact path={termsOfUseUrl} component={TermsOfUse} />
      <PublicAppRoute exact path={faqUrl} component={FAQ} />
      <PublicAppRoute
        exact
        path={publicationRulesUrl}
        component={PublicationRules}
      />
      <PublicAppRoute exact path={permitWorkUrl} component={PermitWork} />
      <PublicAppRoute exact path={addBlackListUrl} component={AddBlackList} />
      <PublicAppRoute exact path={blackListUrl} component={BlackList} />

      <PublicAppRoute
        exact
        path={questionnairesListUrl}
        component={QuestionnairesList}
      />
      <PublicAppRoute
        exact
        path={questionnaireAddUrl}
        component={QuestionnaireCreate}
      />
      <PublicAppRoute
        exact
        path={catalogOfCompaniesUrl}
        component={CatalogOfCompanies}
      />
      <AuthorizedAppRoute exact path={loginUrl} component={Login} />
      <AuthorizedAppRoute
        exact
        path={registrationUrl}
        component={Registration}
      />
      <AuthorizedAppRoute
        exact
        path={forgotPasswordUrl}
        component={ForgotPassword}
      />
      <PublicAppRoute
        exact
        path={restorePasswordUrl}
        component={RestorePassword}
      />
      <AuthorizedAppRoute
        exact
        path={authorizationEmailUrl}
        component={AuthorizationEmail}
      />
      <NotAuthorizedAppRoute exact path={myOfficeUrl} component={UserOffice} />
      <NotAuthorizedAppRoute
        exact
        path={subscriptionToSiteNewsUrl}
        component={null}
      />
    </Switch>
  </Router>
);

export default AppRouter;
