import CategoryWithTelegramChannel from "../../../components/Admin/Setting/CategoryWithTelegramChannel";
import Layout from "../../Layout/Layout";

const CategoryWithTelegramChannelView = () => (
  <Layout>
    <CategoryWithTelegramChannel />
  </Layout>
);

export default CategoryWithTelegramChannelView;
