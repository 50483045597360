import PropTypes from "prop-types";

import { typeField } from "../../../../../graphql/enum";
import ListInformationPosteElement from "../ListInformationPosteElement";

const LookingJobListInformation = ({ allPost, post }) => (
  <ul className="page-single-list page-single-list-half">
    <ListInformationPosteElement
      element={post.categoryProfession}
      type={typeField.categoryProfession}
    />
    <ListInformationPosteElement
      element={post.professions}
      type={typeField.professions}
      allPost={allPost}
    />
    <ListInformationPosteElement element={post.city} type={typeField.city} />
    <ListInformationPosteElement
      element={allPost.postIndexs}
      type={typeField.postIndexs}
    />
    <ListInformationPosteElement
      element={post.workSchedule}
      type={typeField.workSchedule}
    />
    <ListInformationPosteElement
      element={post}
      type={typeField.ilookingForJob}
      allPost={allPost}
    />
    <ListInformationPosteElement
      element={post}
      type={typeField.forWorkThereAre}
      allPost={allPost}
    />
  </ul>
);

LookingJobListInformation.propTypes = {
  post: PropTypes.object,
  allPost: PropTypes.object
};
export default LookingJobListInformation;
