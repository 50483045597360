import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "../../../../../layout/Input/Input";

const Field = ({ errors, value }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-3">
        <Input
          label={<>{t("createPost.email")}</>}
          type="email"
          name="email"
          error={errors?.email}
          value={value}
          id="inputEmail"
          maxLength="50"
        />
      </div>
      <div className="form-check">
        <Input
          type="checkbox"
          id="inputSendEmail"
          name="isSendMessageEmail"
          label={t("createPost.sendApplicationsByEmail")}
        />
      </div>
    </>
  );
};

Field.propTypes = {
  errors: PropTypes.object,
  value: PropTypes.string
};
export default Field;
