import PropTypes from "prop-types";

import { typeField } from "../../../../../graphql/enum";
import ListInformationPosteElement from "../ListInformationPosteElement";

const RentPropertyListInformation = ({ post, allPost }) => (
  <ul className="page-single-list page-single-list-half">
    <ListInformationPosteElement element={post.city} type={typeField.city} />
    <ListInformationPosteElement
      element={post}
      type={typeField.paymentPeriodRent}
    />
    <ListInformationPosteElement
      element={allPost.postIndexs}
      type={typeField.postIndexs}
    />
    <ListInformationPosteElement
      element={post.postRentPropertyCategory}
      type={typeField.postRentPropertyCategory}
    />
    <ListInformationPosteElement
      element={post.typeRooms}
      type={typeField.typeRooms}
    />
    <ListInformationPosteElement
      element={post.numberOfRooms}
      type={typeField.numberOfRooms}
    />
    <ListInformationPosteElement
      element={post.numberOfBathroom}
      type={typeField.numberOfBathroom}
    />
    <ListInformationPosteElement
      element={post.isPropertyForSale}
      type={typeField.propertyForSale}
    />
    <ListInformationPosteElement element={post} type={typeField.wifi} />
    <ListInformationPosteElement
      element={post.isUtilitiesIncluded}
      type={typeField.utilitiesIncluded}
    />
  </ul>
);

RentPropertyListInformation.propTypes = {
  post: PropTypes.object,
  allPost: PropTypes.object
};
export default RentPropertyListInformation;
