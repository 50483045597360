import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { homeUrl, siteArticlesUrl } from "../../../routes";
import Loading from "../../layout/Loading/Loading";
import Page from "../../layout/Page";

export const siteArticlesQuery = gql`
  query siteArticles($siteArticlesIds: [ID]) {
    siteArticles(siteArticlesIds: $siteArticlesIds) {
      id
      title
      subTitle
      description
      image {
        src
      }
    }
  }
`;

const SiteArticle = () => {
  const { t } = useTranslation();
  const { siteArticleId } = useParams();
  const { loading, data } = useQuery(siteArticlesQuery, {
    variables: { siteArticlesIds: [siteArticleId] }
  });
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "siteArticles",
      title: t("header.siteArticle"),
      link: siteArticlesUrl
    }
  ];
  if (loading || !data?.siteArticles?.length) return <Loading />;
  const { title, subTitle, description, image } = data.siteArticles[0];
  return (
    <Page
      pageName={t("header.siteArticle")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="page-section page-about-project">
        <h4>{title}</h4>
        <div></div>
        <div className="articles-item__cat">{subTitle}</div>
        <div className="mb-5">{description}</div>
        <div> {image ? <img src={image?.src} alt="QR code" /> : null}</div>
      </div>
    </Page>
  );
};

SiteArticle.propTypes = {
  filter: PropTypes.object
};
export default SiteArticle;
