import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Loading from "../../../layout/Loading/Loading";
import Page from "../../../layout/Page";
import Item from "./Item";

export const allDataQuery = gql`
  query allData {
    professions {
      id
      title
    }
    postAnnouncementRubric {
      id
      title
    }
    postRentPropertyCategory {
      id
      title
    }
    categoryWithTelegramChannels {
      id
      professionId
      postRentPropertyCategoryId
      postAnnouncementRubricId
      isCarrier
      channelTelegram {
        id
        link
        name
        qrCodeImage {
          src
        }
      }
    }
  }
`;

const CategoryWithTelegramChannel = () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(allDataQuery);
  if (loading) return <Loading />;
  if (!data) return null;
  const types = {
    carrieris: "carrieris",
    announcementRubric: "announcementRubric",
    postRentPropertyCategory: "postRentPropertyCategory",
    profession: "profession"
  };
  return (
    <Page pageName={"Settings"} isClassNamePageSingle={false}>
      <Item
        isCarrier={true}
        title={"Для Перевізників"}
        channelTelegramData={
          data.categoryWithTelegramChannels.find(({ isCarrier }) => isCarrier)
            ?.channelTelegram
        }
      />

      <h1>
        {" "}
        -----------------------------------------------------------------------
      </h1>
      {data.professions.map((profession, i) => (
        <Item
          professionId={profession.id}
          type={types.profession}
          title={t(`profession.${profession.title}`)}
          key={i}
          channelTelegramData={
            data.categoryWithTelegramChannels.find(
              ({ professionId }) => profession.id === professionId
            )?.channelTelegram
          }
        />
      ))}
      <h1>
        {" "}
        -----------------------------------------------------------------------
      </h1>
      {data.postAnnouncementRubric.map((r, i) => (
        <Item
          postAnnouncementRubricId={r.id}
          type={types.announcementRubric}
          title={t(`announcementRubric.${r.title}`)}
          key={i}
          channelTelegramData={
            data.categoryWithTelegramChannels.find(
              ({ postAnnouncementRubricId }) =>
                r.id === postAnnouncementRubricId
            )?.channelTelegram
          }
        />
      ))}
      <h1>
        {" "}
        -----------------------------------------------------------------------
      </h1>
      {data.postRentPropertyCategory.map((r, i) => (
        <Item
          postRentPropertyCategoryId={r.id}
          type={types.postRentPropertyCategory}
          title={t(`postRentPropertyCategory.${r.title}`)}
          key={i}
          channelTelegramData={
            data.categoryWithTelegramChannels.find(
              ({ postRentPropertyCategoryId }) =>
                r.id === postRentPropertyCategoryId
            )?.channelTelegram
          }
        />
      ))}
      <h1>
        {" "}
        -----------------------------------------------------------------------
      </h1>
    </Page>
  );
};

CategoryWithTelegramChannel.propTypes = {
  filter: PropTypes.object
};
export default CategoryWithTelegramChannel;
