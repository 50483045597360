import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getQuestionnaireUrl } from "../../../../routes";
import { classNames, joinedAndKeyTarnslete } from "../../../../utility";
import Link from "../../../layout/Link";

const PostItem = ({ post }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("questionnaire-item")}>
      <Link
        to={getQuestionnaireUrl(post.id)}
        className="questionnaire-item-inner"
      >
        <p className="questionnaire-item-cat">
          {post?.categoryProfession?.title
            ? t(`categoryProfession.${post.categoryProfession.title}`)
            : null}
        </p>
        <p className="questionnaire-item-vacancie">
          {joinedAndKeyTarnslete(post?.professions, "profession", t)}
        </p>
        <p className="questionnaire-item-city">
          {post?.city?.title ? t(`city.${post?.city?.title}`) : ""}
        </p>
        <p className="questionnaire-item-date">
          {moment(post.createdAt).format("DD.MM.YYYY")}
        </p>
      </Link>
    </div>
  );
};

PostItem.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    type: PropTypes.string,
    professions: PropTypes.array,
    categoryProfession: PropTypes.object,
    documentsForWorkInEngland: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string
    }),
    expireInPriorityDate: PropTypes.string,
    createdAt: PropTypes.string
  })
};

PostItem.defaultProps = {
  isPremium: false
};
export default PostItem;
