import PublicationRules from "../components/PublicationRules";
import Layout from "./Layout/Layout";

const PublicationRulesView = () => (
  <Layout>
    <PublicationRules />
  </Layout>
);

export default PublicationRulesView;
