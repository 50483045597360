/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { pageSize } from "../../../../graphql/enum";
import {
  getArrayIfNotEmpty,
  getBooleanInArrayIfNotEmpty,
  getOffset
} from "../../../../utility";
import QuestionnairesTable from "./QuestionnairesTable";

export const questionnairesQuery = gql`
  query questionnaires(
    $filters: QuestionnairesFilters
    $pagination: PaginationInput
  ) {
    questionnaires {
      total(filters: $filters)
      data(pagination: $pagination, filters: $filters) {
        id
        name
        email
        phone
        documentsForWorkInEngland
        professions {
          id
          title
        }
        categoryProfession {
          title
        }
        city {
          id
          title
        }
        createdAt
      }
    }
  }
`;

const QuestionnairesListTable = ({
  filters,
  setTotalPosts,
  currentPage,
  setCurrentPage
}) => {
  const { loading, data } = useQuery(questionnairesQuery, {
    variables: {
      filters: {
        cityIds: filters.cityId ? [filters.cityId] : null,
        professionIds: getArrayIfNotEmpty(filters.professionId),
        categoryProfessionIds: getArrayIfNotEmpty(filters.categoryProfessionId),
        postIndexIds: getArrayIfNotEmpty(filters.postIndexIds),
        documentsForWorks: getArrayIfNotEmpty(filters.documentsForWork),
        sex: getArrayIfNotEmpty(filters.sex),
        isCar: getBooleanInArrayIfNotEmpty(filters.isCar),
        isTool: getBooleanInArrayIfNotEmpty(filters.isTool),
        isWorksOnWeekends: getBooleanInArrayIfNotEmpty(
          filters.isWorksOnWeekends
        ),
        isAssistantMaster: getBooleanInArrayIfNotEmpty(
          filters.isAssistantMaster
        ),
        isDriverLicense: getBooleanInArrayIfNotEmpty(filters.isDriverLicense),
        isNonSmoker: getBooleanInArrayIfNotEmpty(filters.isNonSmoker),
        isKnowledgeOfEnglish: getBooleanInArrayIfNotEmpty(
          filters.isKnowledgeOfEnglish
        ),
        lookingForJobForCouple: getBooleanInArrayIfNotEmpty(
          filters.lookingForJobForCouple
        ),
        lookingForJobForWomen: getBooleanInArrayIfNotEmpty(
          filters.lookingForJobForWomen
        )
      },
      pagination: {
        limit: pageSize,
        offset: getOffset({ currentPage, pageSize })
      }
    }
  });
  useEffect(() => {
    if (data?.questionnaires?.data) {
      setTotalPosts(data.questionnaires.total);
    }
  }, [data?.questionnaires?.data]);
  if (loading) return null;

  return (
    <QuestionnairesTable
      pageSize={pageSize}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      posts={data?.questionnaires?.data}
      total={data?.questionnaires?.total || 0}
      loading={loading}
    />
  );
};

QuestionnairesListTable.propTypes = {
  filters: PropTypes.object,
  setTotalPosts: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func
};
export default QuestionnairesListTable;
