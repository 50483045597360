import queryString from "query-string";

import { addPostUrl, getPostUrl } from "../../../../routes";

export const getUrlAfterFirstStateUpdate = ({
  variablesAccessEdit,
  postSeo
}) => {
  const { code, postId } = variablesAccessEdit.variables;
  if (code) {
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        ...variablesAccessEdit.variables
      }
    });
    return url;
  }
  if (postId) {
    const url = getPostUrl(postId, postSeo);
    return url;
  }
  const url = queryString.stringifyUrl({
    url: addPostUrl,
    query: {
      ...variablesAccessEdit.variables
    }
  });
  return url;
};
export default { getUrlAfterFirstStateUpdate };
