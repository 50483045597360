import FAQ from "../components/FAQ";
import Layout from "./Layout/Layout";

const FAQView = () => (
  <Layout>
    <FAQ />
  </Layout>
);

export default FAQView;
