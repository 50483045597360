import { gql } from "@apollo/client";

const updateBlackList = gql`
  mutation updateBlackList($code: String!, $input: CreateBlackListInput) {
    updateBlackList(code: $code, input: $input) {
      success
      code
    }
  }
`;

export default updateBlackList;
