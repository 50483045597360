import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DatePicker from "../../../../layout/DatePicker/DatePicker";
import Hint from "../../../../layout/Hint";
import Select from "../../../../layout/Select";
import FieldsAddPost, {
  fieldsAddPostTypes
} from "../../CreatePostLayout/FieldsAddPost";

const FirstBlock = ({
  data,
  errors,
  values,
  setValues,
  validateForm,
  setValuesFormik,
  valuesFormik
}) => {
  const { t } = useTranslation();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      validateForm();
      setFirstRender(false);
    }
  }, [firstRender, validateForm]);

  return (
    <>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          errors={errors}
          valuesFormik={valuesFormik}
          type={fieldsAddPostTypes.title}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <Select
          key="countryDeparture"
          value={values.countryDeparture}
          options={data.carrierCountrys.map((p) => ({
            ...p,
            title: t(`carrierCountry.${p.title}`)
          }))}
          error={errors?.countryDeparture}
          id="countryDeparture"
          label={
            <>
              *{t("createPost.carrier.countryDeparture")}
              <Hint components={t("hint.cityDeparture")} />
            </>
          }
          onChange={async (val) => {
            await setValues({ ...values, countryDeparture: val });
            validateForm();
          }}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <Select
          key="countryArrival"
          value={values.countryArrival}
          options={data.carrierCountrys.map((p) => ({
            ...p,
            title: t(`carrierCountry.${p.title}`)
          }))}
          id="countryArrival"
          label={
            <>
              *{t("createPost.carrier.countryArrival")}
              <Hint components={t("hint.countryArrival")} />
            </>
          }
          onChange={async (val) => {
            await setValues({ ...values, countryArrival: val });
            validateForm();
          }}
          error={errors?.countryArrival}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <DatePicker
          name="dateDeparture"
          label={
            <>
              *{t("createPost.carrier.dateDeparture")}
              <Hint components={t("hint.dateDeparture")} />
            </>
          }
          id="dateDeparture"
          error={errors?.dateDeparture}
          value={values.dateDeparture}
          onChange={(date) => {
            const handleDateDepartureChange = async () => {
              await setValues({ ...values, dateDeparture: date });
              validateForm();
            };

            handleDateDepartureChange();
          }}
        />
      </div>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          {...{
            errors,
            validateForm,
            setValues,
            values,
            type: fieldsAddPostTypes.description
          }}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <div className="mb-3">
          <FieldsAddPost
            valuesFormik={valuesFormik}
            type={fieldsAddPostTypes.phone}
            errors={errors}
            setValuesFormik={setValuesFormik}
          />
        </div>
        <div className="form-check-inline-wrap" style={{ height: "inherit" }}>
          <FieldsAddPost type={fieldsAddPostTypes.messages} height="d" />{" "}
        </div>
      </div>
      <div className="col-lg-6 mb-4">
        <FieldsAddPost
          valuesFormik={valuesFormik}
          type={fieldsAddPostTypes.email}
          errors={errors}
        />
      </div>
      <div className="col-lg-6"></div>
      <div className="col-lg-6">
        <div className="row"></div>
      </div>
    </>
  );
};

FirstBlock.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  data: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};

export default FirstBlock;
