import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { clearHTMLTags } from "../../../../../../utility";
import Hint from "../../../../../layout/Hint";
import MinLength from "../../../../../layout/Input/MinLength";
import TextRedactor from "../../../../../layout/TextRedactor";

const Field = ({ errors, validateForm, setValues, values }) => {
  const { t } = useTranslation();
  const min = MinLength();

  return (
    <>
      <label htmlFor="inputText" className="form-label">
        *{t("createPost.advertisements")}{" "}
        {min.getMin(20, clearHTMLTags(values?.description) || "")}
        <Hint
          components={
            <Trans
              i18nKey="hint.description"
              components={{
                bold: <strong />,
                br: <br />,
                span: <span className="text-danger" />
              }}
            />
          }
        />
      </label>
      <TextRedactor {...{ errors, validateForm, setValues, values }} />
    </>
  );
};

Field.defaultValues = {
  validateForm: () => {}
};
Field.propTypes = {
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.shape({
    description: PropTypes.string
  })
};
export default Field;
