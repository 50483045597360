import CatalogOfCompanies from "../components/CatalogOfCompanies";
import Layout from "./Layout/Layout";

const CatalogOfCompaniesView = () => (
  <Layout>
    <CatalogOfCompanies />
  </Layout>
);

export default CatalogOfCompaniesView;
