/* eslint-disable i18next/no-literal-string */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { carrierUrl } from "../../../../routes";
import { setNewFilters } from "../../../../utility";
import DatePicker from "../../../layout/DatePicker/DatePicker";
import Input from "../../../layout/Input/Input";
import Link from "../../../layout/Link";
import Loading from "../../../layout/Loading/Loading";
import SideBarDropdown from "../../../layout/SideBarDropdown/SideBarDropdown";

export const allDataQuery = gql`
  query allData {
    carrierCountrys {
      id
      title
    }
    cities {
      id
      title
    }
  }
`;

const CarrierSideBar = ({ filters, setFilters, totalPosts }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data } = useQuery(allDataQuery);

  if (loading) return <Loading />;
  if (!data) return null;

  const validate = (values) => {
    setNewFilters({
      filters,
      newFilters: {
        ...values
      },
      setFilters,
      history,
      url: carrierUrl
    });
    return {};
  };

  return (
    <>
      <Formik initialValues={filters} validate={validate} onSubmit={() => {}}>
        {({ setValues }) => (
          <>
            <div className="sidebar-block">
              <h6 className="sidebar-title sidebar-title-clear">
                <span>{t("filter")}</span>
                <Link
                  to="#"
                  onClick={async () => {
                    const emptyData = {
                      carrierCountryDepartureId: [],
                      carrierCountryArrivalId: [],
                      dateDepartureFrom: null,
                      dateDepartureTo: null,
                      currentPage: 1
                    };
                    await setFilters(emptyData);
                    setValues(emptyData);
                  }}
                >
                  {t("sideBarFilter.cleanItUp")}
                </Link>
              </h6>
              <Form>
                <SideBarDropdown
                  isOpenDefault={false}
                  title={t("sideBarFilter.departureCountry")}
                >
                  {data.carrierCountrys.map((carrierCountry) => (
                    <div className="form-check" key={carrierCountry.id}>
                      <Input
                        type="checkbox"
                        label={t(`carrierCountry.${carrierCountry.title}`)}
                        key={t(
                          `carrierCountryDepartureId.${carrierCountry.id}`
                        )}
                        id={`carrierCountryDepartureId=${carrierCountry.id}`}
                        value={carrierCountry.id}
                        name={`carrierCountryDepartureId`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown
                  title={t("sideBarFilter.carrierCountryArrival")}
                >
                  {data.carrierCountrys.map((x) => (
                    <div className="form-check" key={x.id}>
                      <Input
                        type="checkbox"
                        label={t(`carrierCountry.${x.title}`)}
                        key={t(`carrierCountryArrivalId.${x.id}`)}
                        id={`carrierCountryArrivalId=${x.id}`}
                        value={x.id}
                        name={`carrierCountryArrivalId`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown
                  title={t("sideBarFilter.dateDeparture")}
                  classnameWidgetContent="mx-auto"
                >
                  <DatePicker
                    name="dateDeparture"
                    id={`dateDeparture`}
                    onChange={async (val) => {
                      const [dateDepartureFrom, dateDepartureTo] = val;
                      await validate({
                        ...filters,
                        dateDepartureFrom,
                        dateDepartureTo
                      });
                    }}
                    startDate={filters.dateDepartureFrom}
                    endDate={filters.dateDepartureTo}
                    selectsRange
                    inline
                    showDisabledMonthNavigation
                  />
                </SideBarDropdown>
                <div className="widget widget-class-result show">
                  <p>
                    {totalPosts} {t("sideBarFilter.posts")}
                  </p>
                </div>
              </Form>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

CarrierSideBar.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalPosts: PropTypes.number,
  maxPrice: PropTypes.number,
  typeActive: PropTypes.string
};

export default CarrierSideBar;
