import { Field } from "formik";
import PropTypes from "prop-types";

import { classNames, getClassNameForInput } from "../../../utility";

const Input = (props) => {
  const {
    addonAfter,
    label,
    id,
    type,
    error,
    required,
    value,
    isDisplayValid
  } = props;
  return type !== "checkbox" ? (
    <>
      {label ? (
        <label htmlFor={id} className="form-label">
          {required ? "*" : ""}
          {label}
        </label>
      ) : null}
      <Field
        className={classNames(
          "form-control",
          getClassNameForInput({ error, value, isDisplayValid })
        )}
        {...{ ...props, required: false }}
      />
      {addonAfter}
    </>
  ) : (
    <>
      <Field className={classNames("form-check-input")} {...{ ...props }} />
      {label ? (
        <label htmlFor={id} className="form-check-label">
          {label}
        </label>
      ) : null}
    </>
  );
};
Input.propTypes = {
  error: PropTypes.string,
  addonAfter: PropTypes.object,
  addonbefore: PropTypes.object,
  errorclassname: PropTypes.shape({
    error: PropTypes.string,
    notError: PropTypes.string
  }),
  label: PropTypes.any,
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.any,
  value: PropTypes.string,
  isDisplayValid: PropTypes.bool
};
Input.defaultProps = {
  errorclassname: {}
};
export default Input;
