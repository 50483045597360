import QuestionnaireSingle from "../components/Questionnaire/QuestionnaireSingle";
import Layout from "./Layout/Layout";

const QuestionnaireSingleView = () => (
  <Layout>
    <QuestionnaireSingle />
  </Layout>
);

export default QuestionnaireSingleView;
