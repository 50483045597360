import moment from "moment";
import queryString from "query-string";

import { postTypes } from "../graphql/enum";
import { getFromIsoDate } from "../graphql/utils/date";
import { postIndexs } from "./constants";

export const classNames = (...classes) => classes.filter(Boolean).join(" ");

export const validateEmail = (email) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const cyrillicPattern = /[а-яА-Я]/;
  const forbiddenChars = /[іїёє]/i;

  if (cyrillicPattern.test(email) || forbiddenChars.test(email)) {
    return false;
  }

  return pattern.test(email);
};

export const clearHTMLTags = (myString = "") =>
  myString.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, " ");

export const validateUrl = (string) =>
  !!string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
export const validatePhone = (value = "") => {
  if (value.length < 1) return false;
  if (!value.match(/\d/g)) return false;

  if (value.match(/\d/g)?.length < 10 || value.match(/\d/g)?.length > 15)
    return false;
  if (value.match(/[^0-9+() -.]/g)) return false;
  if (value.match(/[^0-9+() -.]/g)) return false;
  if (value[0] !== "+") return false;
  return true;
};

export const isAgency = (isAgencyType, t, type) => {
  if (type === postTypes.lookingJob) return "";
  return isAgencyType ? t("post.agency") : t("post.privatePerson");
};
export const concatObj = (objs) => Object.assign({}, ...objs);

export const getArrayIfNotEmpty = (array = []) => {
  const newArray = array.filter((x) => x);
  if (!newArray) return null;
  if (newArray.length === 0) return null;
  return newArray;
};
export const getBooleanInArrayIfNotEmpty = (value) =>
  value?.length ? true : null;

export const getValues = (value, code) => {
  if (code) return value;
  return "";
};

export const getValuesBoolean = (value, code) => {
  if (code) return value;
  return false;
};

export const getValueId = (value, code) => {
  if (code) return value;
  return null;
};

export const joinArrayValues = (array) => {
  const newArray = array.filter((x) => x);
  return newArray.join(", ");
};

export const getCreatePostTypeUser = (isAgencyUser, code) => {
  if (code) return isAgencyUser ? "agency" : "private";
  return "private";
};
export const getOffset = ({ currentPage, pageSize }) =>
  pageSize * (currentPage - 1);

export const getSelectParameter = ({ value, array }) => {
  const e = array.find((x) => x.id === value);
  if (e) return e;
  return {};
};

export const getIsPremium = (date, priority) => {
  if (new Date() > new Date(date)) return false;
  if (!priority) return false;
  return true;
};

export const priorityToArray = (priority) => {
  const stars = [];

  let i = priority - 1;
  while (i !== -1) {
    stars[i] = true;
    i -= 1;
  }
  return stars;
};

export const getUploadLinkGoogle = (link) => {
  const linkArray = link.split("?");
  const newLink = [linkArray[0], "?export=download&", linkArray[1]].join("");
  return newLink;
};

export const isSelectLondon = (selectCityId, cities) => {
  if (!selectCityId) return false;
  const selectCity = cities.find(({ id }) => selectCityId === id);
  if (!selectCity) return false;
  return selectCity.title === "London";
};

export const isAgencyFilter = (filters) =>
  (!!filters.announcementFromAgencies.length && !!filters.privateAds.length) ||
  (!filters.announcementFromAgencies.length && !filters.privateAds.length)
    ? null
    : !filters.privateAds.length;

export const cleanObject = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

export const numberWithSpaces = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const getClassNameForInput = ({
  error,
  value,
  isDisplayValid = true
}) => {
  if (error) return "is-invalid";
  if (value && isDisplayValid) return "is-valid";
  return "";
};

export const joinedAndKeyTarnslete = (arr = [], keyTranslete, t) => {
  if (!arr.length) return "";
  const newArr = arr.map((x) => t(`${keyTranslete}.${x.title}`));
  return newArr.join(", ");
};

export const setNewFilters = ({
  filters,
  url,
  newFilters,
  setFilters,
  history
}) => {
  if (JSON.stringify(cleanObject(newFilters)) === JSON.stringify(filters))
    return;
  setFilters({ ...newFilters, currentPage: 1 });
  const { scrollY } = window;

  const newFilterForUrl = { ...newFilters };
  Object.keys(newFilters).forEach((key) => {
    if (
      newFilters[key] &&
      !(newFilters[key]?.length === 0 || newFilters[key]?.length > 0) &&
      moment(newFilters[key]).isValid()
    ) {
      newFilterForUrl[key] = getFromIsoDate(newFilters[key]);
    }
  });

  const newUrl = queryString.stringifyUrl({
    url,
    query: { ...newFilterForUrl, currentPage: 1 }
  });
  history.push(newUrl);
  setTimeout(() => {
    window.scrollTo(0, scrollY);
  }, 0);
};
export const getIsWomenCategory = ({ id, categories }) =>
  !!categories.find(
    ({ id: categoryId, title: categoryTitle }) =>
      categoryTitle === "workForWomen" && id === categoryId
  );

export const handlePhobePress =
  ({ valuesFormik, setValuesFormik }) =>
  (event) => {
    const { target } = event;
    const currentValue = (target.value || "")
      .split("")
      .map((f, index) => {
        if (index === 0) {
          return "+";
        }
        if (index === 1 && (f === "0" || !/^\d$/.test(f))) {
          return "";
        }
        return /^\d$/.test(f) ? f : "";
      })
      .join("");

    const fieldMapping = {
      phone: "phone",
      phoneCompany: "phoneCompany"
    };

    const { name } = target;
    if (fieldMapping[name]) {
      setValuesFormik({ ...valuesFormik, [fieldMapping[name]]: currentValue });
    }

    return currentValue;
  };

export const handleDownloadFromUrl = (file) => {
  fetch(file.src)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Error fetching the file:", error);
    });
};

export const sortByTitle = (arrayForSort) => {
  const newArray = [...arrayForSort].sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });
  return newArray;
};

export const isSelectedAllPostCode = (selectPostIndexs = []) =>
  !postIndexs.filter((x) => !selectPostIndexs.find((v) => v === x)).length;
export const removeSpaces = (str) => str.replace(/\s/g, "");
