/* eslint-disable max-statements */
/* eslint-disable no-console */
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getGeocode, getLatLng } from "use-places-autocomplete";

import Hint from "../../../../layout/Hint";
import Input from "../../../../layout/Input/Input";
import Maps from "../../../../layout/Maps";

const CreatePostMapsBlock = ({ values, setValues, fieldSityAndPostCode }) => {
  const { t } = useTranslation();
  const [isResult, setIsResult] = useState(false);
  const [coordinates, setCoordinates] = useState(values?.address || null);

  const onChange = (s) => {
    if (!s) return;
    if (!s.target.value) {
      setIsResult(false);
      return;
    }
    if (s.target.value.length < 3) {
      setIsResult(false);
      setCoordinates(null);
      setValues({
        ...values,
        address: null
      });
      return;
    }
    getGeocode({ address: s.target.value })
      .then((results) => {
        setIsResult(false);
        const { lat, lng } = getLatLng(results[0]);
        setCoordinates({ lat, lng });
        setValues({
          ...values,
          address: { title: s.target.value, lat, lng }
        });
      })
      .catch((e) => {
        console.warn("Warning result code:", e);
        setIsResult(true);
      });
    setCoordinates(null);
    setValues({
      ...values,
      address: null
    });
  };
  console.log(values.address);
  return (
    <>
      {fieldSityAndPostCode}
      <div className={!fieldSityAndPostCode ? "col-12 mb-4" : "col-lg-6 mb-4"}>
        <label htmlFor="inputAddress" className="form-label">
          <>
            {" "}
            {t("createPost.postalAddressForTheCard")}
            <Hint
              components={
                <Trans
                  i18nKey="hint.postCodeMaps"
                  components={{ br: <br /> }}
                />
              }
            />
          </>
        </label>
        <Input
          type="text"
          name="postCodeMaps"
          id="inputAddress"
          error={isResult}
          value={values?.address?.title}
          onChange={onChange}
        />
      </div>
      <div className="col-12 mb-4">
        <Maps coordinates={coordinates} />
      </div>
    </>
  );
};

CreatePostMapsBlock.defaultValues = {
  validateForm: () => {}
};

CreatePostMapsBlock.propTypes = {
  values: PropTypes.shape({
    address: PropTypes.object
  }),
  setValues: PropTypes.func,
  fieldSityAndPostCode: PropTypes.any
};
export default CreatePostMapsBlock;
