/* eslint-disable i18next/no-literal-string */
import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Page from "../../../layout/Page";

const addBlackList = gql`
  mutation addBlackList($email: String, $phone: String, $name: String) {
    addBlackList(email: $email, phone: $phone, name: $name) {
      success
      code
    }
  }
`;

const BlackListImport = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const [isSuccses, setIsSuccses] = useState(false);
  const [addBlackListMutation] = useMutation(addBlackList);
  const hendlerOnClick = async () => {
    const { data } = await addBlackListMutation({ variables: values });
    if (data?.addBlackList?.success) {
      setIsSuccses(true);
    }
  };
  return (
    <Page pageName={"Import black list"} isClassNamePageSingle={false}>
      <div>
        Emails
        <textarea
          value={values?.email}
          onChange={(e) => {
            setValues({ ...values, email: e.target.value });
          }}
          id="inputText"
          placeholder={t("createPost.min20")}
        ></textarea>
      </div>{" "}
      <div>
        Phone
        <textarea
          value={values?.phone}
          onChange={(e) => {
            setValues({ ...values, phone: e.target.value });
          }}
          id="inputTextphone"
          placeholder={t("createPost.min20")}
        ></textarea>
      </div>{" "}
      <div>
        Name
        <textarea
          value={values?.name}
          onChange={(e) => {
            setValues({ ...values, name: e.target.value });
          }}
          id="inputTextname"
          placeholder={t("createPost.min20")}
        ></textarea>
      </div>
      <div>
        <button onClick={hendlerOnClick}>Додати</button>
        {isSuccses ? "Ok sucses" : "-"}
      </div>
    </Page>
  );
};

BlackListImport.propTypes = {
  filter: PropTypes.object
};
export default BlackListImport;
