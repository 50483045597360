import Announcements from "../components/Posts/Announcements";
import Layout from "./Layout/Layout";

const LookingJobView = () => (
  <Layout>
    <Announcements />
  </Layout>
);

export default LookingJobView;
