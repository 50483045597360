/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { pageSize, postTypes } from "../../../../graphql/enum";
import {
  getArrayIfNotEmpty,
  getOffset,
  isAgencyFilter
} from "../../../../utility";
import PostTable from "../../../layout/PostTable";

export const checkInsQuery = gql`
  query posts($filters: PostFilters, $pagination: PaginationInput) {
    posts {
      total(filters: $filters)
      data(pagination: $pagination, filters: $filters) {
        id
        title
        seo
        type
        city {
          id
          title
        }
        expireInPriorityDate
      }
    }
  }
`;

const OfferVacancyTable = ({
  filters,
  setTotalPosts,
  currentPage,
  setCurrentPage
}) => {
  const { loading, data, refetch } = useQuery(checkInsQuery, {
    variables: {
      filters: {
        type: postTypes.offerVacancy,
        search: filters.search || null,
        cityIds: filters.cityId ? [filters.cityId] : null,
        postIndexIds: getArrayIfNotEmpty(filters.postIndexs),
        professionsIds: getArrayIfNotEmpty(filters.professionsId),
        categoryProfessionIds: getArrayIfNotEmpty(filters.categoryProfessionId),
        sumFrom: parseInt(filters.sum[0], 10),
        sumTo: parseInt(filters.sum[1], 10),
        isWorkForAMarriedCouple: filters.findOfferForFamily.length
          ? true
          : null,
        isYouCanWorkOnWeekends: filters.iWorkOnWeekends.length ? true : null,
        isWorkForMinors: filters.isWorkForMinors.length ? true : null,
        isAssistantJob: filters.isAssistantJob.length ? true : null,
        isAgency: isAgencyFilter(filters)
      },
      pagination: {
        limit: pageSize,
        offset: getOffset({ currentPage, pageSize })
      }
    }
  });

  setTotalPosts(data?.posts?.total || 0);
  return (
    <PostTable
      pageSize={pageSize}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      posts={data?.posts?.data || []}
      total={data?.posts?.total || 0}
      loading={loading}
      refetch={refetch}
    />
  );
};

OfferVacancyTable.propTypes = {
  filters: PropTypes.object,
  setTotalPosts: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func
};
export default OfferVacancyTable;
