import PropTypes from "prop-types";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Tabs = ({ defaultKey, tabs, currentUrl }) => {
  const [activeKey, setActiveKey] = useState("");
  const history = useHistory();
  const activeTab = tabs.find((tab) => tab.name === activeKey);

  useEffect(() => {
    setActiveKey(defaultKey);
  }, [defaultKey]);

  if (!activeTab) return null;
  return (
    <>
      <ul className="type-menu type-menu-horizontal">
        {tabs.map((tab) => (
          <li key={tab.name}>
            <span
              className={`btn btn-outline-primary w-100 ${
                tab.name === activeTab.name ? "active" : ""
              }`}
              onClick={() => {
                const url = queryString.stringifyUrl({
                  url: currentUrl,
                  query: {
                    activeTab: tab.name
                  }
                });
                history.push(url);
              }}
            >
              {tab.title}
            </span>
          </li>
        ))}
      </ul>
      {activeTab.render()}
    </>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  defaultKey: PropTypes.string,
  currentUrl: PropTypes.string
};

export default Tabs;
