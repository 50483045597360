import { gql } from "@apollo/client";

const updateUser = gql`
  mutation updateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      success
      code
    }
  }
`;

export default updateUser;
