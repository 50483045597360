/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import createQuestion from "../../graphql/mutation/createQuestion";
import { faqUrl, homeUrl } from "../../routes";
import { validateEmail } from "../../utility";
import { socialNetworks } from "../../utility/constants";
import NoRegistration from "../../views/Layout/Modals/NoRegistration";
import Modal from "../layout/Modal";
import Page from "../layout/Page";
import DoneResponseForQestions from "./DoneResponseForQestions/DoneResponseForQestions";
import SendQuestionContactsForm from "./SendQuestionFAQForm/SendQuestionFAQForm";

const SendQuestionFAQ = () => {
  const [isShowNoRegistration, setIsShowNoRegistration] = useState(false);
  const { t } = useTranslation();
  const [values, setValues] = useState({
    description: "",
    typeQuestion: null
  });
  const [createQuestionMutation] = useMutation(createQuestion);
  const [isPublish, setIsPublish] = useState(false);

  const validate = (valuesValidate) => {
    const errors = {};
    if (!values.typeQuestion) {
      errors.typeQuestion = `validationErrors.required`;
    }

    if (!valuesValidate.email || !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }

    return errors;
  };
  const onSave = async (valuesFormik, { setErrors, resetForm }) => {
    const valid = validate(valuesFormik);
    setErrors(valid);
    if (Object.keys(valid).length !== 0) {
      window.scrollTo(0, 0);
      return;
    }

    await createQuestionMutation({
      variables: {
        input: {
          link: valuesFormik.link,
          name: valuesFormik.name,
          email: valuesFormik.email,
          phone: valuesFormik.phone,
          isViber: !!valuesFormik.viber,
          isTelegram: !!valuesFormik.telegram,
          isWhatsapp: !!valuesFormik.whatsApp,
          message: values.description,
          title: values.typeQuestion
        }
      }
    });
    resetForm();
    setValues({
      description: "",
      typeQuestion: null
    });
    setIsPublish(true);
  };
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "faqUrl",
      title: t("faq.namePage"),
      link: faqUrl
    }
  ];
  return (
    <Page
      pageName={t("faq.namePage")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
      modals={[
        <NoRegistration
          isShow={isShowNoRegistration}
          onClose={() => setIsShowNoRegistration(false)}
        />
      ]}
    >
      <div className="page-section">
        <DoneResponseForQestions
          setIsShowNoRegistration={setIsShowNoRegistration}
        />
      </div>
      <div className="page-section">
        <Formik
          initialValues={{
            link: "",
            name: "",
            email: "",
            phone: "",
            isViber: false,
            isTelegram: false,
            isWhatsapp: false
          }}
          onSubmit={onSave}
          validate={validate}
        >
          {({ errors, validateForm, setValues: setValuesFormik }) => (
            <Form>
              <h4>{t("contacts.ifYouHaveNotFoundTheAnswer")}</h4>
              <SendQuestionContactsForm
                {...{
                  values,
                  setValues,
                  errors,
                  validateForm,
                  setValuesFormik
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        isShow={isPublish}
        onClose={() => {
          setIsPublish(false);
        }}
      >
        <div className="block-no-content__image text-center">
          <img
            src="/assets/img//emoji-subscribe-success.svg"
            width="130"
            height="130"
            alt={t("modal.afterAddBlackList")}
          />
        </div>
        <h1 className="thanks-title">{t("modal.messageSent")}</h1>
        <div className="thanks-btn">
          <a href="/" className="btn btn-primary" aria-label={t("modal.home")}>
            {t("modal.home")}
          </a>
        </div>
        <div className="thanks-soc">
          <p>{t("modal.joinGroups")}</p>
          <ul className="soc-links soc-links-thanks">
            <li className="soc-links-instagram">
              <a
                href={socialNetworks.instagram}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-facebook">
              <a
                href={socialNetworks.facebook}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-telegram">
              <a
                href="https://t.me/+SyzcC03mwARkYjE0"
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-whatsapp">
              <a
                href="whatsapp://send?phone=+447599683751"
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-viber">
              <a
                href={socialNetworks.viber}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
          </ul>
        </div>
      </Modal>
    </Page>
  );
};

SendQuestionFAQ.propTypes = {
  editPostData: PropTypes.shape({
    description: PropTypes.string,
    imagesIds: PropTypes.array,
    isAgency: PropTypes.bool,
    title: PropTypes.string,
    linkYouTube: PropTypes.string,
    isSendToEmail: PropTypes.bool,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      isViber: PropTypes.bool,
      isTelegram: PropTypes.bool,
      isWhatsapp: PropTypes.bool
    }),
    announcement: PropTypes.shape({
      postIndexId: PropTypes.string,
      cityId: PropTypes.string,
      cityInEngland: PropTypes.string
    })
  }),
  code: PropTypes.string,
  refetch: PropTypes.func
};

SendQuestionFAQ.defaultProps = {
  editPostData: {}
};

export default SendQuestionFAQ;
