import { gql } from "@apollo/client";

const singleUpload = gql`
  mutation singleUpload($file: String!, $size: Int) {
    singleUpload(file: $file, size: $size) {
      success
      code
      imageId
    }
  }
`;

export default singleUpload;
