/* eslint-disable i18next/no-literal-string */
import PropTypes from "prop-types";

const SideBar = () => (
  <>
    <div className="sidebar-block">
      <div className="widget widget-banner">Реклама</div>
    </div>
    <div className="sidebar-block">
      <div className="widget widget-banner">Реклама</div>
    </div>
    <div className="sidebar-block">
      <div className="widget widget-banner">Реклама</div>
    </div>
    <div className="sidebar-block">
      <div className="widget widget-banner">Реклама</div>
    </div>
    <div className="sidebar-block">
      <div className="widget widget-banner">Реклама</div>
    </div>
  </>
);

SideBar.propTypes = {
  filter: PropTypes.object
};

export default SideBar;
