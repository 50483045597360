import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { isDevelopmentEnv } from "../../../../../config";
import { publicationRulesUrl } from "../../../../../routes";
import { classNames } from "../../../../../utility";
import Captcha from "../../../../layout/Captcha";

const PreviewButtonBlock = ({
  values,
  variablesAccessEdit,
  submitForm,
  isSubmitting
}) => {
  const { t } = useTranslation();

  const [captcha, setCaptcha] = useState(false);

  return (
    <div className="section-add">
      <div className="row justify-content-between">
        <div className="col-lg-6 col-xxl-7 mb-4">
          <p className="form-privacy-policy">
            {t("createPost.contactInformationDescription")}
            <a href={publicationRulesUrl} target="_blank" rel="noreferrer">
              {t("createPost.rulesPublication")}
            </a>
            {t("createPost.declared")}
            <strong>
              {" "}
              {values.typeUser === "agency"
                ? t("post.agency")
                : t("createPost.privatePerson")}
            </strong>
          </p>
        </div>
        <div className="col-lg-6 col-xxl-5 mb-4 text-center text-lg-end d-lg-flex align-items-center">
          <div className="mx-4">
            <Captcha isValidCaptcha={captcha} onChange={(x) => setCaptcha(x)} />
          </div>
          <button
            onClick={() => {
              if (!isSubmitting) {
                window.scrollTo(0, 0);
              }
              submitForm();
            }}
            type="button"
            className={classNames(
              "btn w-100 ml-lg-4 mt-4 mt-lg-0",
              captcha || isDevelopmentEnv ? "btn-primary" : "btn-secondary"
            )}
            disabled={!captcha && !isDevelopmentEnv}
          >
            {variablesAccessEdit.variables.postId
              ? t("createPost.savePost")
              : t("createPost.preview")}
          </button>
        </div>
      </div>
    </div>
  );
};

PreviewButtonBlock.propTypes = {
  variablesAccessEdit: PropTypes.shape({
    variables: PropTypes.shape({
      code: PropTypes.string,
      postId: PropTypes.string
    }),
    isEdit: PropTypes.bool
  }),
  values: PropTypes.object,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool
};
export default PreviewButtonBlock;
