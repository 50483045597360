/* eslint-disable max-lines */
/* eslint-disable complexity */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  isSelectedAllPostCode,
  joinedAndKeyTarnslete
} from "../../../../utility";
import QuestionnaireDropdownBlock from "./QuestionnaireDropdownBlock";

const joinParameter = (array) => {
  const newArray = [];
  array.map((x) => {
    if (x) newArray.push(x);
    return null;
  });
  if (!newArray.length) return null;
  const n = newArray.join(", ");
  return n;
};
const QuestionnaireFirst = ({ questionnaire }) => {
  const { t } = useTranslation();

  const personalQualities = [
    questionnaire.isGotHheToolsToDoTheJob
      ? t("questionnaireSingle.gotHheToolsToDoTheJob")
      : null,
    questionnaire.isIHaveCar ? t("questionnaireSingle.car") : null,
    questionnaire.isNonSmoker ? t("questionnaireSingle.doNotSmoke") : null
  ].filter((x) => x !== null);

  return (
    <div className="section-my-resume d-flex flex-wrap flex-lg-nowrap align-items-start">
      <div className="colLeft-my-resume">
        <div className="user-image-my-resume">
          <img
            src={
              questionnaire?.image
                ? questionnaire.image.src
                : "/assets/img/user-image.jpg"
            }
            alt={questionnaire.name}
          />
        </div>
        <div className="user-info-my-resume">
          <p className="user-name-my-resume">{questionnaire.name}</p>
          <div className="user-data-my-resume">
            {questionnaire.age ? (
              <p>
                <strong>{t("questionnaireSingle.age")}</strong>{" "}
                {`${questionnaire.age} `}
              </p>
            ) : null}

            {questionnaire?.sex ? (
              <p>
                <strong>{t("questionnaireSingle.gender")}</strong>{" "}
                {t(`questionnaireSingle.${questionnaire.sex}`)}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="colRight-my-resume">
        <div className="title-my-resume">
          <span>{t("questionnaireSingle.lookingForWork")}</span>
          <p>
            {joinParameter(
              questionnaire?.professions.map(({ title }) =>
                t(`profession.${title}`)
              )
            )}
          </p>
        </div>
        <div className="tags-my-resume">
          {questionnaire.isYouCanWorkOnWeekends ? (
            <span>{t("questionnaireSingle.onWeekends")}</span>
          ) : null}
          {questionnaire.isAssistantMaster ? (
            <span>{t("questionnaireSingle.assistantMaster")}</span>
          ) : null}
          {questionnaire.isWorkForAMarriedCouple ? (
            <span>{t("questionnaireSingle.forAMarriedCouple")}</span>
          ) : null}
        </div>
        <div className="info-my-resume">
          <h4 className="info-title-my-resume">
            {t("questionnaireSingle.iAmLookingForAJobIn")}
          </h4>
          <div className="content-my-resume">
            <ul className="inline-content-my-resume">
              <li>
                <strong>{t("questionnaireSingle.city")}</strong>{" "}
                {t(`city.${questionnaire.city.title}`)}
              </li>
              {questionnaire.city.title === "London" &&
              questionnaire.postIndexs.length ? (
                <li>
                  <strong>{t("questionnaireSingle.londonArea")}</strong>{" "}
                  {isSelectedAllPostCode(
                    questionnaire.postIndexs.map(({ title }) => title)
                  )
                    ? t("post.allLondon")
                    : joinParameter(
                        questionnaire.postIndexs.map(({ title }) =>
                          t(`postIndexs.${title}`)
                        )
                      )}
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        <div className="info-my-resume">
          {questionnaire?.documentsForWorkInEngland ||
          questionnaire.constructionDocuments.length ? (
            <>
              <h4 className="info-title-my-resume">
                {t("questionnaireSingle.documents")}
              </h4>
              <div className="content-my-resume">
                <ul>
                  {questionnaire?.documentsForWorkInEngland ? (
                    <li>
                      <strong>
                        {t("questionnaireSingle.documentsInEngland")}
                      </strong>{" "}
                      {questionnaire?.documentsForWorkInEngland
                        ? t(
                            `questionnaire.${questionnaire.documentsForWorkInEngland}`
                          )
                        : null}
                    </li>
                  ) : null}
                  {questionnaire.constructionDocuments.length ? (
                    <li>
                      <strong>
                        {t("questionnaireSingle.documentsForWork")}
                      </strong>{" "}
                      {joinedAndKeyTarnslete(
                        questionnaire.constructionDocuments?.map((key) => ({
                          title: key
                        })),
                        "questionnaire",
                        t
                      )}
                    </li>
                  ) : null}
                </ul>
              </div>
            </>
          ) : null}
          <QuestionnaireDropdownBlock
            key="theAdvantageOfMyCandidacy"
            title={t("questionnaireSingle.theAdvantageOfMyCandidacy")}
            isDisplay={
              personalQualities.length ||
              questionnaire?.driverLicense.length ||
              questionnaire?.knowledgeOfEnglish
            }
          >
            <ul className="accordion-content-listIcon-my-resume">
              {personalQualities.length ? (
                <li className="resume-additional">
                  <p>
                    <strong>{t("questionnaireSingle.additionally")}</strong>{" "}
                    {joinParameter(personalQualities)}
                  </p>
                </li>
              ) : null}
              {questionnaire?.driverLicense.length ? (
                <li className="resume-docs">
                  <p>
                    <strong>{t("questionnaireSingle.driverLicense")}</strong>{" "}
                    {joinParameter(questionnaire.driverLicense)}
                  </p>
                </li>
              ) : null}
              {questionnaire?.knowledgeOfEnglish ? (
                <li className="resume-lang">
                  <p>
                    <strong>
                      {t("questionnaireSingle.languageKnowledge")}
                    </strong>{" "}
                    {questionnaire.knowledgeOfEnglish}
                  </p>
                </li>
              ) : null}
            </ul>
          </QuestionnaireDropdownBlock>
          <QuestionnaireDropdownBlock
            key="workExperience"
            title={t("questionnaireSingle.workExperience")}
            isDisplay={!!questionnaire.workExperience.length}
          >
            <div className="work-my-resume">
              {questionnaire.workExperience.map(
                ({ placeOfWork, workedByProfession }) => (
                  <div
                    key={placeOfWork}
                    className="work-item-my-resume text-break"
                  >
                    <p>
                      <strong>{t("questionnaireSingle.profession")}</strong>{" "}
                      {t(`profession.${workedByProfession?.title}`)}
                    </p>
                    <p>
                      <strong>{t("questionnaireSingle.placeOfWork")}</strong>{" "}
                      {placeOfWork}
                    </p>
                  </div>
                )
              )}
            </div>
          </QuestionnaireDropdownBlock>
          <QuestionnaireDropdownBlock
            key="education"
            title={t("questionnaireSingle.education")}
            isDisplay={!!questionnaire.education.length}
          >
            <div className="work-my-resume">
              {questionnaire.education.map(
                ({ education, nameEducationalInstitution }) => (
                  <div
                    key={nameEducationalInstitution}
                    className="work-item-my-resume text-break"
                  >
                    <p>{t(`questionnaire.${education}`)}</p>
                    <p>
                      <strong>{t("questionnaireSingle.name")}</strong>{" "}
                      {nameEducationalInstitution}
                    </p>
                  </div>
                )
              )}
            </div>
          </QuestionnaireDropdownBlock>
        </div>
      </div>
    </div>
  );
};

QuestionnaireFirst.propTypes = {
  questionnaire: PropTypes.object
};
export default QuestionnaireFirst;
