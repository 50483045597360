/* eslint-disable max-statements */
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { addBlackListUrl, blackListUrl, homeUrl } from "../../../routes";
import Input from "../../layout/Input/Input";
import LinkView from "../../layout/Link";
import Page from "../../layout/Page";
import BlackListTable from "./BlackListTable/BlackListTable";

const BlackList = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const validate = (valuesValidate) => {
    const errors = {};

    if (!valuesValidate.search) {
      errors.search = `validationErrors.required`;
    } else {
      setSearch(valuesValidate.search);
    }

    return errors;
  };
  const onSave = async (valuesFormik) => {
    validate(valuesFormik);
  };
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "blackList",
      title: t("blackList.title"),
      link: blackListUrl
    }
  ];
  return (
    <Page
      pageName={t("blackList.title")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
      titleAfter={
        <LinkView className="btn btn-primary" to={addBlackListUrl}>
          {t("blackList.addToTheBlackList")}
        </LinkView>
      }
    >
      <div className="page-section page-black-list">
        <h4>{t("blackList.textTitle")}</h4>
        <p>{t("blackList.text_3")}</p>
        <p>{t("blackList.text_4")}</p>
        <p>{t("blackList.text_5")}</p>
        <div className="black-list-message">{t("blackList.text_6")}</div>
      </div>
      <div className="page-section">
        <Formik
          initialValues={{
            search: ""
          }}
          onSubmit={onSave}
          validate={validate}
        >
          {({ errors }) => (
            <Form>
              <Input
                name="search"
                type="text"
                className="form-control"
                id="inputLink"
                placeholder="Поиск..."
                error={errors.search}
              />
            </Form>
          )}
        </Formik>
      </div>
      <BlackListTable search={search} />
    </Page>
  );
};

BlackList.propTypes = {};
export default BlackList;
