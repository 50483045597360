/* eslint-disable react/prop-types */
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../index.css";

import { useContext } from "react";
import { useLocation } from "react-router-dom";

import {
  addPostUrl,
  getPostUrl,
  loginUrl,
  restorePasswordUrl
} from "../../../routes";
import { classNames } from "../../../utility";
import { UserContext } from "../../../utility/context/User";
import Footer from "../Footer";
import Header from "../Header";

const Layout = ({ children }) => {
  const { otherData, user, logout } = useContext(UserContext);
  const { pathname } = useLocation();
  window.scrollTo(0, 0);
  const isNoPartHeader = [
    addPostUrl,
    getPostUrl,
    loginUrl,
    restorePasswordUrl
  ].includes(pathname);
  return (
    <div
      className={classNames("wrapper", isNoPartHeader ? "no-part-header" : "")}
    >
      <Header {...{ user, pathname, logout }} />
      {children}
      <Footer otherData={otherData} />
      <div className="modal-mobile">
        <div className="modal-mobile-bg"></div>
      </div>
    </div>
  );
};
export default Layout;
