import SinglePost from "../components/SinglePost";
import Layout from "./Layout/Layout";

const PostView = () => (
  <Layout>
    <SinglePost />
  </Layout>
);

export default PostView;
