import "moment/locale/ru";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";

import Pagination from "../../../layout/Pagination";
import PostItem from "./PostItem";

const PostTable = ({
  posts,
  total,
  isAsyncScroll,
  currentPage,
  setCurrentPage,
  pageSize,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="questionnaire-items">
        <div className="questionnaire-item questionnaire-item-head">
          <div className="questionnaire-item-inner">
            <p className="questionnaire-item-cat">
              {t("questionnaire.categoryVacancies")}
            </p>
            <p className="questionnaire-item-vacancie">
              {t("questionnaire.vacancy")}
            </p>
            <p className="questionnaire-item-city">{t("questionnaire.city")}</p>
            <p className="questionnaire-item-date">{t("questionnaire.date")}</p>
          </div>
        </div>

        {isAsyncScroll ? (
          <InfiniteScroll
            pageStart={currentPage}
            loadMore={async (d) => {
              if (loading) return false;
              await setTimeout(() => setCurrentPage(d), 1000);
              return true;
            }}
            hasMore={total / pageSize > currentPage}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {posts.map((post, index) => (
              <PostItem posts={posts} post={post} index={index} key={post.id} />
            ))}
          </InfiniteScroll>
        ) : null}
        {posts.map((post, index) => (
          <PostItem posts={posts} post={post} index={index} key={post.id} />
        ))}
      </div>
      {!isAsyncScroll ? (
        <div>
          <Pagination
            total={total}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </>
  );
};

PostTable.propTypes = {
  posts: PropTypes.array,
  total: PropTypes.number,
  isAsyncScroll: PropTypes.bool,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageSize: PropTypes.number,
  loading: PropTypes.bool
};
PostTable.defaultProps = {
  loading: false
};
export default PostTable;
