import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Hint = ({ components }) => {
  if (!components) return null;

  return (
    <span className="form-label-help">
      <OverlayTrigger overlay={<Tooltip>{components}</Tooltip>}>
        <img src="/assets/img/help-outline.svg" alt="tool" />
      </OverlayTrigger>
    </span>
  );
};

Hint.propTypes = {
  components: PropTypes.string
};
export default Hint;
