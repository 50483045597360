import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import Hint from "../../../../layout/Hint";
import Input from "../../../../layout/Input/Input";
import Radio from "../../../../layout/Radio/Radio";
import CreatePostMapsBlock from "../CreatePostMapsBlock";

const ContactInformation = ({ values, setValues, valuesFormik }) => {
  const { t } = useTranslation();

  const radioList = [
    {
      value: "private",
      title: t("createPost.privatePerson")
    },
    {
      value: "agency",
      title: t("agency")
    }
  ];
  return (
    <div className="section-add">
      <h4 className="mb-4">{t("createPost.contactInformation")}</h4>
      <div className="row">
        <div className="col-lg-4 mb-4">
          <Input
            type="text"
            id="inputName"
            name="name"
            maxLength="80"
            value={valuesFormik.name}
            label={<>{t("name")}</>}
          />
        </div>
        <div className="col-lg-4 mb-4">
          <Input
            type="text"
            name="linkYouTube"
            value={valuesFormik.linkYouTube}
            label={
              <>
                {t("createPost.linkToAVideoFromYouTube")}
                <Hint
                  components={
                    <Trans
                      i18nKey="hint.linkYouTube"
                      components={{ bold: <strong />, br: <br /> }}
                    />
                  }
                />
              </>
            }
            id="inputYoutube"
          />
        </div>
        <div className="col-lg-4 mb-4">
          <label className="form-label">
            {t("createPost.whoIsPostingTheAd")}
          </label>
          <div className="form-check-inline-wrap align-items-center">
            <Radio
              key="input-checbox"
              options={radioList}
              value={values?.typeUser}
              onClick={(val) => setValues({ ...values, typeUser: val })}
            />
          </div>
        </div>
        <CreatePostMapsBlock values={values} setValues={setValues} />
      </div>
    </div>
  );
};

ContactInformation.propTypes = {
  values: PropTypes.shape({
    typeUser: PropTypes.string,
    imagesIds: PropTypes.array
  }),
  setValues: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string
  }),
  isDisplayPrivate: PropTypes.bool,
  thirdColums: PropTypes.any,
  valuesFormik: PropTypes.object
};

ContactInformation.defaultProps = {
  isDisplayPrivate: true
};
export default ContactInformation;
