import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { aboutUrl, contactsUrl, homeUrl } from "../../routes";
import LinkView from "../layout/Link";
import Page from "../layout/Page";

const TermsOfUse = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "termsOfUse",
      title: t("termsOfUse.title"),
      link: aboutUrl
    }
  ];
  return (
    <Page pageName={t("termsOfUse.title")} breadcrumbs={breadcrumbs}>
      <div className="page-section page-term-of-use">
        <p>{t("termsOfUse.description")}</p>
        <h5>1. {t("termsOfUse.1.title")}</h5>
        <p>1.1. {t("termsOfUse.1.1")} </p>
        <p>1.2. {t("termsOfUse.1.2")} </p>
        <p>1.3. {t("termsOfUse.1.3")} </p>
        <p>1.4. {t("termsOfUse.1.4")} </p>
        <p>1.5. {t("termsOfUse.1.5")} </p>
        <p>1.6. {t("termsOfUse.1.6")} </p>
        <h5>2. {t("termsOfUse.2.title")}</h5>
        <p>2.1. {t("termsOfUse.1.1")}</p>
        <p>2.2. {t("termsOfUse.1.2")}</p>
        <p>2.3. {t("termsOfUse.1.3")}</p>
        <p>2.4. {t("termsOfUse.1.4")}</p>
        <p>2.5. {t("termsOfUse.1.5")}</p>
        <p>2.6. {t("termsOfUse.1.6")}</p>
        <p className="ms-3">2.6.1. {t("termsOfUse.1.6-1")}</p>
        <p className="ms-3">2.6.2. {t("termsOfUse.1.6-2")} </p>
        <p>2.7.{t("termsOfUse.1.7")} </p>
        <h5>3. {t("termsOfUse.3.title")}</h5>
        <p>3.1. {t("termsOfUse.3.1")}</p>
        <p>3.2. {t("termsOfUse.3.2")}</p>
        <p>3.3. {t("termsOfUse.3.3")}</p>
        <p>3.4. {t("termsOfUse.3.4")}</p>
        <p>3.5. {t("termsOfUse.3.5")}</p>
        <p>3.6. {t("termsOfUse.3.6")}</p>
        <p>3.7. {t("termsOfUse.3.7")}</p>
        <p>3.8. {t("termsOfUse.3.8")}</p>
        <p>3.9. {t("termsOfUse.3.9")}</p>
        <p className="ms-3">3.9.1. {t("termsOfUse.3.9-1")}</p>
        <p className="ms-3">3.9.2. {t("termsOfUse.3.9-2")}</p>
        <p className="ms-3">3.9.3. {t("termsOfUse.3.9-3")}</p>
        <p className="ms-3">3.9.4. {t("termsOfUse.3.9-4")}</p>
        <p className="ms-3">3.9.5. {t("termsOfUse.3.9-5")}</p>
        <h5>4. {t("termsOfUse.4.title")}</h5>
        <p>4.1. {t("termsOfUse.4.1")}</p>
        <p>4.2. {t("termsOfUse.4.2")}</p>
        <p>4.3. {t("termsOfUse.4.3")}</p>
        <p>4.4. {t("termsOfUse.4.4")}</p>
        <p>4.5. {t("termsOfUse.4.5")}</p>
        <p>4.6. {t("termsOfUse.4.6")}</p>
        <h5>5. {t("termsOfUse.5.title")}</h5>
        <p>5.1. {t("termsOfUse.5.1")}</p>
        <p>5.2. {t("termsOfUse.5.2")}</p>
        <p>5.3. {t("termsOfUse.5.3")}</p>
        <p>5.4. {t("termsOfUse.5.4")}</p>
        <p className="ms-3">5.4.1. {t("termsOfUse.5.4-1")}</p>
        <p className="ms-3">5.4.2. {t("termsOfUse.5.4-2")}</p>
        <p className="ms-3">5.4.3. {t("termsOfUse.5.4-3")}</p>
        <p className="ms-3">5.4.4. {t("termsOfUse.5.4-4")}</p>
        <p className="ms-3">5.4.5. {t("termsOfUse.5.4-5")} </p>
        <p>5.5. {t("termsOfUse.5.5")}</p>
        <p>5.6. {t("termsOfUse.5.6")}</p>
        <h5>6. {t("termsOfUse.6.title")}</h5>
        <p>6.1. {t("termsOfUse.6.1")}</p>
        <p>6.2. {t("termsOfUse.6.2")}</p>
        <h5>7. {t("termsOfUse.7.title")}</h5>
        <p>7.1. {t("termsOfUse.7.1")}</p>
        <h5>8. {t("termsOfUse.8.title")}</h5>
        <p>8.1. {t("termsOfUse.8.1")}</p>
        <p>8.2. {t("termsOfUse.8.2")}</p>
        <p>8.3. {t("termsOfUse.8.3")}</p>
        <p>8.4. {t("termsOfUse.8.4")}</p>
        <p>8.5. {t("termsOfUse.8.5")}</p>
        <p>8.6. {t("termsOfUse.8.6")}</p>
        <p>8.7. {t("termsOfUse.8.7")}</p>
        <p>8.8. {t("termsOfUse.8.8")}</p>
        <h5>9. {t("termsOfUse.9.title")}</h5>
        <p>9.1. {t("termsOfUse.9.1")}</p>
        <p>9.2. {t("termsOfUse.9.2")}</p>
        <p>9.3. {t("termsOfUse.9.3")}</p>
        <p>9.4. {t("termsOfUse.9.4")}</p>
        <p>9.5. {t("termsOfUse.9.5")}</p>
        <p>9.6. {t("termsOfUse.9.6")}</p>
        <p>9.7. {t("termsOfUse.9.7")}</p>
        <p>9.8. {t("termsOfUse.9.8")}</p>
        <h5>10. {t("termsOfUse.10.title")}</h5>
        <p>
          10.1. {t("termsOfUse.10.1")}{" "}
          <LinkView to={aboutUrl}>{t("termsOfUse.10.1b")}</LinkView>{" "}
          {t("termsOfUse.10.1c")}{" "}
          <LinkView to={contactsUrl}>{t("termsOfUse.10.1d")}</LinkView>{" "}
        </p>{" "}
      </div>
    </Page>
  );
};

TermsOfUse.propTypes = {
  filter: PropTypes.object
};
export default TermsOfUse;
