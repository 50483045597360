import PropTypes from "prop-types";

import Link from "../../Link";

const Breadcrumbs = ({ items }) =>
  items ? (
    <div className="breadcrumbs">
      {items.map(({ id, title, link }, index) => (
        <span key={`breadcrumbs-${index}-${title}`}>
          {index !== 0 ? <span className="breadcrumb-separate">|</span> : null}
          <Link key={id} to={link} className="breadcrumb-link">
            {title}
          </Link>
        </span>
      ))}
    </div>
  ) : null;
Breadcrumbs.propTypes = {
  items: PropTypes.array
};

export default Breadcrumbs;
