import PropTypes from "prop-types";

import PrimaryButton from "./PrimaryButton/PrimaryButton";
import PrimaryOutlineButton from "./PrimaryOutlineButton/PrimaryOutlineButton";
import SecondaryButton from "./SecondaryButton/SecondaryButton";

const Button = (props) => {
  const { layout } = props;

  if (layout === "primary") return <PrimaryButton {...{ ...props }} />;
  if (layout === "primary-outline")
    return <PrimaryOutlineButton {...{ ...props }} />;
  if (layout === "secondary") return <SecondaryButton {...{ ...props }} />;
  return props.children;
};

Button.propTypes = {
  layout: PropTypes.string,
  children: PropTypes.any
};

Button.defaultValues = {
  layout: "primary",
  onClick: () => {}
};

export default Button;
