import OfferVacancy from "../components/Posts/OfferVacancy";
import Layout from "./Layout/Layout";

const OfferVacancyView = () => (
  <Layout>
    <OfferVacancy />
  </Layout>
);

export default OfferVacancyView;
