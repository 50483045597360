/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";

import Link from "../../../components/layout/Link";
import { catalogOfCompaniesUrl } from "../../../routes";

const Menu = ({ navigation, setIsShowCompaniesAndServices }) => {
  const { t } = useTranslation();
  return (
    <ul className="main-menu d-none d-lg-flex">
      {navigation.map((item) => (
        <li key={item.name} className={item.current ? "is-active" : ""}>
          {item.href === catalogOfCompaniesUrl ? (
            <Link
              to="#"
              onClick={() => setIsShowCompaniesAndServices(true)}
              key={item.name}
            >
              {t(`header.${item.name}`)}
            </Link>
          ) : (
            <Link to={item.href} key={item.name}>
              {t(`header.${item.name}`)}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};
export default Menu;
