import Login from "../components/Login";
import Layout from "./Layout/Layout";

const LoginView = () => (
  <Layout>
    <Login />
  </Layout>
);

export default LoginView;
