/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";

const LinkView = (props) => (
  <Link
    {...{
      ...props,
      className: `${props.className}`
    }}
  >
    {props.children}
  </Link>
);
export default LinkView;
