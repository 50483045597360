import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { isAgency } from "../../../../utility";

const ContactBlock = ({ post }) => {
  const { t } = useTranslation();
  return (
    <div className="page-preview-wrap">
      <label className="preview-label">{t("post.contactInformation")}</label>
      <ul className="preview-contacts">
        <li className="users text-break">
          <strong>{t("post.username")}</strong>
          <span>
            {post.user.name}{" "}
            {isAgency(post.isAgency, t, post.type)
              ? `( ${isAgency(post.isAgency, t, post.type)} )`
              : ""}
          </span>{" "}
        </li>
        {post?.linkYouTube ? (
          <li className="youtube text-break">
            <strong>{t("post.linkToTheVideoFromYouTube")}</strong>
            <span>
              {" "}
              <a href={post.linkYouTube}>{post.linkYouTube}</a>{" "}
            </span>
          </li>
        ) : null}
        {post.user.email ? (
          <li className="email text-break">
            <strong>{t("post.email")}</strong>
            <span>
              {" "}
              <a href={`mailto:${post.user.email}`}>
                {post.user.email}
                <br />
              </a>
              {post.isSendToEmail
                ? ` (${t("post.sendApplicationsByEmail")})`
                : null}
            </span>
          </li>
        ) : null}
        <li className="phone">
          <strong>{t("post.phone")}</strong>{" "}
          <span>
            <a href={`tel:${post.user.phone}`}> {post.user.phone}</a>{" "}
            <ul className="soc-links page-preview-soc">
              {post.user.isViber ? (
                <li className="soc-links-viber">
                  <a href={`viber://chat?number=${post.user.phone}`}> </a>
                </li>
              ) : null}
              {post.user.isWhatsapp ? (
                <li className="soc-links-whatsapp">
                  <a href={`whatsapp://send?phone=${post.user.phone}`}> </a>
                </li>
              ) : null}
              {post.user.isTelegram ? (
                <li className="soc-links-telegram">
                  <a href={`tg://resolve?domain=${post.user.phone}`}> </a>
                </li>
              ) : null}
            </ul>
          </span>
        </li>
      </ul>
    </div>
  );
};

ContactBlock.propTypes = {
  post: PropTypes.shape({
    isAgency: PropTypes.bool,
    isSendToEmail: PropTypes.bool,
    type: PropTypes.string,
    linkYouTube: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.number,
      email: PropTypes.string,
      isViber: PropTypes.bool,
      isTelegram: PropTypes.bool,
      isWhatsapp: PropTypes.bool
    })
  })
};

export default ContactBlock;
