/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { postTypes, userRoles } from "../../graphql/enum";
import { dateFormat } from "../../graphql/utils/date";
import {
  addPostUrl,
  carrierUrl,
  getPostUrl,
  homeUrl,
  iOfferAVacancyUrl,
  lookingForAJobUrl,
  privateAnnouncementUrl,
  rentUrl
} from "../../routes";
import { UserContext } from "../../utility/context/User";
import AutorizeUser from "../layout/AutorizeUser/AutorizeUser";
import Loading from "../layout/Loading/Loading";
import Page from "../layout/Page";
import IsRemovePostModal from "./Modals/IsRemovePostModal";
import SinglePostContacts from "./SinglePostContacts/SinglePostContacts";
import SinglePostMaps from "./SinglePostMaps";
import SinglePostOtherPosts from "./SinglePostOtherPosts";
import SinglePostTitle from "./SinglePostTitle/SinglePostTitle";

export const checkInsQuery = gql`
  query post($postId: ID!, $pagination: PaginationInput) {
    post(postId: $postId) {
      id
      title
      seo
      type
      expireInPriorityDate
      description
      createdAt
      isAgency
      linkYouTube
      isSendToEmail
      isOwner
      postIndexs {
        id
        title
      }
      channelTelegram {
        id
        link
        qrCodeImage {
          src
        }
      }
      images {
        id
        src
      }
      resume {
        id
        src
        name
      }
      addressMap {
        title
        lat
        lng
      }
      relatedToPost {
        otherPostsAuthor(pagination: $pagination) {
          id
          title
          seo
          type
          expireInPriorityDate
        }
        similarPosts(pagination: $pagination) {
          id
          title
          seo
          type
          expireInPriorityDate
        }
        posts(pagination: $pagination) {
          id
          title
          seo
          type
          expireInPriorityDate
        }
      }
      user {
        name
        email
        phone
        isViber
        isTelegram
        isWhatsapp
      }
      announcement {
        city {
          id
          title
        }
        postAnnouncementCategory {
          id
          title
        }
        postAnnouncementRubric {
          id
          title
        }
      }
      offerVacancy {
        city {
          id
          title
        }
        professions {
          id
          title
        }
        categoryProfession {
          id
          title
        }
        paymentPeriod {
          id
          title
        }
        paymentPeriodId
        workSchedule
        isWorkForMinors
        isAssistantJob
        sum
        isWorkForAMarriedCouple
        isYouCanWorkOnWeekends
        isUTR
        isNationalInsurance
        isTools
        isCar
      }
      lookingJob {
        city {
          id
          title
        }
        professions {
          id
          title
        }
        categoryProfession {
          id
          title
        }
        isAssistantJob
        isLookingJobMinors
        isWorkForAMarriedCouple
        isYouCanWorkOnWeekends
        isUTR
        isNationalInsurance
        isDriverLicense
        isTools
        isCar
      }
      rentProperty {
        postId
        cityId
        numberOfRooms
        numberOfBathroom
        isCarParking
        isGarden
        isUtilitiesIncluded
        isBicycleSpace
        isPropertyForSale
        isWifi
        price
        rentalPeriodId
        typeRooms {
          id
          title
        }
        postRentPropertyCategoryId
        postRentPropertyCategory {
          id
          title
        }
        city {
          id
          title
        }
        rentalPeriod {
          id
          title
        }
      }
      carrier {
        dateDeparture
        cityAlongRoute
        carrierCountryArrivalId
        carrierCountryDepartureId
        cityArrival
        cityDeparture
        countryAlongRouteIds
        countryAlongRoute {
          id
          title
        }
        carrierCountryArrival {
          id
          title
        }
        carrierCountryDeparture {
          id
          title
        }
      }
    }
  }
`;

const getUrlInPosts = (type) => {
  if (type === postTypes.announcements) {
    return privateAnnouncementUrl;
  }
  if (type === postTypes.offerVacancy) {
    return iOfferAVacancyUrl;
  }
  if (type === postTypes.lookingJob) {
    return lookingForAJobUrl;
  }
  if (type === postTypes.rentProperty) {
    return rentUrl;
  }
  if (type === postTypes.carrier) {
    return carrierUrl;
  }
  return null;
};

const getCatrgoryPost = (post) => {
  if (post.type === postTypes.announcements) {
    return {
      id: post.id,
      title: `announcementCategory.${post.announcement.postAnnouncementCategory.title}`,
      searchCategory: `?postAnnouncementCategoryIds=${post.announcement.postAnnouncementCategory.id}`
    };
  }
  if (post.type === postTypes.offerVacancy) {
    return {
      id: post.id,
      title: `categoryProfession.${post.offerVacancy.categoryProfession.title}`,
      searchCategory: `?categoryProfessionId=${post.offerVacancy.categoryProfession.id}`
    };
  }
  if (post.type === postTypes.lookingJob) {
    return {
      id: post.id,
      title: `categoryProfession.${post.lookingJob.categoryProfession.title}`,
      searchCategory: `?categoryProfessionId=${post.lookingJob.categoryProfession.id}`
    };
  }
  if (post.type === postTypes.rentProperty) {
    return {
      id: post.id,
      title: `postRentPropertyCategory.${post.rentProperty.postRentPropertyCategory.title}`,
      searchCategory: `?postRentPropertyCategoryId=${post.rentProperty.postRentPropertyCategory.id}`
    };
  }
  if (post.type === postTypes.carrier) {
    return post.carrier.carrierCountryArrival.title;
  }
  return {};
};

const SinglePost = () => {
  const { postId, seo } = useParams();
  const pagination = {
    offset: 0,
    limit: 5
  };
  const [isShowDeletePost, setIsShowDeletePost] = useState(false);
  const history = useHistory();
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  const { loading, data } = useQuery(checkInsQuery, {
    variables: {
      postId,
      pagination
    }
  });

  const editHendler = async () => {
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        postId,
        edit: true
      }
    });
    history.push(url);
  };

  if (loading) return <Loading />;

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "type",
      title: t(`createPost.${data?.post?.type}`),
      link: getUrlInPosts(data?.post?.type)
    },
    {
      id: "category",
      title: t(getCatrgoryPost(data.post).title),
      link:
        getUrlInPosts(data?.post?.type) +
        getCatrgoryPost(data.post).searchCategory
    },
    {
      id: "post",
      title: t(data.post.title),
      link: getPostUrl(postId, seo)
    }
  ];

  return (
    <Page
      pageName={
        <>
          {t(data.post.type)}{" "}
          <AutorizeUser
            autorizeComponent={
              data.post.isOwner ? (
                <sub>
                  {data.post.expireInPriorityDate > data.post.createdAt
                    ? t("singlePost.yourPaid", {
                        date: moment
                          .utc(data.post.expireInPriorityDate)
                          .format(dateFormat)
                      })
                    : null}
                </sub>
              ) : null
            }
          />
        </>
      }
      modals={[
        <IsRemovePostModal
          isShow={isShowDeletePost}
          onClose={() => setIsShowDeletePost(false)}
          postId={postId}
        />
      ]}
      breadcrumbs={breadcrumbs}
      titleAfter={
        <AutorizeUser
          autorizeComponent={
            data.post.isOwner || user.role === userRoles.admin ? (
              <div className="preview-buttons mb-lg-0 mb-3">
                <button
                  onClick={editHendler}
                  className="btn btn-primary btn-icon btn-icon-edit"
                >
                  {t("singlePost.edit")}
                </button>
                <button
                  onClick={() => setIsShowDeletePost(true)}
                  className="btn btn-thirdy btn-icon btn-icon-remove"
                >
                  {t("singlePost.delete")}
                </button>
              </div>
            ) : null
          }
        />
      }
    >
      <SinglePostTitle post={data.post} />
      <SinglePostContacts post={data.post} />
      <SinglePostMaps post={data.post} />
      <SinglePostOtherPosts post={data.post} />
    </Page>
  );
};

SinglePost.propTypes = {
  filter: PropTypes.object
};
export default SinglePost;
