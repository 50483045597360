/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getPostUrl, homeUrl } from "../../../routes";
import Link from "../../layout/Link";

const ThirdStage = ({ postSeo, postId }) => {
  const { t } = useTranslation();

  return (
    <div className="thanks-wrap w-100 py-5">
      <h1 className="thanks-title">
        {t("createPostThirdStage.hooray")} <br />
        {t("createPostThirdStage.yourAdHasBeenPlaced")}
      </h1>
      <div className="thanks-btn">
        <Link
          to={getPostUrl(postId, postSeo)}
          className="btn btn-outline-primary"
        >
          {" "}
          {t("createPostThirdStage.viewTheAdvertisement")}
        </Link>
        <Link to={homeUrl} className="ms-2 btn btn-primary">
          {t("createPostThirdStage.onTheMainPage")}
        </Link>
      </div>
      <div className="thanks-image">
        <img src="/assets/img/hitchhiker.png" alt="" />
      </div>
    </div>
  );
};

ThirdStage.propTypes = {
  post: PropTypes.object,
  code: PropTypes.string,
  update: PropTypes.func,
  postSeo: PropTypes.string,
  postId: PropTypes.string
};
export default ThirdStage;
