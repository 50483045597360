/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import deletePost from "../../../graphql/mutation/deletePost";
import { homeUrl } from "../../../routes";
import Modal from "../../layout/Modal";

const IsRemovePostModal = ({
  isShow,
  onClose,
  postId,
  afterSuccessDeleteFun,
  deletePostFun = null
}) => {
  const { t } = useTranslation();
  const [deletePostMutation] = useMutation(deletePost);
  const history = useHistory();

  const deletePostHendler = async () => {
    if (deletePostFun) {
      deletePostFun();
      return;
    }
    if (!postId) return;
    const { data: dataDeletePost } = await deletePostMutation({
      variables: {
        postId
      }
    });
    if (dataDeletePost.deletePost.success) {
      if (afterSuccessDeleteFun) {
        afterSuccessDeleteFun();
        return;
      }
      const url = queryString.stringifyUrl({
        url: homeUrl,
        query: {}
      });
      history.push(url);
    }
  };
  return (
    <Modal isShow={isShow} onClose={onClose}>
      <div className="block-no-content__image text-center">
        <img
          src="/assets/img/emoji-remove.svg"
          width="130"
          height="130"
          alt="Удалить объявление?"
        />
      </div>
      <h2 className="block-no-content__title text-center">
        {t("modal.deleteTheAd")}
      </h2>
      <div className="block-no-content__button text-center">
        <button className="btn btn-fourth" onClick={() => deletePostHendler()}>
          {t("modal.yes")}
        </button>
        <button className="btn btn-thirdy" onClick={() => onClose()}>
          {t("modal.no")}
        </button>
      </div>
    </Modal>
  );
};
IsRemovePostModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  afterSuccessDeleteFun: PropTypes.func,
  deletePostFun: PropTypes.func
};

export default IsRemovePostModal;
