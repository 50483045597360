import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "../../../../layout/Input/Input";
import Loading from "../../../../layout/Loading/Loading";
import Select from "../../../../layout/Select";

export const allDataQuery = gql`
  query allData {
    professions {
      id
      title
    }
  }
`;

const QuestionnaireWriteYourProfessionsAndpPlacesOfWork = ({
  setValues,
  values,
  idx,
  valuesFormik,
  error
}) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(allDataQuery);
  if (loading || !data) return <Loading />;
  return (
    <>
      <div className="col-lg-6 mb-4">
        <Select
          label={t("questionnaire.workedByProfessionId")}
          className="form-select"
          id={`inputProfession-${idx}`}
          value={values.workedByProfessionId}
          options={data.professions.map((profession) => ({
            ...profession,
            title: t(`profession.${profession.title}`)
          }))}
          onChange={(val) =>
            setValues({ ...values, workedByProfessionId: val })
          }
          aria-label="Default select example"
        />
      </div>
      <div className="col-lg-6 mb-4">
        <Input
          id={`inputPlaceWork-${idx}`}
          name={`placeOfWork[${idx}]`}
          aria-label="Default select example"
          label={t("questionnaire.placeOfWork")}
          value={valuesFormik?.placeOfWork ? valuesFormik.placeOfWork[idx] : ""}
          disabled={!values.workedByProfessionId}
          error={error}
        />
      </div>
    </>
  );
};

QuestionnaireWriteYourProfessionsAndpPlacesOfWork.propTypes = {
  values: PropTypes.shape({
    workedByProfessionId: PropTypes.string
  }),
  setValues: PropTypes.func,
  idx: PropTypes.number,
  valuesFormik: PropTypes.object,
  error: PropTypes.string
};
export default QuestionnaireWriteYourProfessionsAndpPlacesOfWork;
