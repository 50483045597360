import PropTypes from "prop-types";

import { postTypes } from "../../../../graphql/enum";
import AnnouncementListInformation from "./AnnouncementListInformation";
import CarrierListInformation from "./CarrierListInformation/CarrierListInformation";
import LookingJobListInformation from "./LookingJobListInformation/LookingJobListInformation";
import OfferVacancyListInformation from "./OfferVacancyListInformation/OfferVacancyListInformation";
import RentPropertyListInformation from "./RentPropertyListInformation/RentPropertyListInformation";

const ListInformationPost = ({ post }) => {
  if (post.type === postTypes.announcements)
    return (
      <AnnouncementListInformation allPost={post} post={post.announcement} />
    );
  if (post.type === postTypes.lookingJob)
    return <LookingJobListInformation allPost={post} post={post.lookingJob} />;
  if (post.type === postTypes.offerVacancy)
    return (
      <OfferVacancyListInformation allPost={post} post={post.offerVacancy} />
    );
  if (post.type === postTypes.rentProperty)
    return (
      <RentPropertyListInformation allPost={post} post={post.rentProperty} />
    );
  if (post.type === postTypes.carrier)
    return <CarrierListInformation allPost={post} post={post.carrier} />;

  return null;
};

ListInformationPost.propTypes = {
  post: PropTypes.object
};
export default ListInformationPost;
