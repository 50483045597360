import moment from "moment";

import { hoursWithMinutesFormat } from "../graphql/utils/date";

export const timeIsoFormat = "HH:mm:ssZ";
export const dateIsoFormat = "DD-MM-YYYY";
export const milisecondsPerMinute = 60000;
const minutesPerHour = 60;
const hoursPerDay = 24;
const daysPostOld = 60;

export const formatDateTime = (date) => {
  const localDate = new Date(date);
  return moment(localDate).format("DD MMM YYYY HH:mm");
};

export const convertDateToUTC = (date) => {
  const dateUTC = new Date(date);
  return moment(dateUTC, dateIsoFormat);
};

export const addDays = (date, days) => {
  date.setTime(
    date.getTime() + days * hoursPerDay * milisecondsPerMinute * minutesPerHour
  );
  return date.getTime();
};
export const convertDateToUTCString = (element) =>
  moment(element).format(dateIsoFormat);

export const getTextPreviwe = (date, t) => {
  const dateTodayFrom = new Date();
  const dateTodayTo = new Date();

  dateTodayFrom.setHours(0, 0, 0);

  dateTodayTo.setHours(23, 59, 59);
  if (dateTodayFrom <= date && dateTodayTo >= date)
    return `${t("post.publicationTodayBy")} ${moment
      .utc(date)
      .format(hoursWithMinutesFormat)}`;
  const dateYesterdayFrom = addDays(dateTodayFrom);
  const dateYesterdayTo = addDays(dateTodayTo);
  if (dateYesterdayFrom < date && dateYesterdayTo > date)
    return `${t("post.publicationYesterdayBy")} ${moment
      .utc(date)
      .format(hoursWithMinutesFormat)}`;

  return `${t("post.publicationOf")} ${moment.utc(date).format("DD.MM.YYYY")}`;
};

export const getIsPostOld = (date) => {
  const dateToday = new Date();
  const dateTwoDaysAgo = new Date(dateToday);
  dateTwoDaysAgo.setDate(dateToday.getDate() - daysPostOld);

  return date < dateTwoDaysAgo;
};
