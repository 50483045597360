/* eslint-disable import/no-extraneous-dependencies */
import "./captcha.css";

import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import NumericCaptcha from "react-numeric-captcha";

const Captcha = ({ isValidCaptcha = true, onChange }) => {
  const { t } = useTranslation();
  return (
    <div
      classNname={
        isValidCaptcha ? "border border-danger" : "border border-success"
      }
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: !isValidCaptcha ? "red" : "green",
        borderRadius: "5px"
      }}
    >
      <NumericCaptcha
        onChange={(r) => {
          onChange(r);
        }}
        placeholder={t("captcha.insertNumber")} // optional
      />
    </div>
  );
};
Captcha.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValidCaptcha: PropTypes.bool
};

export default Captcha;
