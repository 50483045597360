import ForgotPassword from "../components/ForgotPassword";
import Layout from "./Layout/Layout";

const ForgotPasswordView = () => (
  <Layout>
    <ForgotPassword />
  </Layout>
);

export default ForgotPasswordView;
