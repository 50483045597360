/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { postTypes } from "../../../graphql/enum";
import Page from "../../layout/Page";
import TabsPost from "../TabsPost/TabsPost";
import LookingJobTable from "./LookingJobTable/LookingJobTable";
import Sidebar from "./SideBar";

const LookingJob = () => {
  const { t } = useTranslation();

  const parameters = new URLSearchParams(window.location.search);
  const cityId = parameters.getAll("cityId")[0];
  const postIndexs = parameters.getAll("postIndexs");
  const professionsId = parameters.getAll("professionsId");
  const categoryProfessionId = parameters.getAll("categoryProfessionId");
  const findOfferForFamily = parameters.getAll("findOfferForFamily");
  const iWorkOnWeekends = parameters.getAll("iWorkOnWeekends");
  const isAssistantJob = parameters.getAll("isAssistantJob");
  const isLookingJobMinors = parameters.getAll("isLookingJobMinors");
  const womenCanAlsoWork = parameters.getAll("womenCanAlsoWork");
  const nationalInsurance = parameters.getAll("nationalInsurance");
  const UTR = parameters.getAll("UTR");
  const tools = parameters.getAll("tools");
  const cars = parameters.getAll("cars");
  const driverLicense = parameters.getAll("driverLicense");
  const announcementFromAgencies = parameters.getAll(
    "announcementFromAgencies"
  );
  const privateAds = parameters.getAll("privateAds");
  const search = parameters.getAll("search")[0];
  const currentPage = parameters.getAll("currentPage")[0];

  const defaultFilter = {
    cityId,
    postIndexs,
    professionsId,
    categoryProfessionId,
    findOfferForFamily,
    iWorkOnWeekends,
    womenCanAlsoWork,
    isAssistantJob,
    isLookingJobMinors,
    announcementFromAgencies,
    privateAds,
    search,
    nationalInsurance,
    UTR,
    tools,
    driverLicense,
    cars,
    currentPage: parseInt(currentPage, 10) || 1
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [totalPosts, setTotalPosts] = useState(0);

  return (
    <Page
      sidebar={{
        mobileTextButton: t("filter"),
        render: () => (
          <Sidebar
            filters={filters}
            setFilters={setFilters}
            totalPosts={totalPosts}
            typeActive={postTypes.lookingJob}
          />
        ),
        after: () => (
          <p className="works-count d-lg-none">{`${t(
            "post.totalPosts"
          )} ${totalPosts}`}</p>
        )
      }}
      beforeContent={
        <TabsPost
          typeActive={postTypes.lookingJob}
          classname="type-menu-horizontal"
        />
      }
    >
      <LookingJobTable
        {...{
          filters,
          setTotalPosts,
          currentPage: filters.currentPage,
          setCurrentPage: (newPage) => {
            setFilters({ ...filters, currentPage: newPage });
          }
        }}
      />
    </Page>
  );
};

LookingJob.propTypes = {
  filter: PropTypes.object
};
export default LookingJob;
