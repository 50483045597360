/* eslint-disable react-hooks/exhaustive-deps */
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import { classNames } from "../../../utility";

const TextRedactor = ({ errors, validateForm, values, setValues }) => {
  const blocksFromHTML = htmlToDraftjs(values?.description || "");
  const { contentBlocks, entityMap } = blocksFromHTML;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorStateStub = EditorState.createWithContent(contentState);

  const [editorState, setEditorState] = useState(editorStateStub);

  useEffect(() => {
    if (values?.description) return;
    const main = async () => {
      setEditorState(editorStateStub);
    };
    main();
  }, [values?.description]);

  useEffect(() => {
    const main = async () => {
      const currentContent = editorState.getCurrentContent();
      const rawContentState = convertToRaw(currentContent);

      const descriptionHtml = draftToHtml(rawContentState);

      await setValues({
        ...values,
        description: descriptionHtml
      });

      validateForm();
    };
    main();
  }, [editorState]);

  return (
    <div className="d-flex w-100">
      <Editor
        editorState={editorState}
        toolbarClassName={errors?.description ? "is-invalid" : ""}
        toolbarStyle={{
          background: "none",
          border: "5px 5px 5px 5px solid #ccc",
          borderBottom: `1px solid ${
            errors?.description ? "#FF0000" : "#c4c4c4"
          }`
        }}
        wrapperClassName={classNames(
          "form-control",
          errors?.description ? "is-invalid" : ""
        )}
        wrapperStyle={{
          width: "100%",
          padding: "0"
        }}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "colorPicker",
            "history"
          ],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"]
          },

          list: {
            options: ["unordered", "ordered"]
          },
          remove: { className: "bg-transparent border-0" },
          history: {
            options: ["undo", "redo"]
          }
        }}
        editorClassName=""
        onEditorStateChange={async (e) => {
          setEditorState(e);
        }}
        editorStyle={{
          height: "auto",
          minHeight: "120px",
          padding: "9.5px 20px"
        }}
      />
    </div>
  );
};

TextRedactor.propTypes = {
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.shape({
    description: PropTypes.string
  })
};

TextRedactor.defaultProps = {
  options: [],
  disabled: false
};

export default TextRedactor;
