import CreatePost from "../components/CreatePost/CreatePost";
import Layout from "./Layout/Layout";

const PeopleView = () => (
  <Layout>
    <CreatePost />
  </Layout>
);

export default PeopleView;
