import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PropTypes from "prop-types";
import React, { useCallback, useRef } from "react";

import { googleMapKey } from "../../../config";

const containerStyle = {
  width: "100%",
  height: "450px"
};

const center = { lat: 51.5072178, lng: -0.1275862 };
const libraries = ["places"];

const Maps = ({ coordinates }) => {
  const mapRef = useRef(undefined);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapKey,
    libraries
  });
  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = undefined;
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates || center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={coordinates} />
    </GoogleMap>
  ) : (
    <></>
  );
};

Maps.propTypes = {
  coordinates: PropTypes.object
};
export default Maps;
