import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { catalogOfCompaniesUrl, homeUrl } from "../../routes";
import LinkView from "../layout/Link";
import Page from "../layout/Page";

const CatalogOfCompaniesStub = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "catalogOfCompaniesUrl",
      title: t("header.catalogOfCompaniesUrl"),
      link: catalogOfCompaniesUrl
    }
  ];
  return (
    <Page
      pageName={t("header.catalogOfCompaniesUrl")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="works-inner works-inner-full no-border">
        <div className="block-no-content block-no-content-lg">
          <div className="block-no-content__image text-center">
            <img
              src="assets/img/emoji-no-content.svg"
              width="130"
              height="130"
              alt="У вас пока нет анкеты"
            />
          </div>
          <h2 className="block-no-content__title text-center">
            {t("stub.documentsInEngland.title")}
            <br />
            {t("stub.documentsInEngland.excusesForInconvenience")}
          </h2>
          <div className="block-no-content__button text-center">
            <LinkView href="#" className="btn btn-primary">
              {t("toHome")}
            </LinkView>
          </div>
        </div>
      </div>
    </Page>
  );
};

CatalogOfCompaniesStub.propTypes = {
  filter: PropTypes.object
};
export default CatalogOfCompaniesStub;
