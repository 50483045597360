import parse from "html-react-parser";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getIsPostOld, getTextPreviwe } from "../../../utility/date";
import ImageGalareyPost from "../../layout/Post/ImageGalareyPost/ImageGalareyPost";
import ListData from "../../layout/Post/ListData/ListData";

const SinglePostTitle = ({ post }) => {
  const { t } = useTranslation();
  const isPostOld = getIsPostOld(new Date(post.expireInPriorityDate));

  return (
    <div className="page-single-main page-single-wrap ">
      <div className="page-single-main-inner">
        <p className="page-single-date">
          {getTextPreviwe(new Date(post.createdAt), t)}
        </p>
        <h1 className="page-single-title text-break">{post.title}</h1>
        <ul className="page-single-list">
          <ListData post={post} />
        </ul>
        <>
          <div className="page-single-content">
            <label className="page-single-label">{t("post.description")}</label>
            <p className="text-break">{parse(post.description)}</p>
            {isPostOld && (
              <h3 style={{ color: "red" }}>
                {parse(t("post.publicationOutdated"))}
              </h3>
            )}
          </div>
        </>
        {post.images.length !== 0 ? (
          <div className="page-single-images">
            <ImageGalareyPost images={post.images} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

SinglePostTitle.propTypes = {
  post: PropTypes.object
};
export default SinglePostTitle;
