/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { postTypes } from "../../../graphql/enum";
import Page from "../../layout/Page";
import TabsPost from "../TabsPost/TabsPost";
import Sidebar from "./CarrierSideBar";
import RentPropertyTable from "./CarrierTable/CarrierTable";

const RentProperty = () => {
  const { t } = useTranslation();

  const parameters = new URLSearchParams(window.location.search);
  const carrierCountryDepartureId = parameters.getAll(
    "carrierCountryDepartureId"
  );
  const carrierCountryArrivalId = parameters.getAll("carrierCountryArrivalId");
  const cityDeparture = parameters.getAll("cityDeparture");
  const cityArrival = parameters.getAll("cityArrival");
  const postCarrierCountryAlongRoute = parameters.getAll(
    "postCarrierCountryAlongRoute"
  );
  const dateDepartureFrom = parameters.getAll("dateDepartureFrom")[0];
  const dateDepartureTo = parameters.getAll("dateDepartureTo")[0];
  const search = parameters.getAll("search")[0];
  const currentPage = parameters.getAll("currentPage")[0];

  const defaultFilter = {
    carrierCountryDepartureId,
    carrierCountryArrivalId,
    cityDeparture,
    cityArrival,
    postCarrierCountryAlongRoute,
    dateDepartureFrom: dateDepartureFrom ? new Date(dateDepartureFrom) : null,
    dateDepartureTo: dateDepartureTo ? new Date(dateDepartureTo) : null,
    search,
    currentPage: parseInt(currentPage, 10) || 1
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [totalPosts, setTotalPosts] = useState(0);

  return (
    <Page
      sidebar={{
        mobileTextButton: t("filter"),
        render: () => (
          <Sidebar
            filters={filters}
            setFilters={setFilters}
            totalPosts={totalPosts}
            typeActive={postTypes.carrier}
          />
        ),
        after: () => (
          <p className="works-count d-lg-none">{`${t(
            "post.totalPosts"
          )} ${totalPosts}`}</p>
        )
      }}
      beforeContent={
        <TabsPost
          classname="type-menu-horizontal"
          typeActive={postTypes.carrier}
        />
      }
    >
      <RentPropertyTable
        {...{
          filters,
          setTotalPosts,
          currentPage: filters.currentPage,
          setCurrentPage: (newPage) => {
            setFilters({ ...filters, currentPage: newPage });
          }
        }}
      />
    </Page>
  );
};

RentProperty.propTypes = {
  filter: PropTypes.object
};
export default RentProperty;
