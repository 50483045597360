/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import deletePostByCode from "../../../graphql/mutation/deletePostByCode";
import showPostByCode from "../../../graphql/mutation/showPostByCode";
import { addPostUrl, homeUrl } from "../../../routes";
import Post from "../../layout/Post";
import ManageBlock from "./ManageBlock/ManageBlock";
import PayPost from "./PayPost/PayPost";

const SecondStage = ({ post, code, refetch }) => {
  const [deletePostByCodeMutation] = useMutation(deletePostByCode);
  const [showPostByCodeMutation] = useMutation(showPostByCode);
  const history = useHistory();
  const deletePostByCodeFun = async () => {
    deletePostByCodeMutation({
      variables: {
        code
      }
    });
    history.push(homeUrl);
  };
  const showPostByCodeFun = async () => {
    await showPostByCodeMutation({
      variables: {
        code
      }
    });
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        postId: post?.id,
        addPostSuccess: true
      }
    });
    history.push(url);
    await refetch();
  };
  const editByCodeFun = async () => {
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        activeTab: post.type,
        code,
        edit: true
      }
    });
    history.push(url);
  };
  return (
    <div className="page-preview">
      <div className="page-preview-left">
        <Post post={post} />
        <ManageBlock
          deletePostByCode={deletePostByCodeFun}
          editPostByCode={editByCodeFun}
          showPostByCodeFun={showPostByCodeFun}
        />
      </div>
      <div className="page-preview-right">
        <PayPost
          deletePostByCode={deletePostByCodeFun}
          showPostByCodeFun={showPostByCodeFun}
          code={code}
          editPostByCode={editByCodeFun}
        />
      </div>
    </div>
  );
};

SecondStage.propTypes = {
  post: PropTypes.object,
  code: PropTypes.string,
  refetch: PropTypes.func
};
export default SecondStage;
