/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { numberOfRooms } from "../../../../../graphql/enum";
import Hint from "../../../../layout/Hint";
import Select from "../../../../layout/Select";
import MultiSelect from "../../../../layout/Select/MultiSelect";
import FieldsAddPost, {
  fieldsAddPostTypes
} from "../../CreatePostLayout/FieldsAddPost";

const FirstBlock = ({
  data,
  errors,
  values,
  setValues,
  validateForm,
  valuesFormik,
  setValuesFormik
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          errors={errors}
          valuesFormik={valuesFormik}
          type={fieldsAddPostTypes.title}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <Select
          key="rentPropertyCategory"
          value={values.postRentPropertyCategory}
          options={data.postRentPropertyCategory.map((p) => ({
            ...p,
            title: t(`postRentPropertyCategory.${p.title}`)
          }))}
          id="postRentPropertyCategory"
          error={errors?.postRentPropertyCategory}
          label={
            <>
              *{t("createPost.categoryRent")}
              <Hint components={t("hint.categoryRent")} />
            </>
          }
          onChange={async (val) => {
            await setValues({ ...values, postRentPropertyCategory: val });
            validateForm();
          }}
        />
      </div>
      <div className="col-lg-6 mb-4 mb-lg-3">
        <MultiSelect
          className="form-select"
          id="inputRent"
          key="inputRent"
          aria-label="Default select example"
          options={data.typeRooms.map((p) => ({
            ...p,
            title: t(`typeRoom.${p.title}`)
          }))}
          value={values.typeRooms}
          onChange={async (val) => {
            await setValues({ ...values, typeRooms: val });
            validateForm();
          }}
          label={
            <>
              *{t("createPost.typeRoom")}
              <Hint components={t("hint.typeRoom")} />
            </>
          }
          error={errors?.typeRooms}
        />
      </div>
      <div className="col-lg-8">
        <div className="row">
          <FieldsAddPost
            {...{
              values,
              setValues,
              errors,
              validateForm,
              data,
              isRequairedCity: false,
              type: fieldsAddPostTypes.cityAndPostCode,
              isMultiSelect: true
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 mb-4">
        <MultiSelect
          className="form-select"
          id="inputRent"
          key="inputRent"
          aria-label="Default select example"
          options={numberOfRooms}
          value={values.numberOfRooms}
          onChange={async (val) => {
            await setValues({ ...values, numberOfRooms: val });
            validateForm();
          }}
          label={<>*{t("createPost.numberOfRoom")}</>}
          error={errors?.numberOfRooms}
        />
      </div>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          {...{
            errors,
            validateForm,
            setValues,
            values,
            type: fieldsAddPostTypes.description
          }}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <div className="mb-3">
          <FieldsAddPost
            valuesFormik={valuesFormik}
            type={fieldsAddPostTypes.phone}
            errors={errors}
            setValuesFormik={setValuesFormik}
          />
        </div>
        <div className="form-check-inline-wrap" style={{ height: "inherit" }}>
          <FieldsAddPost type={fieldsAddPostTypes.messages} height="d" />{" "}
        </div>
      </div>
      <div className="col-lg-6 mb-4">
        <FieldsAddPost
          valuesFormik={valuesFormik}
          type={fieldsAddPostTypes.email}
          errors={errors}
        />
      </div>
    </>
  );
};

FirstBlock.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  data: PropTypes.object,
  setValuesFormik: PropTypes.func,
  valuesFormik: PropTypes.object
};
export default FirstBlock;
