import {
  catalogOfCompaniesUrl,
  homeUrl,
  questionnairesListUrl,
  siteArticlesUrl
} from "../routes";

export const lang = {
  en: "en",
  ua: "ua",
  ro: "ro",
  rus: "rus"
};

export const typeMenu = {
  user: "user",
  organization: "organization",
  noOrganizationCreated: "noOrganizationCreated"
};

export const getProfile = ({ name, email, avatar }) => ({
  name,
  email,
  imageUrl: avatar
});

export const getNavigationMenu = (user, pathname) => [
  {
    name: "home",
    href: homeUrl,
    current: homeUrl === pathname
  },
  {
    name: "questionnairesOfWorkersUrl",
    href: questionnairesListUrl,
    current: pathname === questionnairesListUrl
  },
  {
    name: "catalogOfCompaniesUrl",
    href: catalogOfCompaniesUrl,
    current: pathname === catalogOfCompaniesUrl
  },
  {
    name: "siteArticles",
    href: siteArticlesUrl,
    current: pathname === siteArticlesUrl
  }
];

export const getDefaultValueForDropdown = (name) => ({
  id: "all",
  name,
  value: null
});
