/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DenamicFieldsLayout = ({
  values,
  setValues,
  Component,
  keyValues,
  valuesFormik,
  validateForm,
  errors,
  setValuesFormik,
  keyValuesFormik
}) => {
  const { t } = useTranslation();

  const canAddField = values[keyValues].length < 6;

  return (
    <>
      {values[keyValues].map((value, idx) => (
        <div className="dynamical-field" key={idx}>
          <div className="row align-items-end">
            <Component
              values={value}
              idx={idx}
              error={errors[`${keyValues}.${idx}`]}
              setValues={async (objectValue) => {
                const newValue = { ...values };
                newValue[keyValues] = [...values[keyValues]];
                newValue[keyValues][idx] = objectValue;

                const keys = Object.keys(objectValue);

                if (!objectValue[keys[0]]) {
                  const newValueFormik = {
                    [keyValuesFormik]: valuesFormik[keyValuesFormik] ?? []
                  };
                  newValueFormik[keyValuesFormik][idx] = "";

                  await setValuesFormik({
                    ...valuesFormik,
                    newValueFormik
                  });
                }

                await setValues(newValue);
                validateForm();
              }}
              valuesFormik={valuesFormik}
            />
          </div>
        </div>
      ))}

      {canAddField && (
        <div className="dynamical-field-btn">
          <span
            className="btn btn-icon btn-secondary btn-icon-plus"
            onClick={() => {
              const newValue = { ...values };
              newValue[keyValues] = [...values[keyValues], {}];
              setValues(newValue);
            }}
          >
            {t("questionnaire.addAnotherField")}
          </span>
        </div>
      )}
    </>
  );
};

DenamicFieldsLayout.propTypes = {
  values: PropTypes.object,
  Component: PropTypes.any,
  keyValues: PropTypes.string,
  setValues: PropTypes.func,
  valuesFormik: PropTypes.object,
  validateForm: PropTypes.func,
  errors: PropTypes.object,
  setValuesFormik: PropTypes.func,
  keyValuesFormik: PropTypes.string
};

export default DenamicFieldsLayout;
