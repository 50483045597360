import PropTypes from "prop-types";

import { classNames } from "../../../../utility";

const PrimaryOutlineButton = ({ children, onClick, className }) => (
  <span
    onClick={onClick}
    className={classNames("btn btn-outline-primary", className)}
  >
    {children}
  </span>
);

PrimaryOutlineButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default PrimaryOutlineButton;
