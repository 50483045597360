import { gql } from "@apollo/client";

const createPost = gql`
  mutation createPost($input: PostInput) {
    createPost(input: $input) {
      success
      code
      accessCode
    }
  }
`;

export default createPost;
