/* eslint-disable max-lines */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { postTypes, typeField } from "../../../../graphql/enum";
import { isSelectedAllPostCode } from "../../../../utility";
import { convertDateToUTCString } from "../../../../utility/date";

const slipe = ", ";

const getParms = (post, findParms) =>
  findParms
    .map((r) => (post[r.key] ? r.string : null))
    .filter((s) => s)
    .join(slipe);

const ListInformationPosteElement = ({ allPost, element, type }) => {
  const { t } = useTranslation();

  if (
    type === typeField.paymentPeriod &&
    (element?.sum || element?.paymentPeriodId)
  )
    return (
      <li
        key={element?.id}
        className="preview-price preview-price-earnings"
        style={{ color: "#9F1005" }}
      >
        <strong style={{ color: "#9F1005" }}>
          {t(element?.sum)}
          {"£"}
        </strong>{" "}
        {t(`paymentPeriod.${element?.paymentPeriod?.title}`)}{" "}
      </li>
    );
  if (type === typeField.paymentPeriodRent) {
    return (
      <li
        key="priceRent"
        className="page-single-price"
        style={{ color: "#9F1005" }}
      >
        {t(element?.price)} £{" "}
        {element?.rentalPeriod?.id
          ? t(`rentalPeriod.${element?.rentalPeriod?.title}`)
          : ""}
      </li>
    );
  }
  if (type === typeField.workSchedule && element)
    return (
      <li key={element?.id} className="alarm">
        <strong>{t("post.schedule")}</strong> {element}
      </li>
    );
  if (type === typeField.typeRooms && element?.length)
    return (
      <li key={element?.id} className="door">
        <strong>{t("post.typeRoom")}</strong>{" "}
        {element.map(({ title }) => t(`typeRoom.${title}`)).join(slipe)}
      </li>
    );
  if (type === typeField.city && element?.id)
    return (
      <li key={element?.id} className="departure">
        <strong>{t("post.cityInEngland")}</strong> {t(`city.${element?.title}`)}
      </li>
    );

  if (type === typeField.professions && element?.length)
    return (
      <li key={`portfolio-profession`} className="portfolio">
        <strong>
          {allPost.type === postTypes.lookingJob
            ? t("post.iAmLookingForAVacancy")
            : t("post.Work")}
        </strong>
        {element.map(({ title }) => t(`profession.${title}`)).join(slipe)}
      </li>
    );
  if (type === typeField.categoryProfession && element?.id)
    return (
      <li key={`portfolio-profession`} className="portfolio">
        <strong>{t(`categoryProfession.${element?.title}`)}</strong>
      </li>
    );
  if (type === typeField.postIndex && element?.id)
    return (
      <li key={element?.id} className="mailbox">
        <strong>{t("post.postCodeLondon")}</strong>{" "}
        {t(`postIndexs.${element?.title}`)}
      </li>
    );
  if (type === typeField.postIndexs && element?.length)
    return (
      <li key={element?.id} className="mailbox">
        <strong>{t("post.postCodeLondon")}</strong>{" "}
        {isSelectedAllPostCode(element.map(({ title }) => title))
          ? t("post.allLondon")
          : element.map(({ title }) => t(`postIndexs.${title}`)).join(slipe)}
      </li>
    );
  if (type === typeField.rentalPeriod && element?.id)
    return (
      <li key={element?.id} className="deadline">
        <strong>{t("post.rentalPeriod")}</strong>{" "}
        {t(`rentalPeriod.${element?.title}`)}
      </li>
    );
  if (type === typeField.category && element?.id)
    return (
      <li key={element?.id} className="category">
        <strong>{t("post.rentalPeriod")}</strong>{" "}
        {t(`postIndexs.${element?.title}`)}
      </li>
    );
  if (type === typeField.announcementCategory && element?.id)
    return (
      <li key={element?.id} className="category">
        <strong>{t("post.announcementCategory")}</strong>{" "}
        {t(`announcementCategory.${element?.title}`)}
      </li>
    );
  if (type === typeField.announcementRubric && element?.id)
    return (
      <li key={element?.id} className="category2">
        <strong>{t("post.announcementRubric")}</strong>{" "}
        {t(`announcementRubric.${element?.title}`)}
      </li>
    );
  if (type === typeField.postRentPropertyCategory && element?.id)
    return (
      <li key={element?.id} className="category">
        <strong>{t("post.category")}</strong>{" "}
        {t(`postRentPropertyCategory.${element?.title}`)}
      </li>
    );

  if (type === typeField.numberOfRooms && element.length)
    return (
      <li key="livingroom" className="livingroom">
        <strong>{t("post.numberOfRoom")}</strong> {element.join(slipe)}
      </li>
    );

  if (type === typeField.contractIn && element?.id)
    return (
      <li key="deadline" className="deadline">
        <strong>{t("post.contractIn")}</strong> {element}
      </li>
    );
  if (type === typeField.numberOfBathroom && element)
    return (
      <li key="numberOfBathroom" className="bath">
        <strong>{t("post.numberOfBathroom")}</strong> {element}
      </li>
    );

  if (
    (type === typeField.wifi ||
      type === typeField.garden ||
      type === typeField.carParking ||
      type === typeField.bicycleSpace) &&
    element
  ) {
    const x = getParms(element, [
      {
        key: "isCarParking",
        string: t("post.carParking")
      },
      {
        key: "isWifi",
        string: t("post.wifi")
      },
      {
        key: "isBicycleSpace",
        string: t("post.bicycleSpace")
      },
      {
        key: "isGarden",
        string: t("post.garden")
      }
    ]);
    if (!x.length) return null;
    return (
      <li key="home" className="home">
        <strong>{t("post.nearTheHouseThereAre")}</strong>
        {x}
      </li>
    );
  }
  if (type === typeField.ilookingForJob && element) {
    const x = getParms(element, [
      {
        key: "isYouCanWorkOnWeekends",
        string: t("post.youCanWorkOnWeekends")
      },
      {
        key: "isAssistantJob",
        string: t("post.assistantJob")
      },
      {
        key: "isWorkForMinors",
        string: t("post.workForMinors")
      },
      {
        key: "isLookingJobMinors",
        string: t("post.lookingJobMinors")
      },
      {
        key: "isWorkForAMarriedCouple",
        string: t("post.workForAMarriedCouple")
      }
    ]);
    if (!x.length) return null;
    return (
      <li key="home" className="home">
        <strong>
          {allPost.type === postTypes.offerVacancy
            ? t("post.workFor")
            : t("post.lookingJobType")}{" "}
        </strong>{" "}
        {x}
      </li>
    );
  }
  if (type === typeField.forWorkThereAre && element) {
    const x = getParms(element, [
      {
        key: "isUTR",
        string: t("post.UTR")
      },
      {
        key: "isNationalInsurance",
        string: t("post.nationalInsurance")
      },
      {
        key: "isTools",
        string: t("post.tools")
      },
      {
        key: "isCar",
        string: t("post.cars")
      },
      {
        key: "isDriverLicense",
        string: t("post.driverLicense")
      }
    ]);
    if (!x.length) return null;
    return (
      <li key="build" className="build">
        <strong>
          {allPost.type === postTypes.offerVacancy
            ? t("post.forWorkNeedTo")
            : t("post.forWorkThereAre")}
          {element.type}
        </strong>{" "}
        {x}
      </li>
    );
  }

  if (type === typeField.wifi && element)
    return (
      <li key="wifi" className="wifi">
        <strong>{t("post.wifi")}</strong>
      </li>
    );
  if (type === typeField.garden && element)
    return (
      <li key="parking" className="parking">
        <strong>{t("post.garden")}</strong>
      </li>
    );
  if (type === typeField.carParking && element)
    return (
      <li key="bicycle" className="bicycle">
        <strong>{t("post.carParking")}</strong>
      </li>
    );
  if (type === typeField.bicycleSpace && element)
    return (
      <li key="bicycle" className="bicycle">
        <strong>{t("post.bicycleSpace")}</strong>
      </li>
    );
  if (type === typeField.propertyForSale && element)
    return (
      <li key="bicycle" className="bicycle">
        <strong>{t("post.propertyForSale")}</strong>
      </li>
    );

  if (type === typeField.arrival && element.length)
    return (
      <li key="arrival" className="arrival">
        <strong>{t("post.arrival")}</strong> {element}
      </li>
    );
  if (type === typeField.departure && element.length)
    return (
      <li key="departure" className="departure">
        <strong>{t("post.departure")}</strong> {element}
      </li>
    );
  if (type === typeField.countryAlongRoute && element.length)
    return (
      <li key="route-country" className="route-country">
        <strong>{t("post.countryAlongRoute")}</strong>{" "}
        {element.map(({ title }) => t(`carrierCountry.${title}`)).join(slipe)}
      </li>
    );
  if (type === typeField.cityAlongRoute && element.length)
    return (
      <li key="route-city" className="route-city">
        <strong>{t("post.cityAlongRoute")}</strong> {element}
      </li>
    );
  if (type === typeField.dateDeparture && element)
    return (
      <li key="calendar" className="calendar">
        <strong>{t("post.dateDeparture")}</strong>{" "}
        {convertDateToUTCString(element)}
      </li>
    );

  if (type === typeField.isWorkForMinors && element)
    return (
      <li key={`portfolio-${type}`} className="portfolio">
        <strong>{t("post.workForMinors")}</strong>
      </li>
    );
  if (type === typeField.isAssistantJob && element)
    return (
      <li key={`portfolio-${type}`} className="portfolio">
        <strong>{t("post.assistantJob")}</strong>
      </li>
    );
  if (type === typeField.isAssistantJob && element)
    return (
      <li key={`portfolio-${type}`} className="portfolio">
        <strong>{t("post.lookingAssistant")}</strong>
      </li>
    );
  if (type === typeField.isLookingJobMinors && element)
    return (
      <li key={`portfolio-${type}`} className="portfolio">
        <strong>{t("post.lookingJobMinors")}</strong>
      </li>
    );
  if (type === typeField.isYouCanWorkOnWeekends && element)
    return (
      <li key={`portfolio-${type}`} className="portfolio">
        <strong>{t("createPost.youCanWorkOnWeekends")}</strong>
      </li>
    );

  if (type === typeField.utilitiesIncluded && element)
    return (
      <li key={element?.id} className="door">
        <strong>{t(`post.utilitiesIncluded`)}</strong>
      </li>
    );
  if (type === typeField.isWorkForAMarriedCouple && element)
    return (
      <li key={`portfolio-${type}`} className="portfolio">
        <strong>{t("createPost.workForAMarriedCouple")}</strong>
      </li>
    );

  return null;
};

ListInformationPosteElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool
  ]),
  type: PropTypes.string,
  allPost: PropTypes.object
};
export default ListInformationPosteElement;
