export const sexOptions = [
  {
    id: "MEN",
    title: "questionnaire.men",
    name: "isMen"
  },
  {
    id: "WOMEN",
    title: "questionnaire.women",
    name: "isWomen"
  }
];

export const documentsForWorkInEnglandOptions = [
  {
    id: "brp",
    value: "brp",
    title: "questionnaire.brp",
    name: "documentsForWork"
  },
  {
    id: "tr5",
    value: "tr5",
    title: "questionnaire.tr5",
    name: "documentsForWork"
  },
  {
    id: "preSettledStatus",
    value: "preSettledStatus",
    title: "questionnaire.preSettledStatus",
    name: "documentsForWork"
  },
  {
    id: "settledStatus",
    value: "settledStatus",
    title: "questionnaire.settledStatus",
    name: "documentsForWork"
  },
  {
    id: "visaWorkUK",
    value: "visaWorkUK",
    title: "questionnaire.visaWorkUK",
    name: "documentsForWork"
  },
  {
    id: "citizenshipUK",
    value: "citizenshipUK",
    title: "questionnaire.citizenshipUK",
    name: "documentsForWork"
  },
  {
    id: "other",
    value: "other",
    title: "questionnaire.other",
    name: "documentsForWork"
  }
];

export default {};
