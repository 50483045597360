import About from "../components/About";
import Layout from "./Layout/Layout";

const AboutView = () => (
  <Layout>
    <About />
  </Layout>
);

export default AboutView;
