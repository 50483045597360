import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "../../../../layout/Input/Input";
import Select from "../../../../layout/Select";

const QuestionnaireBasicAndAdditionalEducation = ({
  values,
  setValues,
  idx,
  valuesFormik,
  error
}) => {
  const { t } = useTranslation();
  const educationOptions = [
    {
      id: "noCmpleteSecondary",
      values: "noCmpleteSecondary",
      title: t("questionnaire.noCmpleteSecondary")
    },
    {
      id: "average",
      values: "average",
      title: t("questionnaire.average")
    },
    {
      id: "technicalCollege",
      values: "technicalCollege",
      title: t("questionnaire.technicalCollege")
    },
    {
      id: "college",
      values: "college",
      title: t("questionnaire.college")
    },
    {
      id: "institute",
      values: "institute",
      title: t("questionnaire.institute")
    },
    {
      id: "university",
      values: "university",
      title: t("questionnaire.university")
    }
  ];
  return (
    <>
      <div className="col-lg-6 mb-4">
        <Select
          label={t("questionnaire.education")}
          className="form-select"
          id={`inputEducationEducation-${idx}`}
          aria-label="Default select example"
          options={educationOptions}
          onChange={(val) => setValues({ ...values, education: val })}
          value={values.education}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <Input
          label={t("questionnaire.nameOfYourEducationalInstitution")}
          name={`nameEducationalInstitution[${idx}]`}
          type="text"
          value={
            valuesFormik?.nameEducationalInstitution
              ? valuesFormik?.nameEducationalInstitution[idx]
              : ""
          }
          id={`inputUniversity-${idx}`}
          disabled={!values.education}
          error={error}
        />
      </div>
    </>
  );
};

QuestionnaireBasicAndAdditionalEducation.propTypes = {
  values: PropTypes.shape({
    additionalEducation: PropTypes.string,
    education: PropTypes.string
  }),
  setValues: PropTypes.func,
  idx: PropTypes.number,
  error: PropTypes.string,
  valuesFormik: PropTypes.shape({
    nameEducationalInstitution: PropTypes.arrayOf(PropTypes.string)
  })
};
export default QuestionnaireBasicAndAdditionalEducation;
