import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getPostUrl } from "../../../../routes";
import { classNames, getIsPremium, priorityToArray } from "../../../../utility";
import Link from "../../../layout/Link";

const SinglePostContacts = ({ posts, classnames, title }) => {
  const { t } = useTranslation();
  if (!posts || posts.length === 0) {
    return null;
  }
  return (
    <div className={classNames("page-single-wrap bg-none", classnames)}>
      <label className="page-single-label">{title}</label>
      <div className="page-single-works-items ">
        {posts.map((post) => (
          <div className="page-single-works-item" key={post.id}>
            <Link
              to={getPostUrl(post.id, post?.seo)}
              className="page-single-works-item-inner"
            >
              <div className="page-single-works-item-class">
                <span>{t(post.type)}</span>
              </div>
              <div className="page-single-works-item-title">{post.title}</div>
              <div className="page-single-works-item-city">
                {post?.city?.title}
              </div>
              <div className="page-single-works-item-date">
                {getIsPremium(post.expireInPriorityDate, post.priority) ? (
                  <p className="works-stars">
                    {priorityToArray(post?.priority).map(() => (
                      <span />
                    ))}
                  </p>
                ) : (
                  moment(post.expireInPriorityDate).format("DD.MM.YYYY")
                )}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

SinglePostContacts.propTypes = {
  posts: PropTypes.array,
  classnames: PropTypes.string,
  title: PropTypes.string
};
export default SinglePostContacts;
