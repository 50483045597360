import { gql } from "@apollo/client";

const deletePost = gql`
  mutation deletePost($postId: ID!) {
    deletePost(postId: $postId) {
      success
      code
    }
  }
`;

export default deletePost;
