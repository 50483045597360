import { gql } from "@apollo/client";

const restorePassword = gql`
  mutation restorePassword($code: String!, $input: RestorePasswordInput!) {
    restorePassword(code: $code, input: $input) {
      success
      code
    }
  }
`;

export default restorePassword;
