import Contacts from "../components/Contacts";
import Layout from "./Layout/Layout";

const ContactsView = () => (
  <Layout>
    <Contacts />
  </Layout>
);

export default ContactsView;
