import LookingJob from "../components/Posts/LookingJob";
import Layout from "./Layout/Layout";

const LookingJobView = () => (
  <Layout>
    <LookingJob />
  </Layout>
);

export default LookingJobView;
