import PropTypes from "prop-types";

import { classNames } from "../../../../utility";

const SecondaryButton = ({ children, onClick, className }) => (
  <span
    onClick={onClick}
    className={classNames("btn btn-secondary", className)}
  >
    {children}
  </span>
);

SecondaryButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default SecondaryButton;
