import { gql } from "@apollo/client";

const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
      code
    }
  }
`;

export default forgotPassword;
