/* eslint-disable i18next/no-literal-string */
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { homeUrl, myOfficeUrl } from "../../../../routes";
import Input from "../../../layout/Input/Input";
import LinkView from "../../../layout/Link";

const SideBar = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const validate = (values) => {
    const url = queryString.stringifyUrl({
      url: myOfficeUrl,
      query: {
        ...values
      }
    });

    setFilters(values);
    history.push(url);
    return {};
  };
  return (
    <Formik initialValues={filters} validate={validate} onSubmit={() => {}}>
      {() => (
        <div className="sidebar-block">
          <div className="widget widget-type-advert show">
            <div className="widget-title">
              <span>{t("myOffice.yourAds")}</span>
            </div>
            <Form>
              <div className="widget-content">
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    value={"true"}
                    name="free"
                    id="free"
                    label={t("myOffice.free")}
                  />
                </div>
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    value={"true"}
                    name="paid"
                    id="paid"
                    label={t("myOffice.paid")}
                  />
                </div>
              </div>
              <div className="widget-content">
                <p>
                  <LinkView
                    to={homeUrl}
                    className="btn btn-outline-primary w-100"
                  >
                    {t("myOffice.myPoll")}
                  </LinkView>
                </p>
                <p>
                  <LinkView
                    to={homeUrl}
                    className="btn btn-outline-primary w-100"
                  >
                    {t("myOffice.changePassword")}
                  </LinkView>
                </p>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

SideBar.propTypes = {
  filter: PropTypes.object,
  filters: PropTypes.func,
  setPosts: PropTypes.func,
  setFilters: PropTypes.func
};

export default SideBar;
