/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { homeUrl, registrationUrl } from "../../routes";
import LinkView from "../layout/Link";
import Page from "../layout/Page";

const RegistrationStub = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "registration",
      title: t("registration.registration"),
      link: registrationUrl
    }
  ];

  return (
    <Page
      pageName={t("registration.registration")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="works-inner works-inner-full no-border">
        <div className="block-no-content block-no-content-lg account-confirm">
          <div className="block-no-content__image text-center">
            <img
              src="/assets/img/emoji-no-content.svg"
              width="130"
              height="130"
              alt="У вас пока нет анкеты"
            />
          </div>
          <h2 className="block-no-content__title text-center">
            {t("stub.registration.functionality")}
            <br />
            {t("stub.registration.excusesForInconvenience")}
          </h2>
          <div className="block-no-content__button text-center">
            <LinkView to={homeUrl} className="btn btn-primary">
              {t("toHome")}
            </LinkView>
          </div>
        </div>
      </div>
    </Page>
  );
};

RegistrationStub.propTypes = {
  filter: PropTypes.object
};
export default RegistrationStub;
