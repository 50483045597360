import About from "./About";
import AddBlackList from "./AddBlackList";
import Announcements from "./Announcements";
import AuthorizationEmail from "./AuthorizationEmail";
import BlackList from "./BlackList";
import Carrier from "./Carrier";
import CatalogOfCompanies from "./CatalogOfCompanies";
import ComplaintSentBlackList from "./ComplaintSentBlackList";
import Contacts from "./Contacts";
import CreatePost from "./CreatePost";
import FAQ from "./FAQ";
import ForgotPassword from "./ForgotPassword";
import Home from "./Home";
import Login from "./Login";
import LookingJob from "./LookingJob";
import NoPage from "./NoPage";
import OfferVacancy from "./OfferVacancy";
import PermitWork from "./PermitWork";
import Post from "./Post";
import PublicationRules from "./PublicationRules";
import QuestionnaireCreate from "./QuestionnaireCreate";
import QuestionnaireSingle from "./QuestionnaireSingle";
import QuestionnairesList from "./QuestionnairesList";
import Registration from "./Registration";
import RentProperty from "./RentProperty";
import RestorePassword from "./RestorePassword";
import SiteArticle from "./SiteArticle";
import SiteArticles from "./SiteArticles";
import TermsOfUse from "./TermsOfUse";
import UserOffice from "./UserOffice";

export default {
  Home,
  NoPage,
  About,
  CreatePost,
  Post,
  OfferVacancy,
  LookingJob,
  RentProperty,
  Carrier,
  Announcements,
  Contacts,
  PermitWork,
  BlackList,
  AddBlackList,
  ComplaintSentBlackList,
  QuestionnaireCreate,
  QuestionnaireSingle,
  QuestionnairesList,
  CatalogOfCompanies,
  Login,
  FAQ,
  PublicationRules,
  TermsOfUse,
  SiteArticles,
  SiteArticle,
  Registration,
  ForgotPassword,
  RestorePassword,
  AuthorizationEmail,
  UserOffice
};
