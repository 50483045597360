import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { postTypes } from "../../../graphql/enum";
import {
  carrierUrl,
  homeUrl,
  iOfferAVacancyUrl,
  lookingForAJobUrl,
  privateAnnouncementUrl,
  rentUrl
} from "../../../routes";
import { classNames } from "../../../utility";
import Link from "../../layout/Link";

const TabsPost = ({ typeActive, classname }) => {
  const { t } = useTranslation();
  return (
    <ul className={classNames("type-menu", classname)}>
      <li>
        <Link
          to={homeUrl}
          className={classNames("btn btn-outline-primary w-100")}
        >
          {t("filsersPost.allPosts")}
        </Link>
      </li>
      <li>
        <Link
          to={iOfferAVacancyUrl}
          className={classNames(
            "btn btn-outline-primary w-100",
            typeActive === postTypes.offerVacancy ? "active" : ""
          )}
        >
          {t("filsersPost.iOfferAVacancy")}
        </Link>
      </li>
      <li>
        <Link
          to={lookingForAJobUrl}
          className={classNames(
            "btn btn-outline-primary w-100",
            typeActive === postTypes.lookingJob ? "active" : ""
          )}
        >
          {t("filsersPost.lookingForAJob")}
        </Link>
      </li>
      <li>
        <Link
          to={privateAnnouncementUrl}
          className={classNames(
            "btn btn-outline-primary w-100",
            typeActive === postTypes.announcements ? "active" : ""
          )}
        >
          {t("filsersPost.announcements")}
        </Link>
      </li>
      <li>
        <Link
          to={carrierUrl}
          className={classNames(
            "btn btn-outline-primary w-100",
            typeActive === postTypes.carrier ? "active" : ""
          )}
        >
          {t("filsersPost.carriers")}
        </Link>
      </li>

      <li>
        <Link
          to={rentUrl}
          className={classNames(
            "btn btn-outline-primary w-100",
            typeActive === postTypes.rentProperty ? "active" : ""
          )}
        >
          {t("filsersPost.rentalHousing")}
        </Link>
      </li>
    </ul>
  );
};
TabsPost.propTypes = {
  typeActive: PropTypes.string,
  classname: PropTypes.string
};
export default TabsPost;
