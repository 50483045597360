import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "../../../layout/Loading/Loading";
import UploadImageAndPreview from "../../../layout/UploadImageAndPreview/UploadImageAndPreview";

const createOrUpdateCategoryWithTelegramChannel = gql`
  mutation createOrUpdateCategoryWithTelegramChannel(
    $isCarrier: Boolean
    $postRentPropertyCategoryId: ID
    $postAnnouncementRubricId: ID
    $professionId: ID
    $input: CreateOrUpdateCategoryWithTelegramChannelInput
  ) {
    createOrUpdateCategoryWithTelegramChannel(
      postRentPropertyCategoryId: $postRentPropertyCategoryId
      isCarrier: $isCarrier
      postAnnouncementRubricId: $postAnnouncementRubricId
      professionId: $professionId
      input: $input
    ) {
      success
      code
    }
  }
`;

const Item = ({
  isCarrier,
  postRentPropertyCategoryId,
  postAnnouncementRubricId,
  professionId,
  title,
  channelTelegramData
}) => {
  const { t } = useTranslation();
  const [linkInput, setLinkValue] = useState(channelTelegramData?.link);
  const [valueInput, setInputValue] = useState(channelTelegramData?.name);
  const [fileId, setFileId] = useState(channelTelegramData?.qrCodeLinkFileId);
  const [isLoading, setIsLoading] = useState(false?.qrCodeLinkFileId);
  const [createOrUpdateCategoryWithTelegramChannelMutation] = useMutation(
    createOrUpdateCategoryWithTelegramChannel
  );
  const updateData = async () => {
    await createOrUpdateCategoryWithTelegramChannelMutation({
      variables: {
        isCarrier,
        postRentPropertyCategoryId,
        postAnnouncementRubricId,
        professionId,
        input: {
          qrCodeImageFileId: fileId,
          name: valueInput,
          link: linkInput
        }
      }
    });
    setIsLoading(false);
  };

  return (
    <div className="col-lg-4 mb-4 p-2 border border-primary">
      <h3> {title}</h3>
      <label for="inputTitle" className="form-label">
        {t("*Назва каналу")}
      </label>
      <input
        name="title"
        className="form-control"
        type="text"
        id="inputTitle"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onBlur={() => {
          setIsLoading(true);
          updateData();
        }}
        value={valueInput}
      />
      <label for="inputLink" className="form-label">
        {t("Силка")}
      </label>
      <input
        name="link"
        className="form-control"
        type="text"
        id="inputlink"
        onChange={(e) => {
          setLinkValue(e.target.value);
        }}
        onBlur={() => {
          setIsLoading(true);
          updateData();
        }}
        value={linkInput}
      />
      <UploadImageAndPreview
        title="Завантажити QR - code"
        imagesDefault={
          channelTelegramData?.qrCodeImage
            ? [channelTelegramData?.qrCodeImage]
            : []
        }
        maxPhoto={2}
        setFile={async (file) => {
          setIsLoading(true);
          await setFileId(file);
          updateData();
        }}
      />
      {isLoading ? <Loading /> : null}
    </div>
  );
};

Item.propTypes = {
  isCarrier: PropTypes.bool,
  postRentPropertyCategoryId: PropTypes.number,
  postAnnouncementRubricId: PropTypes.number,
  professionId: PropTypes.number,
  title: PropTypes.string,
  channelTelegramData: PropTypes.object
};
export default Item;
