import PropTypes from "prop-types";
import { useState } from "react";

import { classNames } from "../../../../utility";

const DropDownBlockItem = ({ title, description }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className={classNames("faq-item", isActive ? "active" : "")}>
      <div className="faq-item-head" onClick={() => setIsActive(!isActive)}>
        {title}
      </div>
      <div className="faq-item-content">
        <p>{description}</p>
      </div>
    </div>
  );
};

DropDownBlockItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
export default DropDownBlockItem;
