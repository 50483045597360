import { gql } from "@apollo/client";

const createQuestionnaire = gql`
  mutation createQuestionnaire($input: QuestionnaireInput) {
    createQuestionnaire(input: $input) {
      success
      code
      id
    }
  }
`;

export default createQuestionnaire;
