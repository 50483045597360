import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import forgotPassword from "../../graphql/mutation/forgotPassword";
import { forgotPasswordUrl, homeUrl } from "../../routes";
import Input from "../layout/Input/Input";
import Page from "../layout/Page";
import SuccessSendForgotPassword from "./SuccessSendForgotPassword";

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [sendToEmailRequest, setSendToEmailRequest] = useState();

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "forgotPassword",
      title: t("forgotPassword.forgotYourPassword"),
      link: forgotPasswordUrl
    }
  ];
  const [forgotPasswordMutation] = useMutation(forgotPassword);

  const validate = (valuesValidate) => {
    const errors = {};
    if (!valuesValidate.email) {
      errors.email = `validationErrors.required`;
    }

    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    const { data } = await forgotPasswordMutation({
      variables: {
        email: valuesFormik.email
      }
    });
    if (data?.forgotPassword.success) {
      setSendToEmailRequest(valuesFormik.email);
    }
    setErrors({ email: data?.forgotPassword.code });
  };
  return (
    <Page
      pageName={t("forgotPassword.forgotYourPassword")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      {sendToEmailRequest ? (
        <SuccessSendForgotPassword email={sendToEmailRequest} />
      ) : (
        <div className="account-form">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-md-6 col-xl-4">
              <div className="account-form-logo">
                <img src="/assets/img/logo-dark.svg" alt="" />
              </div>
              <h1 className="account-form-heading">
                {t("forgotPassword.forgotYourPassword")}
              </h1>
              <p className="text-center">
                {t("forgotPassword.enterEmailAddress")}
              </p>
              <Formik
                initialValues={{
                  email: ""
                }}
                onSubmit={onSave}
                validate={validate}
              >
                {({ errors }) => (
                  <Form>
                    <div className="mb-3">
                      <Input
                        type="text"
                        label={t("forgotPassword.email")}
                        id="email"
                        name="email"
                        maxLength="50"
                        required="1"
                        error={errors?.email}
                      />
                    </div>
                    <div className="mb-0 text-center">
                      <button type="submit" className="btn btn-primary">
                        {t("forgotPassword.continue")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

ForgotPassword.propTypes = {
  filter: PropTypes.object
};
export default ForgotPassword;
