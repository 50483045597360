import SiteArticles from "../components/SiteArticles/SiteArticlesList";
import Layout from "./Layout/Layout";

const SiteArticlesView = () => (
  <Layout>
    <SiteArticles />
  </Layout>
);

export default SiteArticlesView;
