/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { postTypes } from "../../../graphql/enum";
import Page from "../../layout/Page";
import TabsPost from "../TabsPost/TabsPost";
import Sidebar from "./RentPropertySideBar";
import RentPropertyTable from "./RentPropertyTable/RentPropertyTable";

const RentProperty = () => {
  const { t } = useTranslation();

  const parameters = new URLSearchParams(window.location.search);
  const cityId = parameters.getAll("cityId")[0];
  const postIndexs = parameters.getAll("postIndexs");
  const postRentPropertyCategoryIds = parameters.getAll(
    "postRentPropertyCategoryIds"
  );
  const typeRooms = parameters.getAll("typeRooms");
  const rentalPeriods = parameters.getAll("rentalPeriods");
  const numberOfRoom = parameters.getAll("numberOfRoom");
  const numberOfBathroom = parameters.getAll("numberOfBathroom");
  const garden = parameters.getAll("garden");
  const carParking = parameters.getAll("carParking");
  const isBicycleSpace = parameters.getAll("isBicycleSpace");
  const isPropertyForSale = parameters.getAll("isPropertyForSale");
  const isWifi = parameters.getAll("isWifi");
  const utilitiesIncluded = parameters.getAll("utilitiesIncluded");
  const price = parameters.getAll("price");

  const announcementFromAgencies = parameters.getAll(
    "announcementFromAgencies"
  );
  const privateAds = parameters.getAll("privateAds");
  const maxPrice = 999;
  const search = parameters.getAll("search")[0];
  const currentPage = parameters.getAll("currentPage")[0];

  const defaultFilter = {
    cityId,
    postIndexs,
    postRentPropertyCategoryIds,
    typeRooms,
    rentalPeriods,
    numberOfRoom,
    numberOfBathroom,
    garden,
    carParking,
    isBicycleSpace,
    isPropertyForSale,
    isWifi,
    utilitiesIncluded,
    announcementFromAgencies,
    privateAds,
    price: price.length ? price : [0, maxPrice],
    search,
    currentPage: parseInt(currentPage, 10) || 1
  };

  const [filters, setFilters] = useState(defaultFilter);
  const [totalPosts, setTotalPosts] = useState(0);

  return (
    <Page
      sidebar={{
        mobileTextButton: t("filter"),
        render: () => (
          <Sidebar
            filters={filters}
            setFilters={setFilters}
            totalPosts={totalPosts}
            maxPrice={maxPrice}
            typeActive={postTypes.rentProperty}
          />
        ),
        after: () => (
          <p className="works-count d-lg-none">{`${t(
            "post.totalPosts"
          )} ${totalPosts}`}</p>
        )
      }}
      beforeContent={
        <TabsPost
          typeActive={postTypes.rentProperty}
          classname="type-menu-horizontal"
        />
      }
    >
      <RentPropertyTable
        {...{
          filters,
          setTotalPosts,
          currentPage: filters.currentPage,
          setCurrentPage: (newPage) => {
            setFilters({ ...filters, currentPage: newPage });
          }
        }}
      />
    </Page>
  );
};

RentProperty.propTypes = {
  filter: PropTypes.object
};
export default RentProperty;
