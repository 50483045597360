import AddBlackList from "../components/BlackList/AddBlackList";
import Layout from "./Layout/Layout";

const AddBlackListView = () => (
  <Layout>
    <AddBlackList />
  </Layout>
);

export default AddBlackListView;
