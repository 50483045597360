import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const PreviewButtonBlock = ({ submitForm, isSubmitting, errors }) => {
  const { t } = useTranslation();
  const [isPublished, setIsPublished] = useState(false);

  const handleClick = async () => {
    if (!isSubmitting && !isPublished && !Object.keys(errors).length) {
      window.scrollTo(0, 0);
      await submitForm();
      setIsPublished(true);
    }
  };

  return (
    <div className="section-add">
      <div className="row">
        <div className="col-lg-12 mb-4 text-lg-end">
          <button
            type="button"
            onClick={handleClick}
            className="btn btn-primary"
            disabled={isSubmitting || isPublished}
          >
            {t("questionnaire.publish")}
          </button>
        </div>
      </div>
    </div>
  );
};

PreviewButtonBlock.propTypes = {
  values: PropTypes.shape({
    categoryProfession: PropTypes.string,
    professionConstruction: PropTypes.string,
    profession: PropTypes.string
  }),
  setValues: PropTypes.func,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object
};

export default PreviewButtonBlock;
