import QuestionnairesList from "../components/Questionnaire/QuestionnairesList";
import Layout from "./Layout/Layout";

const QuestionnairesListView = () => (
  <Layout>
    <QuestionnairesList />
  </Layout>
);

export default QuestionnairesListView;
