import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Link from "../../components/layout/Link";
import {
  aboutUrl,
  blackListUrl,
  contactsUrl,
  faqUrl,
  publicationRulesUrl
} from "../../routes";
import { numberWithSpaces } from "../../utility";
import { socialNetworks, telegramGropUrls } from "../../utility/constants";
import CompaniesAndServices from "./Modals/CompaniesAndServices";
import NoRegistration from "./Modals/NoRegistration";

const Footer = ({ otherData }) => {
  const { t } = useTranslation();
  const [isShowCompaniesAndServices, setIsShowCompaniesAndServices] =
    useState(false);
  const [isShowNoRegistration, setIsShowNoRegistration] = useState(false);
  return (
    <>
      <NoRegistration
        isShow={isShowNoRegistration}
        onClose={() => setIsShowNoRegistration(false)}
      />
      <CompaniesAndServices
        isShow={isShowCompaniesAndServices}
        onClose={() => setIsShowCompaniesAndServices(false)}
      />
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-widget footer-widget-1">
                <p>{t("foter.employmentInEngland")}</p>
                <p className="copyright">
                  {t("foter.trs", { date: new Date().getFullYear() })}
                </p>
                <p className="site-view">
                  {t("footer.siteReviwe")}{" "}
                  <span>{numberWithSpaces(otherData.analytic.totalViews)}</span>{" "}
                  {t("footer.users")}
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget footer-widget-2">
                <ul className="footer-menu">
                  <li>
                    <Link to={aboutUrl}>{t("foter.about")}</Link>
                  </li>
                  <li>
                    <Link to={contactsUrl}>{t("foter.contacts")}</Link>
                  </li>
                  <li>
                    {/* <Link to={`${myOfficeUrl}?page=settings`}> */}
                    <Link
                      to={`#`}
                      onClick={() => setIsShowNoRegistration(true)}
                    >
                      {t("foter.subscriptionToSiteNews")}
                    </Link>
                  </li>
                  <li>
                    <Link to={blackListUrl}>{t("foter.blackList")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-widget footer-widget-3">
                <div>
                  <p className="mb-2">{t("foter.title")}</p>
                  <p className="mb-1">
                    <Link to={faqUrl}>{t("foter.descriptiom")}</Link>
                  </p>
                  <p>
                    <Link to={publicationRulesUrl}>
                      {t("foter.descriptiom3")}
                    </Link>
                  </p>
                </div>
                <ul className="soc-links footer-widget-soc">
                  <li className="soc-links-facebook">
                    <a
                      href={socialNetworks.facebook}
                      target="_blank"
                      aria-label={t("modal.socialMedia")}
                      rel="noreferrer"
                    ></a>
                  </li>
                  <li className="soc-links-viber">
                    <a
                      href={socialNetworks.viber}
                      target="_blank"
                      aria-label={t("modal.socialMedia")}
                      rel="noreferrer"
                    ></a>
                  </li>

                  <li className="soc-links-telegram">
                    <a
                      href={telegramGropUrls.announcements}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Telegram announcements"
                    />
                  </li>
                  <li className="soc-links-btn">
                    {/* <Link to={`${myOfficeUrl}?page=settings`}> */}

                    <Link
                      to="#"
                      onClick={() => setIsShowNoRegistration(true)}
                      className="btn btn-primary"
                    >
                      {t("foter.subscribe")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
Footer.propTypes = {
  otherData: PropTypes.object
};

export default Footer;
