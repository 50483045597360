/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { postTypes } from "../../../graphql/enum";
import Page from "../../layout/Page";
import TabsPost from "../TabsPost/TabsPost";
import Sidebar from "./AnnouncementsSideBar";
import LookingJobTable from "./AnnouncementsTable/AnnouncementsTable";

const LookingJob = () => {
  const { t } = useTranslation();

  const parameters = new URLSearchParams(window.location.search);
  const cityId = parameters.getAll("cityId")[0];
  const postAnnouncementCategoryIds = parameters.getAll(
    "postAnnouncementCategoryIds"
  );
  const postAnnouncementRubricIds = parameters.getAll(
    "postAnnouncementRubricIds"
  );
  const postIndexs = parameters.getAll("postIndexs");
  const announcementFromAgencies = parameters.getAll(
    "announcementFromAgencies"
  );
  const privateAds = parameters.getAll("privateAds");
  const currentPage = parameters.getAll("currentPage")[0];
  const search = parameters.getAll("search")[0];
  const defaultFilter = {
    cityId,
    postIndexs,
    privateAds,
    announcementFromAgencies,
    postAnnouncementCategoryIds,
    postAnnouncementRubricIds,
    search,
    currentPage: parseInt(currentPage, 10) || 1
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [totalPosts, setTotalPosts] = useState(0);

  return (
    <Page
      sidebar={{
        mobileTextButton: t("filter"),
        render: () => (
          <Sidebar
            filters={filters}
            setFilters={setFilters}
            typeActive={postTypes.announcements}
            totalPosts={totalPosts}
          />
        ),
        after: () => (
          <p className="works-count d-lg-none">{`${t(
            "post.totalPosts"
          )} ${totalPosts}`}</p>
        )
      }}
      beforeContent={
        <TabsPost
          classname="type-menu-horizontal"
          typeActive={postTypes.announcements}
        />
      }
    >
      <LookingJobTable
        {...{
          filters,
          setTotalPosts,
          currentPage: filters.currentPage,
          setCurrentPage: (newPage) => {
            setFilters({ ...filters, currentPage: newPage });
          }
        }}
      />
    </Page>
  );
};

LookingJob.propTypes = {
  filter: PropTypes.object
};
export default LookingJob;
