import AuthorizationEmail from "../components/AuthorizationEmail";
import Layout from "./Layout/Layout";

const AuthorizationEmailView = () => (
  <Layout>
    <AuthorizationEmail />
  </Layout>
);

export default AuthorizationEmailView;
