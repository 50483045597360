import "react-datepicker/dist/react-datepicker.css";

import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import { classNames, getClassNameForInput } from "../../../utility";

const data = (t) => {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ].map((day) => t(`days.${day}`));

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
  ].map((month) => t(`months.${month}`));

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n]
    },
    formatLong: {
      date: () => "mm/dd/yyyy"
    },
    match: {
      day: (n) => days[n],
      month: (n) => months[n]
    }
  };

  return locale;
};

const DateDeparture = ({
  label,
  error,
  value,
  isDisplayValid,
  id,
  required,
  onChange,
  minDate,
  maxDate,
  ...props
}) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const maxEndDate = currentDate.setDate(currentDate.getDate() + 13);

  return (
    <>
      {label ? (
        <label htmlFor={id} className="form-label">
          {required ? "*" : ""}
          {label}
        </label>
      ) : null}
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={onChange}
        className={classNames(
          "form-control",
          getClassNameForInput({ error, value, isDisplayValid })
        )}
        wrapperClassName="my-custom-datepicker"
        dateFormat="yyyy-MM-dd"
        placeholderText="yyyy-mm-dd"
        required={false}
        minDate={minDate ?? new Date()}
        maxDate={maxDate ?? maxEndDate}
        locale={data(t)}
        {...props}
      />
    </>
  );
};

DateDeparture.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  isDisplayValid: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  errorclassname: PropTypes.object
};

DateDeparture.defaultProps = {
  errorclassname: {}
};

export default DateDeparture;
