/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import { useMutation } from "@apollo/client";
import GooglePayButton from "@google-pay/button-react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import createPayPost from "../../../../graphql/mutation/createPayPost";
import { contactsUrl } from "../../../../routes";
import { getSelectParameter, numberWithSpaces } from "../../../../utility";
import { UserContext } from "../../../../utility/context/User";
import Select from "../../../layout/Select";

const PayPost = ({ showPostByCodeFun, code }) => {
  const { t } = useTranslation();
  const { otherData } = useContext(UserContext);

  const stars = [
    {
      id: "1",
      title: "1",
      value: 0.25
    },
    {
      id: "2",
      title: "2",
      value: 0.5
    },
    {
      id: "3",
      title: "3",
      value: 0.75
    },
    {
      id: "4",
      title: "4",
      value: 1
    },
    {
      id: "5",
      title: "5",
      value: 1.25
    }
  ];
  const days = [
    {
      id: "2",
      title: "2",
      value: 2
    },
    {
      id: "3",
      title: "3",
      value: 3
    },
    {
      id: "4",
      title: "4",
      value: 4
    },
    {
      id: "5",
      title: "5",
      value: 5
    },
    {
      id: "6",
      title: "6",
      value: 6
    },
    {
      id: "7",
      title: "7",
      value: 7
    },
    {
      id: "8",
      title: "8",
      value: 8
    },
    {
      id: "9",
      title: "9",
      value: 9
    },
    {
      id: "10",
      title: "10",
      value: 10
    },
    {
      id: "11",
      title: "11",
      value: 11
    },
    {
      id: "12",
      title: "12",
      value: 12
    },
    {
      id: "13",
      title: "13",
      value: 13
    },
    {
      id: "14",
      title: "14",
      value: 14
    },
    {
      id: "15",
      title: "15",
      value: 15
    },
    {
      id: "16",
      title: "16",
      value: 16
    },
    {
      id: "17",
      title: "17",
      value: 17
    },
    {
      id: "18",
      title: "18",
      value: 18
    },
    {
      id: "19",
      title: "19",
      value: 19
    },
    {
      id: "20",
      title: "20",
      value: 20
    },
    {
      id: "21",
      title: "21",
      value: 21
    },
    {
      id: "22",
      title: "22",
      value: 22
    },
    {
      id: "23",
      title: "23",
      value: 23
    },
    {
      id: "24",
      title: "24",
      value: 24
    },
    {
      id: "25",
      title: "25",
      value: 25
    },
    {
      id: "26",
      title: "26",
      value: 26
    },
    {
      id: "27",
      title: "27",
      value: 27
    },
    {
      id: "28",
      title: "28",
      value: 28
    },
    {
      id: "29",
      title: "29",
      value: 29
    },
    {
      id: "30",
      title: "30",
      value: 30
    }
  ];
  const [day, setDay] = useState();
  const [star, setStar] = useState();
  const [price, setPrice] = useState(0);
  useEffect(() => {
    const pr =
      getSelectParameter({ value: day, array: days }).value *
      getSelectParameter({ value: star, array: stars }).value;
    setPrice(pr || 0);
  }, [day, star]);
  const [createPayPostMutation] = useMutation(createPayPost);

  const onSave = () => {};
  return (
    <Formik
      initialValues={{
        inputStar: false
      }}
      onSubmit={onSave}
    >
      {() => (
        <Form>
          <div className="page-preview-wrap page-preview-wrap-btn">
            <label className="preview-label">
              {t("payBlock.highlightTheAdOnTheMainPage")}
            </label>
            <div className="preview-select">
              <div className="row">
                <div className="col-6 col-lg-4">
                  <Select
                    id="inputDay"
                    options={days}
                    value={day}
                    label={t("payBlock.numberOfDays")}
                    onChange={(val) => setDay(val)}
                  />
                </div>
                <div className="col-6 col-lg-4">
                  <Select
                    id="inputStar"
                    name="inputStar"
                    options={stars}
                    value={star}
                    label={t("payBlock.countStars")}
                    onChange={(val) => setStar(val)}
                  />
                </div>
              </div>
            </div>
            <p className="preview-all-view">
              {t("payBlock.yourPostMoreViews")}
              <span>
                {numberWithSpaces(otherData.analytic.totalViews)}
              </span>{" "}
              {t("payBlock.peoples")}
            </p>
            <p className="preview-pay-summ preview-pay-summ-1">
              {t("postManageButton.toPay")} {price} {t("£")}{" "}
            </p>

            <div className="preview-pay-text">
              <ul>
                <li key="text_1">{t("payBlock.text_1")}</li>
                <li key="text_2">{t("payBlock.text_2")}</li>
                <li key="text_3">{t("payBlock.text_3")}</li>
                <li key="text_4">{t("payBlock.text_4")}</li>
                <li key="text_5">{t("payBlock.text_5")}</li>
                <li>
                  {t("payBlock.text_6", {
                    countView: numberWithSpaces(otherData.analytic.totalViews)
                  })}
                </li>
              </ul>
              <p>
                {t("payBlock.text_7")}
                <a href="mailto:emigrant.center.mail@gmail.com">
                  {t("payBlock.text_9")}
                </a>
                {t("payBlock.text_10")}
                <a href={contactsUrl} target="_blank" rel="noreferrer">
                  {t("payBlock.text_8")}
                </a>
              </p>
            </div>
            <div
              className="preview-pay-info d-content"
              style={{
                display: "contents"
              }}
            >
              <p className="preview-pay-summ">
                {t("postManageButton.toPay")} {price} {t("£")}
              </p>
              <div className="preview-pay-btn">
                <GooglePayButton
                  environment="TEST"
                  paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                      {
                        type: "CARD",
                        parameters: {
                          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                          allowedCardNetworks: ["MASTERCARD", "VISA"]
                        },
                        tokenizationSpecification: {
                          type: "PAYMENT_GATEWAY",
                          parameters: {
                            gateway: "example",
                            gatewayMerchantId: "exampleGatewayMerchantId"
                          }
                        }
                      }
                    ],
                    merchantInfo: {
                      merchantId: "BCR2DN4TZSVMB5ZL",
                      merchantName: "WebTop"
                    },
                    transactionInfo: {
                      totalPriceStatus: "FINAL",
                      totalPriceLabel: "Total",
                      totalPrice: "1.00",
                      currencyCode: "USD",
                      countryCode: "US"
                    }
                  }}
                  onLoadPaymentData={(paymentRequest) => {
                    if (paymentRequest?.paymentMethodData) {
                      createPayPostMutation({
                        variables: {
                          code,
                          input: {
                            stars: getSelectParameter({
                              value: star,
                              array: stars
                            }).value,
                            days: getSelectParameter({
                              value: day,
                              array: days
                            }).value,
                            price
                          }
                        }
                      });
                      showPostByCodeFun();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

PayPost.propTypes = {
  filter: PropTypes.object,
  showPostByCodeFun: PropTypes.func,
  deletePostByCode: PropTypes.func,
  editPostByCode: PropTypes.func,
  code: PropTypes.string
};
export default PayPost;
