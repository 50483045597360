/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { t } from "i18next";
import PropTypes from "prop-types";

import Link from "../../../layout/Link";
import Loading from "../../../layout/Loading/Loading";

export const blackListsQuery = gql`
  query blackLists($search: String!, $isShow: Boolean) {
    blackLists {
      data(search: $search, isShow: $isShow) {
        phoneCompany
        emailCompany
        webSiteCompany
        nameCompany
        name
        email
        phone
        isViber
        isTelegram
        isWhatsapp
        isAdmin
        description
        createdAt
      }
    }
  }
`;
const BlackListTable = ({ search }) => {
  const { loading, data } = useQuery(blackListsQuery, {
    variables: {
      search,
      isShow: true
    }
  });
  if (loading) return <Loading />;

  const blackLists = data.blackLists.data;
  return (
    <div className="blackList-inner p-0">
      <div className="blackList-items">
        <div className="blackList-item blackList-item-head">
          <div className="blackList-item-inner">
            <p className="blackList-item-phone">{t("blackList.phone")}</p>
            <p className="blackList-item-site">{t("blackList.webSite")}</p>
            <p className="blackList-item-email">{t("blackList.email")}</p>
            <p className="blackList-item-name">{t("blackList.name")}</p>
          </div>
        </div>
        {blackLists.map((blackList) => (
          <div className="blackList-item">
            <Link to="#" className="blackList-item-inner">
              <p className="blackList-item-phone">{blackList.phoneCompany}</p>
              <p className="blackList-item-site">{blackList.webSiteCompany}</p>
              <p className="blackList-item-email">{blackList.emailCompany}</p>
              <p className="blackList-item-name">{blackList.nameCompany}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

BlackListTable.propTypes = {
  search: PropTypes.string
};
export default BlackListTable;
