import TermsOfUse from "../components/TermsOfUse";
import Layout from "./Layout/Layout";

const TermsOfUseView = () => (
  <Layout>
    <TermsOfUse />
  </Layout>
);

export default TermsOfUseView;
