/* eslint-disable complexity */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { postTypes } from "../../../graphql/enum";
import {
  getGlobalPostUrl,
  subscriptionToSiteNewsUrl
} from "../../../routes/index";
import {
  classNames,
  getIsWomenCategory,
  handleDownloadFromUrl,
  isAgency
} from "../../../utility";
import { socialNetworks, telegramGropUrls } from "../../../utility/constants";

const SinglePostContacts = ({ post }) => {
  const { t } = useTranslation();
  const currentURL = window.location.href;
  const [isCopyBufer, setIsCopyBufer] = useState(false);

  const getLinkForTypePost = (type, workForWoomen) => {
    if (workForWoomen && type === postTypes.offerVacancy)
      return telegramGropUrls.workForWomen;
    switch (type) {
      case postTypes.rentProperty:
        return telegramGropUrls.rentProperty;
      case postTypes.carrier:
        return telegramGropUrls.carrier;
      case postTypes.announcements:
        return telegramGropUrls.announcements;
      case postTypes.lookingJob:
        return telegramGropUrls.work;
      case postTypes.offerVacancy:
        return telegramGropUrls.work;
      default:
        return telegramGropUrls.announcements;
    }
  };

  return (
    <>
      <div className="page-single-contacts">
        <div
          className="page-single-contacts-top page-single-wrap"
          style={{ marginBottom: "10px" }}
        >
          <label className="page-single-label">
            {t("post.contactInformation")}
          </label>
          <ul>
            <li className="users text-break">
              <strong> {t("post.username")}</strong>{" "}
              <span>
                {post.user.name}{" "}
                {isAgency(post.isAgency, t, post.type)
                  ? `( ${isAgency(post.isAgency, t, post.type)} )`
                  : ""}
              </span>
            </li>
            {post?.resume ? (
              <li className="cv">
                <strong>{t("post.downloadCV")}</strong>
                <span className="d-block ">
                  <span
                    className="btn btn-primary"
                    onClick={() => handleDownloadFromUrl(post?.resume)}
                  >
                    {t("post.downloadCVMin")}
                  </span>
                </span>
              </li>
            ) : null}
            {post?.linkYouTube ? (
              <li className="youtube text-break">
                <strong>{t("post.linkToTheVideoFromYouTube")}</strong>{" "}
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={post.linkYouTube}
                  className="text-ellipsis overflow-hidden"
                >
                  {post.linkYouTube}
                </a>
              </li>
            ) : null}
            {post?.user?.email ? (
              <li className="email text-break">
                <strong>{t("post.email")}</strong>
                <a
                  href={`mailto:${post.user.email}?subject=Subject&body=${t(
                    "responseMesenger.response"
                  )}${post.title} ${getGlobalPostUrl(post.id, post.seo)} ${t(
                    "responseMesenger.hello"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {post.user.email}
                </a>
              </li>
            ) : null}
            <li className="phone">
              <strong>{t("post.phone")}</strong>
              <a href={`tel:${post.user.phone}`}> {post.user.phone}</a>
            </li>
            <ul className="soc-links page-single-soc">
              {post.user.isViber ? (
                <li className="soc-links-viber">
                  <a
                    href={`viber://chat?number=${post.user.phone}&text=${t(
                      "responseMesenger.response"
                    )}${post.title} ${getGlobalPostUrl(post.id, post.seo)} ${t(
                      "responseMesenger.hello"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  />
                </li>
              ) : null}
              {post.user.isWhatsapp ? (
                <li className="soc-links-whatsapp">
                  <a
                    href={`https://wa.me/${post.user.phone}?text=${t(
                      "responseMesenger.response"
                    )}${post.title} ${getGlobalPostUrl(post.id, post.seo)} ${t(
                      "responseMesenger.hello"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  />
                </li>
              ) : null}
              {post.user.isTelegram ? (
                <li className="soc-links-telegram">
                  <a
                    href={`https://t.me/${post.user.phone}?text=${t(
                      "responseMesenger.response"
                    )}${post.title} ${getGlobalPostUrl(post.id, post.seo)} ${t(
                      "responseMesenger.hello"
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  />
                </li>
              ) : null}
            </ul>
            <li className="no-icon">
              <strong>{t("post.join")}</strong>
              <ul className="soc-links single-widget-soc">
                <li className="soc-links-facebook">
                  <a
                    href={socialNetworks.facebook}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
                <li className="soc-links-telegram">
                  <a
                    href={getLinkForTypePost(
                      post.type,
                      getIsWomenCategory({
                        id: post.offerVacancy?.categoryProfession?.id,
                        categories: post.offerVacancy?.categoryProfession
                          ? [post.offerVacancy?.categoryProfession]
                          : []
                      })
                    )}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
                <li className="soc-links-viber">
                  <a
                    href={socialNetworks.viber}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
                <li className="soc-links-mail">
                  <a href={subscriptionToSiteNewsUrl}> </a>
                </li>
              </ul>
            </li>
            <li className="no-icon">
              <strong>{t("post.shareThisMaterial")}</strong>
              <ul className="soc-links single-widget-soc">
                <li className="soc-links-mail">
                  <a
                    href={`
                  mailto:?subject=${post.title}&body=${currentURL}
                  `}
                  >
                    {" "}
                  </a>
                </li>
                <li className="soc-links-whatsapp">
                  <a
                    href={`
                  https://wa.me/?text=${currentURL}
                  `}
                  >
                    {" "}
                  </a>
                </li>
                <li className="soc-links-viber">
                  <a
                    href={`
                  viber://forward?text=${currentURL}
                  `}
                  >
                    {" "}
                  </a>
                </li>
                <li className={classNames("soc-links-link")}>
                  <a
                    style={{ cursor: isCopyBufer ? "default" : "pointer" }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(currentURL);
                      setIsCopyBufer(true);
                      window.alert(t("post.linkCopied"));
                    }}
                  ></a>
                </li>
                <li className="soc-links-telegram">
                  <a
                    href={`
                  https://t.me/share/url?url=${currentURL}
                  `}
                  >
                    {" "}
                  </a>
                </li>
                <li className="soc-links-facebook">
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${currentURL}`}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
                <li className="soc-links-instagram">
                  <a
                    href={`
                  https://www.instagram.com/?url=${currentURL}
                  `}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
              </ul>
            </li>
          </ul>
          <div className="page-single-btn-call position-static">
            <a
              href={`tel:${post.user.phone}`}
              className=" btn btn-primary btn-icon btn-icon-phone"
            >
              {t("post.call")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

SinglePostContacts.propTypes = {
  post: PropTypes.object
};
export default SinglePostContacts;
