export const telegramGropUrls = {
  work: "https://t.me/employment_in_uk",
  workForWomen: "https://t.me/jobs_for_women_in_uk",
  announcements: "https://t.me/obyavleniya_anglia",
  rentProperty: "https://t.me/rent_in_uk",
  carrier: "https://t.me/transit_in_uk",
  services: "https://t.me/business_in_uk"
};
export const socialNetworks = {
  facebook: "https://www.facebook.com/groups/239793706923122/?ref=share",
  instagram: "https://www.instagram.com/emigrant_center?igsh=czU4czQzaG9kemdk",
  youtube: "https://www.youtube.com/channel/UCXHgXf7Zo2l4Z5lQy1JW8Ww",
  viber:
    "https://invite.viber.com/?g2=AQBTwHPkFyjG7khW10r8vo31yUdViGG9qXXIAgUfvbRGxlW5P0f1%2FfBbAu6N5wv5",
  whatsapp: "https://chat.whatsapp.com/EQBgo6eJQY7LM5jn36sWSp"
};
export const postIndexs = [
  "E",
  "EC",
  "N",
  "NW",
  "SE",
  "SW",
  "W",
  "WC",
  "londonSurroundings"
];

export default null;
