import "moment/locale/ru";

import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getIsPremium } from "../../../../utility";
import { lang } from "../../../../utility/config";
import PostItem from "../PostItem";

const isDateEqual = (date, lastDate) =>
  date?.getDate() === lastDate?.getDate() &&
  date?.getMonth() === lastDate?.getMonth();

const ListPost = ({ posts, post, index, user, refetch, setPosts }) => {
  const { i18n } = useTranslation();
  return (
    <>
      {!isDateEqual(
        new Date(posts[index - 1]?.expireInPriorityDate),
        new Date(post.expireInPriorityDate)
      ) ? (
        <div
          key={`rr-${post.id}`}
          className="works-item works-item-sectionDate"
        >
          {moment(post.expireInPriorityDate)
            .locale(i18n.resolvedLanguage === lang.rus ? "ru" : "en")
            .format("DD MMMM YYYY")}
        </div>
      ) : null}
      <PostItem
        user={user}
        key={post.id}
        post={post}
        refetch={refetch}
        setPosts={setPosts}
        posts={posts}
        isPremium={getIsPremium(post.expireInPriorityDate, post.priority)}
      />
    </>
  );
};

ListPost.propTypes = {
  posts: PropTypes.array,
  post: PropTypes.object,
  index: PropTypes.number,
  user: PropTypes.object,
  refetch: PropTypes.func,
  setPosts: PropTypes.func
};
export default ListPost;
