import { gql } from "@apollo/client";

const authorizationEmailCode = gql`
  mutation authorizationEmailCode($code: String!) {
    authorizationEmailCode(code: $code) {
      success
      code
      jwtToken
    }
  }
`;

export default authorizationEmailCode;
