import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Hint from "../../../../layout/Hint";
import Input from "../../../../layout/Input/Input";
import Select from "../../../../layout/Select";
import UploadImageAndPreview from "../../../../layout/UploadImageAndPreview/UploadImageAndPreview";

const SecondBlock = ({
  values,
  setValues,
  errors,
  validateForm,
  data,
  images,
  valuesFormik,
  setValuesFormik
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-12 mb-4">
        <label className="form-label">
          <>
            {t("createPost.forEmploymentNeed")}{" "}
            <Hint components={t("hint.forEmploymentNeed")} />
          </>
        </label>
        <div className="form-check-inline-wrap align-items-center">
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isUTR"
              label={t("createPost.UTR")}
              name="isUTR"
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isNationalInsurance"
              name="isNationalInsurance"
              label={t("createPost.nationalInsurance")}
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isTools"
              label={t("createPost.tools")}
              name="isTools"
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isDriverLicense"
              name="isDriverLicense"
              label={t("createPost.driverLicense")}
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isCar"
              name="isCar"
              label={t("post.cars")}
            />
          </div>
        </div>
      </div>
      <div className="col-12 mb-4">
        <label for="inputCity" className="form-label">
          {t("createPost.workFor")}
        </label>
        <div className="form-check-inline-wrap align-items-center">
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="youCanWorkOnWeekends"
              name="youCanWorkOnWeekends"
              label={t("createPost.youCanWorkOnWeekends")}
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="assistantJob"
              name="assistantJob"
              label={t("post.assistantJob")}
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="workForAMarriedCouple"
              label={t("createPost.couples")}
              name="workForAMarriedCouple"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6 mb-4">
        <Input
          type="text"
          id="workSchedule"
          label={t("post.workSchedule")}
          name="workSchedule"
          value={valuesFormik.workSchedule}
        />
      </div>
      <div className="col-lg-3 mb-4">
        <Select
          value={values.paymentPeriod}
          options={data.paymentPeriods.map((p) => ({
            ...p,
            title: t(`paymentPeriod.${p.title}`)
          }))}
          id="typeQuestion"
          label={<>{t("post.payment")}</>}
          onChange={async (val) => {
            await setValues({ ...values, paymentPeriod: val });
            validateForm();
          }}
          error={errors?.paymentPeriod}
        />
      </div>
      <div className="col-lg-3 mb-4">
        <Input
          type="number"
          id="inputTitles"
          label={t("post.sum")}
          placeholder={t("post.sum")}
          name="sum"
          error={errors.sum}
          value={valuesFormik.sum}
          disabled={!values.paymentPeriod}
          onChange={async (e) => {
            const newValue = parseFloat(e.target.value);
            await setValuesFormik((prevValues) => ({
              ...prevValues,
              sum: Math.max(0, newValue)
            }));
            validateForm();
          }}
        />
      </div>

      <div className="col-12 mb-4">
        <UploadImageAndPreview
          title={t("createPost.uploadAPhotoInGalarey")}
          imagesDefault={images}
          setFile={(fileId) =>
            setValues({
              ...values,
              imagesIds: [...values.imagesIds, fileId]
            })
          }
          removeFile={(fileId) => {
            const newImages = values.imagesIds.filter((i) => i !== fileId);
            setValues({
              ...values,
              imagesIds: newImages
            });
          }}
        />
      </div>
    </>
  );
};
SecondBlock.defaultValues = {
  validateForm: () => {}
};

SecondBlock.propTypes = {
  values: PropTypes.shape({
    imagesIds: PropTypes.array,
    description: PropTypes.string,
    paymentPeriod: PropTypes.string
  }),
  setValues: PropTypes.func,
  errors: PropTypes.object,
  validateForm: PropTypes.func,
  data: PropTypes.object,
  images: PropTypes.array,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};
export default SecondBlock;
