/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { pageSize, postTypes } from "../../../../graphql/enum";
import { getFromIsoDate } from "../../../../graphql/utils/date";
import { getArrayIfNotEmpty, getOffset } from "../../../../utility";
import PostTable from "../../../layout/PostTable";

export const checkInsQuery = gql`
  query posts($filters: PostFilters, $pagination: PaginationInput) {
    posts {
      total(filters: $filters)
      data(pagination: $pagination, filters: $filters) {
        id
        title
        seo
        type
        city {
          id
          title
        }
        carrier {
          cityDeparture
        }
        expireInPriorityDate
        priority
      }
    }
  }
`;

const CarrierTable = ({
  filters,
  setTotalPosts,
  currentPage,
  setCurrentPage
}) => {
  const { loading, data, refetch } = useQuery(checkInsQuery, {
    variables: {
      filters: {
        type: postTypes.carrier,
        search: filters.search || null,
        carrierCountryDepartureIds: getArrayIfNotEmpty(
          filters.carrierCountryDepartureId
        ),
        carrierCountryArrivalIds: getArrayIfNotEmpty(
          filters.carrierCountryArrivalId
        ),
        postCarrierCountryAlongRouteIds: getArrayIfNotEmpty(
          filters.postCarrierCountryAlongRoute
        ),
        dateDepartureFrom: getFromIsoDate(filters.dateDepartureFrom),
        dateDepartureTo: getFromIsoDate(filters.dateDepartureTo)
      },

      pagination: {
        limit: pageSize,
        offset: getOffset({ currentPage, pageSize })
      }
    }
  });

  setTotalPosts(data?.posts?.total || 0);
  return (
    <PostTable
      pageSize={pageSize}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      posts={data?.posts?.data || []}
      total={data?.posts?.total || 0}
      loading={loading}
      refetch={refetch}
    />
  );
};

CarrierTable.propTypes = {
  filters: PropTypes.object,
  setTotalPosts: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func
};
export default CarrierTable;
