/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import deletePostByCode from "../../../graphql/mutation/deletePostByCode";
import updateBlackList from "../../../graphql/mutation/updateBlackList";
import { addBlackListUrl, blackListUrl } from "../../../routes";
import { socialNetworks } from "../../../utility/constants";
import { dateIsoFormat } from "../../../utility/date";
import Loading from "../../layout/Loading/Loading";
import Modal from "../../layout/Modal";

export const blackListByCodeQuery = gql`
  query blackListByCode($code: String) {
    blackListByCode(code: $code) {
      phoneCompany
      emailCompany
      webSiteCompany
      name
      email
      phone
      isViber
      isTelegram
      isWhatsapp
      isAdmin
      description
      createdAt
    }
  }
`;

const getStringFromObjectByParametrs = (data, arrayKey) => {
  const array = [];
  arrayKey.map((key) => {
    const value = data[key];
    if (value) array.push(value);
    return null;
  });
  return array.join(", ");
};

const ComplaintSentBlackList = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const history = useHistory();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);

  const { loading, data } = useQuery(
    blackListByCodeQuery,
    {
      variables: {
        code
      }
    },
    {
      fetchPolicy: "no-cache"
    }
  );
  const [deletePostByCodeMutation] = useMutation(deletePostByCode);
  const [updateBlackListMutation] = useMutation(updateBlackList);

  const editUrl = queryString.stringifyUrl({
    url: addBlackListUrl,
    query: {
      code
    }
  });
  if (loading) return <Loading />;
  const blackList = data.blackListByCode;
  return (
    <>
      <div className="page-section page-complaint-sent">
        <h1 className="page-title text-center mb-3 mb-lg-5">
          {t("blackList.complaintSent")}{" "}
          {moment.utc(blackList.createdAt).format(dateIsoFormat)}
        </h1>
        <div className="row">
          <div className="col-lg-3 offset-lg-1 text-center text-lg-start mb-3 mb-lg-0">
            <img src="/assets/img/complaint-sent.png" alt="" />
          </div>
          <div className="col-lg-7">
            <div className="row mb-3 text-center text-lg-start complaint-sent-heading">
              <div className="col-lg-3">{t("blackList.complaintFrom")} </div>
              <div className="col-lg-9">
                {getStringFromObjectByParametrs(blackList, [
                  "name",
                  "phone",
                  "email"
                ])}
              </div>
            </div>
            <div className="row mb-3 mb-lg-5 text-center text-lg-start complaint-sent-heading">
              <div className="col-lg-3"> {t("blackList.complaintAbout")}</div>
              <div className="col-lg-9">
                {getStringFromObjectByParametrs(blackList, [
                  "nameCompany",
                  "phoneCompany",
                  "emailCompany",
                  "webSiteCompany"
                ])}
              </div>
            </div>
            <div className="complaint-sent-text">{blackList?.description}</div>
          </div>
          <div className="col-lg-10 offset-lg-1 mt-3 mt-lg-5">
            <div className="complaint-sent-note text-center mb-3 mb-lg-5">
              <p>{t("blackList.text")}</p>
              <p>
                {t("blackList.text_1")}
                <br />
                {t("blackList.text_7")}
              </p>
            </div>
            <div className="complaint-sent-btn text-center">
              <button
                onClick={() => setIsDeleteModalVisible(true)}
                className="btn btn-outline-primary me-3"
              >
                {t("blackList.delete")}
              </button>{" "}
              <button
                onClick={() => history.push(editUrl)}
                className="btn btn-outline-primary me-3"
              >
                {t("blackList.edit")}
              </button>
              <button
                onClick={async () => {
                  await updateBlackListMutation({
                    variables: {
                      code,
                      input: {
                        isShow: true
                      }
                    }
                  });
                  setIsPublishModalVisible(true);
                }}
                className="btn btn-primary"
              >
                {t("blackList.publish")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isShow={isDeleteModalVisible}
        onClose={() => {
          setIsDeleteModalVisible(false);
        }}
      >
        <div className="block-no-content__image text-center">
          <img
            src="/assets/img//emoji-remove-2.svg"
            width="130"
            height="130"
            alt={t("modal.DeleteComplaint")}
          />
        </div>
        <h2 className="block-no-content__title text-center">
          {t("modal.DeleteComplaint")}
        </h2>
        <div className="block-no-content__button text-center">
          <button
            onClick={async () => {
              await deletePostByCodeMutation({
                variables: {
                  code
                }
              });
              history.push(blackListUrl);
            }}
            className="btn btn-fourth"
          >
            {t("modal.yes")}
          </button>
          <button className="btn btn-thirdy">{t("modal.no")}</button>
        </div>
      </Modal>
      <Modal
        isShow={isPublishModalVisible}
        onClose={() => {
          setIsPublishModalVisible(false);
          history.push(blackListUrl);
        }}
      >
        <div className="block-no-content__image text-center">
          <img
            src="/assets/img//emoji-subscribe-success.svg"
            width="130"
            height="130"
            alt={t("modal.afterAddBlackList")}
          />
        </div>
        <h1 className="thanks-title">{t("modal.messageSent")}</h1>
        <div className="thanks-btn">
          <a href="/" className="btn btn-primary" aria-label={t("modal.home")}>
            {t("modal.home")}
          </a>
        </div>
        <div className="thanks-soc">
          <p>{t("modal.joinGroups")}</p>
          <ul className="soc-links soc-links-thanks">
            <li className="soc-links-instagram">
              <a
                href={socialNetworks.instagram}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-facebook">
              <a
                href={socialNetworks.facebook}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-telegram">
              <a
                href="https://t.me/+SyzcC03mwARkYjE0"
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-whatsapp">
              <a
                href="whatsapp://send?phone=+447599683751"
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-viber">
              <a
                href={socialNetworks.viber}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

ComplaintSentBlackList.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func
};
export default ComplaintSentBlackList;
