import { gql } from "@apollo/client";

const updatePost = gql`
  mutation updatePost($code: String, $postId: ID, $input: PostInput) {
    updatePost(input: $input, code: $code, postId: $postId) {
      success
      code
    }
  }
`;

export default updatePost;
