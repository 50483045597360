/* eslint-disable max-statements */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import FallbackSpinner from "../../components/layout/Loading/Loading";
import updateUserMut from "../../graphql/mutation/updateUser";
import { lang } from "../config";
import { getUserData, setJWT, setUserData } from "../localStorage";

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      email
      name
      phone
      role
      lang
    }
    analytic {
      totalViews
    }
  }
`;

export const UserContext = React.createContext({});
export const UserContextProvider = ({ children }) => {
  const [updateUserMutation] = useMutation(updateUserMut);

  const userLocalStorage = getUserData();
  const userLocalStorageObject = JSON.parse(userLocalStorage || "{}");
  const [user, setUser] = useState({
    ...userLocalStorageObject,
    lang: userLocalStorageObject.lang || lang.rus,
    createSesion: new Date(),
    loading: true
  });
  const [otherData, setOtherData] = useState({
    analytic: {
      totalViews: 100000
    }
  });

  const { loading, error, data, refetch } = useQuery(CURRENT_USER, {
    errorPolicy: "ignore"
  });
  useEffect(() => {
    if (!loading) {
      const currentUser =
        !error && data && data.currentUser
          ? {
              id: data.currentUser.id,
              email: data.currentUser.email,
              name: data.currentUser.name,
              phone: data.currentUser.phone,
              role: data.currentUser.role,
              lang: data.currentUser.lang
            }
          : false;
      setUser({ ...user, ...currentUser, loading: false });

      const analytic =
        !error && data && data.analytic
          ? {
              totalViews: data.analytic.totalViews
            }
          : null;

      if (analytic) {
        setOtherData({ analytic });
      }
    }
  }, [error, data, loading]);

  if (user === null) return <FallbackSpinner />;

  const updateUser = async (userData) => {
    const newUser = { ...user, ...userData };
    setUserData(`${JSON.stringify(newUser)}`);
    if (data.currentUser.id && userData.lang && userData.lang !== user.lang) {
      await updateUserMutation({
        variables: {
          input: { lang: userData.lang }
        }
      });
    }
    setUser(newUser);
  };

  const login = async (jwt) => {
    await setJWT(jwt);
    refetch();
  };

  const logout = () => {
    setJWT(null);
    setUser(null);
    setUserData(null);
    refetch();
  };

  return (
    <UserContext.Provider
      value={{ user, otherData, login, logout, updateUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
