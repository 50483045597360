import PropTypes from "prop-types";

import { typeField } from "../../../../graphql/enum";
import ListInformationPosteElement from "./ListInformationPosteElement";

const ListInformationPostAnnouncement = ({ post, allPost }) => (
  <ul className="page-single-list page-single-list-half">
    <ListInformationPosteElement element={post.city} type={typeField.city} />
    <ListInformationPosteElement
      element={allPost.postIndexs}
      type={typeField.postIndexs}
    />
    <ListInformationPosteElement
      element={post.postAnnouncementCategory}
      type={typeField.announcementCategory}
    />{" "}
    <ListInformationPosteElement
      element={post.postAnnouncementRubric}
      type={typeField.announcementRubric}
    />
  </ul>
);

ListInformationPostAnnouncement.propTypes = {
  post: PropTypes.object,
  allPost: PropTypes.object
};
export default ListInformationPostAnnouncement;
