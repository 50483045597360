import PropTypes from "prop-types";
import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { postTypes } from "../../../graphql/enum";
import { addPostUrl } from "../../../routes";
import { UserContext } from "../../../utility/context/User";
import Tabs from "../../layout/Tabs/Tabs";
import Announcement from "./Announcement";
import Carrier from "./Carrier";
import LookingJob from "./LookingJob";
import OfferVacancy from "./OfferVacancy";
import RentProperty from "./RentProperty";

const FirstStage = (props) => {
  const { t } = useTranslation();
  const parameters = new URLSearchParams(window.location.search);
  const { user } = useContext(UserContext);

  const activeTab = parameters.get("activeTab");
  const history = useHistory();

  const defaultKeyId = postTypes.announcements;
  useEffect(() => {
    if (!activeTab) {
      const otherParrameters = {};
      parameters.forEach((value, key) => {
        otherParrameters[key] = value;
        return null;
      });
      const url = queryString.stringifyUrl({
        url: addPostUrl,
        query: {
          ...otherParrameters,
          activeTab: props?.variablesAccessEdit?.isEdit
            ? props?.editPostData?.type
            : defaultKeyId
        }
      });
      history.push(url);
    }
  });
  const tabs = [
    {
      name: postTypes.offerVacancy,
      title: t("createPost.iOfferAVacancy"),
      render: () => <OfferVacancy {...{ ...props, user }} />
    },
    {
      name: postTypes.lookingJob,
      title: t("createPost.lookingForAJob"),
      render: () => <LookingJob {...{ ...props, user }} />
    },
    {
      name: postTypes.announcements,
      title: t("createPost.announcement"),
      render: () => <Announcement {...{ ...props, user }} />
    },
    {
      name: postTypes.carrier,
      title: t("createPost.carriers"),
      render: () => <Carrier {...{ ...props, user }} />
    },
    {
      name: postTypes.rentProperty,
      title: t("createPost.rentalHousing"),
      render: () => <RentProperty {...{ ...props, user }} />
    }
  ];

  return (
    <div className="page-add">
      <Tabs defaultKey={activeTab} tabs={tabs} currentUrl={addPostUrl} />
    </div>
  );
};

FirstStage.propTypes = {
  filter: PropTypes.object,
  editPostData: PropTypes.shape({
    type: PropTypes.string
  }),
  variablesAccessEdit: PropTypes.shape({
    variables: PropTypes.shape({
      code: PropTypes.string,
      postId: PropTypes.string
    }),
    isEdit: PropTypes.bool
  })
};
export default FirstStage;
