import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";

const ImageGalarey = ({ images }) => {
  if (!images.length) return null;
  const newImage = images.map(({ src }) => ({
    original: src,
    thumbnail: src
  }));
  /*
    color: black;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 0px;
*/
  return (
    <div className="preview-images">
      <ImageGallery
        thumbnailPosition="left"
        items={newImage}
        renderFullscreenButton={(onClick, arf) => {
          if (arf)
            return (
              <svg
                alt="Close"
                className="image-gallery-fullscreen-close-icon"
                src="/assets/img/close.svg"
                style={{
                  display: "block",
                  zIndex: 10,
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "white",
                  cursor: "pointer",
                  width: "60px"
                }}
                onClick={onClick}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 3.205L8.795 2.5L6 5.295L3.205 2.5L2.5 3.205L5.295 6L2.5 8.795L3.205 9.5L6 6.705L8.795 9.5L9.5 8.795L6.705 6L9.5 3.205Z"
                  fill="#ff4242"
                />
              </svg>
            );

          return (
            <div
              style={{
                display: "block",
                height: "100%",
                width: "100%",
                zIndex: 10,
                position: "absolute",
                top: "0px",
                cursor: "pointer"
              }}
              onClick={onClick}
            ></div>
          );
        }}
      />
    </div>
  );
};

ImageGalarey.defaultProps = {
  images: []
};
ImageGalarey.propTypes = {
  images: PropTypes.array
};
export default ImageGalarey;
