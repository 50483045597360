import { gql } from "@apollo/client";

const deletePostByCode = gql`
  mutation deletePostByCode($code: String!) {
    deletePostByCode(code: $code) {
      success
      code
    }
  }
`;

export default deletePostByCode;
