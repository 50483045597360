import PropTypes from "prop-types";

import { classNames } from "../../../../utility";

const PrimaryButton = ({ children, onClick, className }) => (
  <span onClick={onClick} className={classNames("btn btn-primary", className)}>
    {children}
  </span>
);

PrimaryButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default PrimaryButton;
