import PropTypes from "prop-types";
import { useState } from "react";

import { classNames } from "../../../utility";

const SideBarDropdown = ({
  children,
  title,
  isOpenDefault,
  isStaticBlock = false,
  classnameWidgetContent
}) => {
  const [isActive, setIsActive] = useState(isOpenDefault);

  return (
    <div
      className={classNames("widget widget-class-city", isActive ? "show" : "")}
    >
      <span
        onClick={() => {
          if (!isStaticBlock) setIsActive(!isActive);
        }}
        className="widget-title"
      >
        <span>{title}</span>
        {!isStaticBlock ? <i></i> : null}
      </span>
      <div className={classNames("widget-content", classnameWidgetContent)}>
        {children}
      </div>
    </div>
  );
};

SideBarDropdown.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  isOpenDefault: PropTypes.bool,
  classnameWidgetContent: PropTypes.string,
  isStaticBlock: PropTypes.bool
};
SideBarDropdown.defaultProps = {
  isOpenDefault: false
};

export default SideBarDropdown;
