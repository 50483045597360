/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { handlePhobePress, isSelectLondon } from "../../../../utility";
import { sexOptions } from "../../../Contants";
import Input from "../../../layout/Input/Input";
import Loading from "../../../layout/Loading/Loading";
import Select from "../../../layout/Select";
import MultiSelect from "../../../layout/Select/MultiSelect";
import UploadImage from "../../../layout/UploadImage/UploadImage";

export const allDataQuery = gql`
  query allData {
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
  }
`;

const QuestionnaireCreateTitleBlock = ({
  validateForm,
  errors,
  values,
  setValues,
  valuesFormik,
  setValuesFormik
}) => {
  const { t } = useTranslation();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { loading, data } = useQuery(allDataQuery);
  if (loading) return <Loading />;
  if (isFirstRender && data) {
    validateForm();
    setIsFirstRender(false);
  }
  if (!values.cityInEngland) {
    const london = data.cities.find(({ title }) => title === "London");

    setValues({ ...values, cityInEngland: london.id });
  }

  return (
    <div className="section-add">
      <h4>{t("questionnaire.pleaseOntroduceYourself")}</h4>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6 mb-4">
              <Input
                type="text"
                error={errors?.name}
                required={true}
                label={t("questionnaire.name")}
                value={valuesFormik?.name}
                name="name"
                id="inputName"
                maxLength="80"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <Input
                type="number"
                name="age"
                id="inputYear"
                error={errors?.age}
                label={t("questionnaire.age")}
                isDisplayValid={true}
                value={valuesFormik?.age}
              />
            </div>
            <div className="col-lg-6 mb-4">
              <Input
                error={errors?.phone}
                label={t("questionnaire.phone")}
                name="phone"
                onKeyUp={handlePhobePress({ valuesFormik, setValuesFormik })}
                maxLength="13"
                type="text"
                id="inputPhone"
                placeholder="+"
                required="1"
                value={valuesFormik?.phone}
              />
              <div className="mt-3">
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    id="inlineCheckbox1"
                    label={t("viber")}
                    name="viber"
                  />
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    id="inlineCheckbox2"
                    name="telegram"
                    label={t("telegram")}
                  />
                </div>
                <div className="form-check form-check-inline me-0">
                  <Input
                    type="checkbox"
                    id="inlineCheckbox3"
                    label={t("whatsApp")}
                    name="whatsApp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <Input
                type="email"
                label={t("questionnaire.email")}
                name="email"
                maxLength="50"
                id="inputEmail"
                error={errors?.email}
                value={valuesFormik?.email}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6 mb-4">
              <Select
                value={values.sex}
                options={sexOptions.map((x) => ({
                  ...x,
                  title: t(x.title)
                }))}
                onChange={async (val) => {
                  await setValues({ ...values, sex: val });
                  validateForm();
                }}
                error={errors?.sex}
                label={<>*{t("questionnaire.sex")}</>}
                className="form-select"
                id="inputSex"
                aria-label="Default select example"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <UploadImage
                title={t("questionnaire.uploadAPhoto")}
                setFile={(fileId) =>
                  setValues({
                    ...values,
                    imageId: fileId
                  })
                }
                deleteFile={() => setValues({ ...values, imageId: null })}
              />{" "}
            </div>
            <div className="col-lg-6 mb-4">
              <Select
                value={values.cityInEngland}
                options={data.cities.map((city) => ({
                  ...city,
                  title: t(`city.${city.title}`)
                }))}
                onChange={async (val) => {
                  const postIndexIds = isSelectLondon(val, data.cities)
                    ? values.postIndexIds
                    : [];
                  await setValues({
                    ...values,
                    cityInEngland: val,
                    postIndexIds
                  });
                  validateForm();
                }}
                error={errors?.cityInEngland}
                label={<>*{t("questionnaire.cityInEngland")}</>}
                className="form-select"
                id="inputCity"
                aria-label="Default select example"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <MultiSelect
                value={values.postIndexIds}
                options={data.postIndexs.map((p) => ({
                  ...p,
                  title: t(`postIndexs.${p.title}`)
                }))}
                id="londonPostcodes"
                label={t("questionnaire.myPostalCode")}
                disabled={!isSelectLondon(values.cityInEngland, data.cities)}
                onChange={(val) => setValues({ ...values, postIndexIds: val })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuestionnaireCreateTitleBlock.propTypes = {
  errors: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    cityInEngland: PropTypes.string,
    sex: PropTypes.string,
    phone: PropTypes.string,
    age: PropTypes.string,
    email: PropTypes.string
  }),
  values: PropTypes.shape({
    cityInEngland: PropTypes.string,
    postCode: PropTypes.string,
    sex: PropTypes.string,
    imagesIds: PropTypes.array,
    postIndexIds: PropTypes.array
  }),
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  setValuesFormik: PropTypes.func,
  valuesFormik: PropTypes.shape({
    name: PropTypes.string,
    age: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  })
};
export default QuestionnaireCreateTitleBlock;
