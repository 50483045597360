import ListLinks from "../../components/Admin/ListLinks";
import Layout from "../Layout/Layout";

const ListLinksAdminView = () => (
  <Layout>
    <ListLinks />
  </Layout>
);

export default ListLinksAdminView;
