/* eslint-disable max-statements */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable max-lines */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { privateAnnouncementUrl } from "../../../../routes";
import {
  getArrayIfNotEmpty,
  isSelectedAllPostCode,
  isSelectLondon,
  setNewFilters
} from "../../../../utility";
import Input from "../../../layout/Input/Input";
import Link from "../../../layout/Link";
import Loading from "../../../layout/Loading/Loading";
import Radio from "../../../layout/Radio/Radio";
import SideBarDropdown from "../../../layout/SideBarDropdown/SideBarDropdown";

export const allDataQuery = gql`
  query allData {
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
    postAnnouncementCategory {
      id
      title
      postAnnouncementRubric {
        id
        title
      }
    }
    postAnnouncementRubric {
      id
      title
    }
  }
`;

const AnnouncementsSideBar = ({ filters, setFilters, totalPosts }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [rubric, setRubric] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { loading, data } = useQuery(allDataQuery);

  if (loading) return <Loading />;
  if (!data) return null;
  const validate = (valuesFormik) => {
    if (!data) return;
    const values = {
      ...filters,
      ...valuesFormik
    };

    const cat = getArrayIfNotEmpty(values.postAnnouncementCategoryIds);

    const listCategory = cat
      ? data.postAnnouncementCategory.filter((c) => cat.includes(c.id))
      : data.postAnnouncementCategory;
    const newRubric = [];
    listCategory.map((c) => {
      newRubric.push(...c.postAnnouncementRubric);
      return null;
    });
    const newRubricIds = newRubric.map((g) => g.id);
    const filterRubruc = (values.postAnnouncementRubricIds || []).filter((r) =>
      newRubricIds.includes(r)
    );
    setRubric(newRubric);

    setNewFilters({
      filters,
      newFilters: {
        ...values,
        postAnnouncementRubricIds: filterRubruc
      },
      setFilters,
      history,
      url: privateAnnouncementUrl
    });
  };
  if (isFirstRender && data) {
    setIsFirstRender(false);
    validate({});
  }
  return (
    <>
      <Formik initialValues={filters} validate={validate}>
        {({ setValues, values }) => (
          <>
            <div className="sidebar-block">
              <h6 className="sidebar-title sidebar-title-clear">
                <span>{t("filter")}</span>
                <Link
                  to="#"
                  onClick={async () => {
                    const emptyData = {
                      postAnnouncementCategoryIds: [],
                      postAnnouncementRubricIds: [],
                      cityId: null,
                      postIndexs: [],
                      announcementFromAgencies: [],
                      privateAds: [],
                      search: undefined,
                      currentPage: 1
                    };
                    await setFilters(emptyData);
                    setValues(emptyData);
                  }}
                >
                  {t("sideBarFilter.cleanItUp")}
                </Link>
              </h6>
              <Form>
                <SideBarDropdown title={t("sideBarFilter.category")}>
                  {data.postAnnouncementCategory.map((p) => (
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        label={t(`announcementCategory.${p.title}`)}
                        key={t(`postAnnouncementCategory.${p.id}`)}
                        id={`postAnnouncementCategory=${p.id}`}
                        value={p.id}
                        name={`postAnnouncementCategoryIds`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown title={t("sideBarFilter.announcementRubric")}>
                  {(rubric.length ? rubric : data.postAnnouncementRubric).map(
                    (p) => (
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          label={t(`announcementRubric.${p.title}`)}
                          key={t(`announcementRubric.${p.id}`)}
                          id={`announcementRubric=${p.id}`}
                          value={p.id}
                          name={`postAnnouncementRubricIds`}
                        />
                      </div>
                    )
                  )}
                </SideBarDropdown>
                <SideBarDropdown title={t("sideBarFilter.cityInEngland")}>
                  <Radio
                    key="input-checbox"
                    isAddDiv={true}
                    options={data.cities.map((c) => ({
                      id: c.id,
                      value: c.id,
                      title: t(`city.${c.title}`)
                    }))}
                    name="cityInEngland"
                    value={filters?.cityId}
                    onClick={(val) =>
                      setValues({
                        ...values,
                        cityId: val,
                        postIndexs: isSelectLondon(filters.cityId, data.cities)
                          ? values.postIndexs
                          : []
                      })
                    }
                  />
                </SideBarDropdown>
                {isSelectLondon(values.cityId, data.cities) ? (
                  <SideBarDropdown title={t("sideBarFilter.londonPostcodes")}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="allLondon"
                        checked={
                          !values.postIndexs.length ||
                          isSelectedAllPostCode(
                            data.postIndexs
                              .filter((x) => values.postIndexs.includes(x.id))
                              .map((x) => x.title)
                          )
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValues({
                              ...values,
                              postIndexs: data.postIndexs.map((x) => x.id)
                            });
                          } else {
                            setValues({
                              ...values,
                              postIndexs: []
                            });
                          }
                        }}
                      />
                      <label htmlFor="allLondon">
                        {t("sideBarFilter.allLondon")}
                      </label>
                    </div>
                    {data.postIndexs.map((x) => (
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          label={t(`postIndexs.${x.title}`)}
                          key={t(`postIndexs.${x.id}`)}
                          id={`postCode=${x.id}`}
                          value={x.id}
                          name={`postIndexs`}
                        />
                      </div>
                    ))}
                  </SideBarDropdown>
                ) : null}
                <div className="widget widget-class-additional show">
                  <div className="widget-content mt-0">
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        key={"announcementFromAgencies"}
                        value={"true"}
                        label={t("sideBarFilter.announcementFromAgencies")}
                        id={"announcementFromAgencies"}
                        name={`announcementFromAgencies`}
                      />
                    </div>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        key={"privateAds"}
                        value={"true"}
                        label={t("sideBarFilter.privateAds")}
                        id={"privateAds"}
                        name={`privateAds`}
                      />
                    </div>
                  </div>
                </div>
                <div className="widget widget-class-result show">
                  <p>
                    {totalPosts} {t("sideBarFilter.posts")}
                  </p>
                </div>
              </Form>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

AnnouncementsSideBar.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalPosts: PropTypes.number,
  typeActive: PropTypes.string
};

export default AnnouncementsSideBar;
