/* eslint-disable i18next/no-literal-string */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Hint from "../../../../layout/Hint";
import Input from "../../../../layout/Input/Input";
import UploadDocument from "../../../../layout/UploadDocument";
import UploadImageAndPreview from "../../../../layout/UploadImageAndPreview/UploadImageAndPreview";

const SecondBlock = ({ values, setValues, images }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-12 mb-4">
        <label className="form-label">
          {t("createPost.forEmploymentNeedLookingJob")}{" "}
          <Hint components={t("hint.forEmploymentNeed")} />
        </label>
        <div className="form-check-inline-wrap align-items-center">
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isNationalInsurance"
              name="isNationalInsurance"
              label={t("createPost.nationalInsurance")}
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isUTR"
              label={t("createPost.UTR")}
              name="isUTR"
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isTools"
              label={t("createPost.tools")}
              name="isTools"
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isDriverLicense"
              name="isDriverLicense"
              label={t("createPost.driverLicense")}
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isCar"
              name="isCar"
              label={t("post.cars")}
            />
          </div>
        </div>
      </div>
      <div className="col-12 mb-4">
        <label for="inputCity" className="form-label">
          {t("createPost.lookingForAJob")}
        </label>
        <div className="form-check-inline-wrap align-items-center">
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="workForAMarriedCouple"
              label={t("createPost.lookingJob.workForAMarriedCouple")}
              name="workForAMarriedCouple"
            />
          </div>

          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="assistantJob"
              name="assistantJob"
              label={t("post.lookingAssistant")}
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="checkbox"
              id="youCanWorkOnWeekends"
              name="youCanWorkOnWeekends"
              label={t("createPost.lookingJob.youCanWorkOnWeekends")}
            />
          </div>
        </div>
      </div>
      <div className="col-12 mb-4">
        <UploadImageAndPreview
          title={t("createPost.uploadAPhotoInGalarey")}
          imagesDefault={images}
          setFile={(fileId) =>
            setValues({
              ...values,
              imagesIds: [...values.imagesIds, fileId]
            })
          }
          removeFile={(fileId) => {
            const newImages = values.imagesIds.filter((i) => i !== fileId);
            setValues({
              ...values,
              imagesIds: newImages
            });
          }}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <div className="input-group">
          <UploadDocument
            id="inputPhotoCV"
            label={t("createPost.uploadCV")}
            setFile={(fileId) =>
              setValues({
                ...values,
                fileId
              })
            }
            deleteFile={() => setValues({ ...values, fileId: null })}
          />
        </div>
        <small className="fw-normal mt-1">
          {t("post.fileFormats")} jpg, jpeg, doc, docx, pdf
        </small>
      </div>
    </>
  );
};
SecondBlock.defaultValues = {
  validateForm: () => {}
};

SecondBlock.propTypes = {
  values: PropTypes.shape({
    imagesIds: PropTypes.array,
    description: PropTypes.string,
    paymentPeriod: PropTypes.string
  }),
  setValues: PropTypes.func,
  errors: PropTypes.object,
  validateForm: PropTypes.func,
  data: PropTypes.object,
  images: PropTypes.array
};
export default SecondBlock;
