/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { myOfficeUrl } from "../../routes";
import Link from "../layout/Link";
import Dashboard from "./Dashboard";
import Settings from "./Settings";

export const postsQuery = gql`
  query posts($filters: PostFilters, $pagination: PaginationInput) {
    posts {
      total(filters: $filters)
      data(pagination: $pagination, filters: $filters) {
        id
        title
        seo
        type
        city {
          id
          title
        }
        rentProperty {
          postRentPropertyCategory {
            title
          }
        }
        expireInPriorityDate
        priority
      }
    }
  }
`;
const UserOfficeDashboard = () => {
  const { t } = useTranslation();

  const parameters = new URLSearchParams(window.location.search);
  const page = parameters.getAll("page")[0];
  const tabsList = [
    {
      id: "dashboard",
      title: t("office.yourAds")
    },
    {
      id: "yourAdvertisement",
      title: t("office.yourAdvertisement")
    },
    {
      id: "survey",
      title: t("office.survey")
    },
    {
      id: "company",
      title: t("office.company")
    },
    {
      id: "settings",
      title: t("office.settings")
    }
  ];
  const tabs = (
    <ul className="type-menu type-menu-horizontal">
      {tabsList.map((ta) => (
        <li>
          <Link
            to={`${myOfficeUrl}?page=${ta.id}`}
            className={`btn btn-outline-primary w-100 ${
              (!page && ta.id === tabsList[0].id) || ta.id === page
                ? "active"
                : "d"
            }`}
          >
            {ta.title}
          </Link>
        </li>
      ))}
    </ul>
  );

  const tabsArray = [
    {
      id: "dashboard",
      component: () => <Dashboard tabsHeader={tabs} />
    },
    {
      id: "yourAdvertisement",
      component: () => null
    },
    {
      id: "survey",
      component: () => null
    },
    {
      id: "company",
      component: () => null
    },
    {
      id: "settings",
      component: () => <Settings tabsHeader={tabs} />
    }
  ];

  const activeTab = tabsArray.find((b) => b.id === page) || tabsArray[0];

  return activeTab.component();
};

UserOfficeDashboard.propTypes = {
  filter: PropTypes.object
};
export default UserOfficeDashboard;
