import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { aboutUrl, homeUrl } from "../../routes";
import Page from "../layout/Page";

const About = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "aboutTheProject",
      title: t("aboutTheProject.title"),
      link: aboutUrl
    }
  ];
  return (
    <Page pageName={t("aboutTheProject.title")} breadcrumbs={breadcrumbs}>
      <div className="page-section page-about-project">
        <div className="page-image-left">
          <img src="/assets/img/permit-work-image.jpg" alt="" />
        </div>
        <p>{t("aboutTheProject.euroworkProject")}</p>
        <p>{t("aboutTheProject.text")}</p>
        <p>{t("aboutTheProject.text_1")}</p>
        <p></p>
        <p></p>
        <ol>
          <li>{t("aboutTheProject.list_1")}</li>
          <li>{t("aboutTheProject.list_2")}</li>
          <li>{t("aboutTheProject.list_3")}</li>
          <li>{t("aboutTheProject.list_4")}</li>
          <li>{t("aboutTheProject.list_5")}</li>
        </ol>
        <h4 style={{ clear: "both" }}>{t("aboutTheProject.emigrantCenter")}</h4>
        <p>{t("aboutTheProject.thisIsAProjectOfOnlineIntegrations")}</p>
      </div>
    </Page>
  );
};

About.propTypes = {
  filter: PropTypes.object
};
export default About;
