import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Page from "../../layout/Page";
import Sidebar from "./SideBar";
import Subscription from "./Subscription";

const Settings = ({ tabsHeader }) => {
  const { t } = useTranslation();
  const tabs = [
    {
      id: "novomationSubscription",
      title: t("office.novomationSubscription"),
      render: () => <Subscription />
    },
    {
      id: "changePassword",
      title: t("office.changePassword"),
      render: () => null
    },
    {
      id: "leaveTheAccount",
      title: t("office.leaveTheAccount"),
      render: () => null
    },
    {
      id: "deleteProfile",
      title: t("office.deleteProfile"),
      render: () => null
    }
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <Page
      sidebar={{
        mobileTextButton: t("Setting"),
        render: () => (
          <Sidebar
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )
      }}
      beforeContent={tabsHeader}
      pageName={t("header.personalArea")}
    >
      {selectedTab.render()}
    </Page>
  );
};

Settings.propTypes = {
  tabsHeader: PropTypes.any
};
export default Settings;
