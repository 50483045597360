import SiteArticle from "../components/SiteArticles/SiteArticle";
import Layout from "./Layout/Layout";

const SiteArticleView = () => (
  <Layout>
    <SiteArticle />
  </Layout>
);

export default SiteArticleView;
