import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { classNames, handlePhobePress } from "../../../utility";
import Input from "../../layout/Input/Input";
import Select from "../../layout/Select";

const SendQuestionFAQForm = ({
  values,
  setValues,
  errors,
  validateForm,
  valuesFormik,
  setValuesFormik
}) => {
  const { t } = useTranslation();
  const typeQuestions = [
    {
      id: "paidAdvertisingOnTheSite",
      title: t("contactQuestions.paidAdvertisingOnTheSite"),
      value: "paidAdvertisingOnTheSite"
    },
    {
      id: "registerOrDelete",
      title: t("contactQuestions.registerOrDelete"),
      value: "registerOrDelete"
    },
    {
      id: "complain",
      title: t("contactQuestions.complain"),
      value: "complain"
    },
    {
      id: "notificationSubscription",
      title: t("contactQuestions.notificationSubscription"),
      value: "notificationSubscription"
    },
    {
      id: "cooperation",
      title: t("contactQuestions.cooperation"),
      value: "cooperation"
    },
    {
      id: "other",
      title: t("contactQuestions.other"),
      value: "other"
    }
  ];

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="mb-4">
          <Select
            value={values.typeQuestion}
            options={typeQuestions}
            id="typeQuestion"
            label={`*${t("contacts.selectTheSubjectOfYourMessage")}`}
            placeholder={t("contactQuestions.chooseTheThemeOfTheLetter")}
            onChange={async (val) => {
              await setValues({ ...values, typeQuestion: val });
              validateForm();
            }}
            error={errors?.typeQuestion}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="inputMessage" className="form-label">
            {t("contacts.message")}
          </label>
          <textarea
            value={values?.description}
            onChange={(e) => {
              validateForm();
              setValues({ ...values, description: e.target.value });
            }}
            placeholder={t("createPost.min50")}
            className={classNames(
              "form-control",
              errors?.description ? "is-invalid" : ""
            )}
            id="inputMessage"
          ></textarea>
        </div>
        <div className="mb-4">
          <Input
            type="text"
            label={t("contacts.insertLinkToThePageYouAreInterestedIn")}
            name="link"
            id="inputLink"
          />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mb-4">
          <Input
            type="text"
            name="email"
            label={t("contacts.email")}
            id="inputЕmail"
            maxLength="50"
            required="1"
            error={errors?.email}
          />
        </div>
        <div className="mb-4">
          <Input
            type="text"
            label={t("contacts.name")}
            name="name"
            id="inputName"
            maxLength="80"
            error={errors?.name}
          />
        </div>
        <div className="mb-4">
          <Input
            type="text"
            label={t("contacts.phone")}
            name="phone"
            id="inputPhone"
            maxLength="13"
            error={errors?.phone}
            onKeyUp={handlePhobePress({ valuesFormik, setValuesFormik })}
          />
        </div>
        <div className="mb-2">
          <div className="form-check form-check-inline">
            <Input
              name="viber"
              type="checkbox"
              label={t("Viber")}
              id="inlineCheckbox1"
              value="option1"
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              label={t("Telegram")}
              name="telegram"
              type="checkbox"
              id="inlineCheckbox2"
              value="option2"
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              label={t("WhatsApp")}
              name="whatsApp"
              type="checkbox"
              id="inlineCheckbox3"
              value="option3"
            />
          </div>
        </div>
      </div>
      <div className="col-12 text-lg-end">
        <button className="btn btn-primary" layout="primary" type="submit">
          {t("contacts.send")}
        </button>
      </div>
    </div>
  );
};

SendQuestionFAQForm.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  errors: PropTypes.object,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};
export default SendQuestionFAQForm;
