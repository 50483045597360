/* eslint-disable complexity */
/* eslint-disable max-statements */
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { postRentPropertyCategorys, postTypes } from "../../../../graphql/enum";
import { classNames, priorityToArray } from "../../../../utility";
import IsPermisionEdit from "./IsPermisionEdit";

const getTypeText = (post, t) => {
  const { type } = post;
  const postRentPropertyCategoryTitle =
    post?.rentProperty?.postRentPropertyCategory?.title;

  switch (type) {
    case postTypes.announcements:
      return t("servicesBusiness");
    case postTypes.offerVacancy:
      return t(type);
    case postTypes.lookingJob:
      return t(type);
    case postTypes.rentProperty:
      if (postRentPropertyCategoryTitle) {
        switch (postRentPropertyCategoryTitle) {
          case postRentPropertyCategorys.realEstateExchange:
            return t("postRentPropertyCategoryMinType.iAmLookingForHousing");
          case postRentPropertyCategorys.rentalOfProperty:
            return t("postRentPropertyCategoryMinType.rental");
          // case postRentPropertyCategorys.iWillBuyRealEstate:
          //   return t("postRentPropertyCategoryMinType.iWillBuyHousing");
          // case postRentPropertyCategorys.sellingRealEstate:
          //   return t("postRentPropertyCategoryMinType.iWillSellHousing");
          default:
            return t(type);
        }
      }
      return t(type);
    case postTypes.carrier:
      return t(type);
    default:
      return "";
  }
};

const PostItem = ({ isPremium, post, user, refetch, posts, setPosts }) => {
  const { t } = useTranslation();
  const [isShowAction, setIsShowAction] = useState(false);

  return (
    <div
      className={classNames(
        "works-item",
        isShowAction ? "active" : null,
        isPremium ? "works-item-top" : null
      )}
    >
      <IsPermisionEdit
        post={post}
        user={user}
        refetch={refetch}
        posts={posts}
        setPosts={setPosts}
        setIsShowAction={setIsShowAction}
      >
        <div className="works-item-class">{getTypeText(post, t)}</div>
        <div className="works-item-title">{post.title}</div>
        <div className="works-item-city">
          {post?.city?.title
            ? t(`city.${post?.city?.title}`)
            : post?.carrier?.cityDeparture || ""}
        </div>
        <div className="works-item-date">
          {isPremium ? (
            <p className="works-stars">
              {priorityToArray(post?.priority).map(() => (
                <span />
              ))}
            </p>
          ) : (
            moment(post.expireInPriorityDate).format("DD.MM.YYYY")
          )}
        </div>
      </IsPermisionEdit>
    </div>
  );
};

PostItem.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    seo: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    priority: PropTypes.number,
    carrier: PropTypes.shape({
      cityDeparture: PropTypes.string
    }),
    city: PropTypes.shape({
      title: PropTypes.string
    }),
    expireInPriorityDate: PropTypes.string
  }),
  isPremium: PropTypes.bool,
  user: PropTypes.object,
  refetch: PropTypes.func,
  posts: PropTypes.array,
  setPosts: PropTypes.func
};

PostItem.defaultProps = {
  isPremium: false
};
export default PostItem;
