/* eslint-disable max-lines */
import { gql } from "@apollo/client";

export const postQuery = gql`
  query post($postId: ID, $code: String) {
    post(postId: $postId, code: $code) {
      id
      title
      description
      type
      linkYouTube
      isAgency
      createdAt
      isShow
      seo
      isSendToEmail
      postIndexIds
      postIndexs {
        id
        title
      }
      images {
        id
        name
        size
        src
      }
      addressMap {
        title
        lat
        lng
      }
      user {
        name
        email
        phone
        isViber
        isTelegram
        isWhatsapp
      }
      announcement {
        postId
        cityId
        postAnnouncementRubricId
        postAnnouncementCategoryId
        postAnnouncementCategory {
          id
          title
        }
        postAnnouncementRubric {
          id
          title
        }
        city {
          id
          title
        }
      }
      offerVacancy {
        postId
        cityId
        categoryProfessionId
        isWorkForAMarriedCouple
        isYouCanWorkOnWeekends
        isUTR
        isDriverLicense
        isNationalInsurance
        isTools
        isCar
        paymentPeriod {
          id
          title
        }
        paymentPeriodId
        workSchedule
        isWorkForMinors
        isAssistantJob
        sum
        city {
          id
          title
        }
        professions {
          id
          title
        }
        categoryProfession {
          id
          title
        }
      }
      lookingJob {
        postId
        cityId
        categoryProfessionId
        isWorkForAMarriedCouple
        isYouCanWorkOnWeekends
        isAssistantJob
        isLookingJobMinors
        isUTR
        isDriverLicense
        isNationalInsurance
        isTools
        isCar
        city {
          id
          title
        }
        professions {
          id
          title
        }
        categoryProfession {
          id
          title
        }
      }
      rentProperty {
        postId
        cityId
        numberOfRooms
        numberOfBathroom
        isCarParking
        isGarden
        isUtilitiesIncluded
        isBicycleSpace
        isPropertyForSale
        isWifi
        price
        rentalPeriodId
        typeRooms {
          id
          title
        }
        postRentPropertyCategoryId
        postRentPropertyCategory {
          id
          title
        }
        city {
          id
          title
        }
        rentalPeriod {
          id
          title
        }
      }
      carrier {
        dateDeparture
        cityAlongRoute
        carrierCountryArrivalId
        carrierCountryDepartureId
        cityArrival
        cityDeparture
        countryAlongRouteIds
        countryAlongRoute {
          id
          title
        }
        carrierCountryArrival {
          id
          title
        }
        carrierCountryDeparture {
          id
          title
        }
      }
    }
  }
`;

export default {};
