import { gql } from "@apollo/client";

const showPostByCode = gql`
  mutation showPostByCode($code: String!) {
    showPostByCode(code: $code) {
      success
      code
      postId
    }
  }
`;

export default showPostByCode;
