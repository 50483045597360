/* eslint-disable i18next/no-literal-string */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { contactsUrl, homeUrl } from "../../routes";
import { socialNetworks, telegramGropUrls } from "../../utility/constants";
import Page from "../layout/Page";
import SendQuestionContacts from "./SendQuestionContacts/SendQuestionContacts";

const Contacts = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "contacts",
      title: t("foter.contacts"),
      link: contactsUrl
    }
  ];
  return (
    <Page
      pageName={t("foter.contacts")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="page-section">
        <ul className="about-contacts">
          <li className="about-contacts-item-address">
            <div>
              <p>
                286 High Rd Leyton, <br />
                London, E10 5PW
              </p>
            </div>
          </li>
          <li className="about-contacts-item-soc">
            <div>
              <p>
                {t("createPostThirdStage.subscribeToOurGroups")}
                <br /> {t("createPostThirdStage.ourGroups")}
                <br /> {t("createPostThirdStage.inSocialNetworks")}
              </p>
            </div>
            <ul className="soc-links soc-links-about-color">
              <li className="soc-links-instagram">
                <a
                  href={socialNetworks.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </li>
              <li className="soc-links-facebook">
                <a
                  href={socialNetworks.facebook}
                  target="_blank"
                  aria-label={t("modal.socialMedia")}
                  rel="noreferrer"
                ></a>
              </li>
              <li className="soc-links-telegram">
                <a
                  href={telegramGropUrls.announcements}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Telegram announcements"
                />
              </li>
              <li className="soc-links-whatsapp">
                <a
                  href={socialNetworks.whatsapp}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </li>
              <li className="soc-links-viber">
                <a
                  href={socialNetworks.viber}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="viber"
                />
              </li>
            </ul>
          </li>
          <li className="about-contacts-item-phone">
            <div>
              <p>
                <a href="tel:+447599683751">+447 599 683 751</a>
              </p>
              <ul className="soc-links soc-links-about">
                <li className="soc-links-viber">
                  <a
                    href={socialNetworks.viber}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="viber"
                  />
                </li>
                <li className="soc-links-whatsapp">
                  <a
                    href={socialNetworks.whatsapp}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                  </a>
                </li>
                <li className="soc-links-telegram">
                  <a
                    href={telegramGropUrls.announcements}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Telegram announcements"
                  />
                </li>
              </ul>
            </div>
            <p className="about-contacts-item-phone-mail">
              <a href="mailto:emigrant.center.mail@gmail.com">
                emigrant.center.mail@gmail.com
              </a>
            </p>
          </li>
          <li className="about-contacts-item-team">
            <div>
              <p>Ruslan Smac</p>
            </div>
          </li>
        </ul>
      </div>
      <SendQuestionContacts />
    </Page>
  );
};

Contacts.propTypes = {
  filter: PropTypes.object
};
export default Contacts;
