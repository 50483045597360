import { useTranslation } from "react-i18next";

const MinLength = () => {
  const { t } = useTranslation();
  return {
    min10: (
      <small>
        ({t("createPost.in")} <span style={{ color: "red" }}>10</span>{" "}
        {t("createPost.symbols")} )
      </small>
    ),
    min20: (
      <small>
        ({t("createPost.in")} <span style={{ color: "red" }}>20</span>{" "}
        {t("createPost.symbols")} )
      </small>
    ),
    min50: (
      <small>
        ({t("createPost.in")} <span style={{ color: "red" }}>20</span>{" "}
        {t("createPost.symbols")} )
      </small>
    ),
    getMin: (min, value) => (
      <small>
        ({t("createPost.in")}{" "}
        <span style={{ color: "red" }}>
          {value.replace(/\s/g, "").length < min
            ? min - value.replace(/\s/g, "").length
            : 0}
        </span>{" "}
        {t("createPost.symbols")} )
      </small>
    )
  };
};
export default MinLength;
