/* eslint-disable i18next/no-literal-string */
/* eslint-disable max-lines */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { numberOfRooms } from "../../../../graphql/enum";
import { rentUrl } from "../../../../routes";
import {
  isSelectedAllPostCode,
  isSelectLondon,
  setNewFilters
} from "../../../../utility";
import Input from "../../../layout/Input/Input";
import Link from "../../../layout/Link";
import Loading from "../../../layout/Loading/Loading";
import Radio from "../../../layout/Radio/Radio";
import SideBarDropdown from "../../../layout/SideBarDropdown/SideBarDropdown";

export const allDataQuery = gql`
  query allData {
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
    typeRooms {
      id
      title
    }
    rentalPeriods {
      id
      title
    }
    postRentPropertyCategory {
      id
      title
    }
  }
`;

const RentPropertySideBar = ({ filters, setFilters, totalPosts }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data } = useQuery(allDataQuery);
  if (loading) return <Loading />;
  if (!data) return null;
  const validate = (values) => {
    setNewFilters({
      filters,
      newFilters: values,
      setFilters,
      history,
      url: rentUrl
    });
    return {};
  };
  if (!filters.postRentPropertyCategoryId) {
    setFilters({
      ...filters,
      postRentPropertyCategoryId: data.postRentPropertyCategory[0].id
    });
  }
  return (
    <>
      <Formik initialValues={filters} validate={validate} onSubmit={() => {}}>
        {({ setValues, values }) => (
          <>
            <div className="sidebar-block">
              <h6 className="sidebar-title sidebar-title-clear">
                <span>{t("filter")}</span>
                <Link
                  to="#"
                  onClick={async () => {
                    const emptyData = {
                      postIndexs: [],
                      typeRooms: [],
                      numberOfRoom: [],
                      announcementFromAgencies: [],
                      privateAds: [],
                      postRentPropertyCategoryIds: [],
                      rentalPeriods: [],
                      garden: [],
                      numberOfBathroom: [],
                      search: undefined,
                      carParking: [],
                      isBicycleSpace: [],
                      isPropertyForSale: [],
                      isWifi: [],
                      utilitiesIncluded: [],
                      isAgecy: [],
                      currentPage: 1
                    };

                    await setFilters(emptyData);
                    setValues(emptyData);
                  }}
                >
                  {t("sideBarFilter.cleanItUp")}
                </Link>
              </h6>
              <Form>
                <SideBarDropdown title={t("sideBarFilter.categoryRent")}>
                  {data.postRentPropertyCategory.map((x) => (
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        label={t(`postRentPropertyCategory.${x.title}`)}
                        key={t(`postRentPropertyCategory.${x.id}`)}
                        id={`postRentPropertyCategory=${x.id}`}
                        value={x.id}
                        name={`postRentPropertyCategoryIds`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown title={t("sideBarFilter.housingType")}>
                  {data.typeRooms.map((x) => (
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        label={t(`typeRoom.${x.title}`)}
                        key={t(`typeRooms.${x.id}`)}
                        id={`typeRooms=${x.id}`}
                        value={x.id}
                        name={`typeRooms`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown title={t("sideBarFilter.cityInEngland")}>
                  <Radio
                    key="input-checbox"
                    options={data.cities.map((c) => ({
                      id: c.id,
                      value: c.id,
                      title: t(`city.${c.title}`)
                    }))}
                    name="cityInEngland"
                    value={filters?.cityId}
                    isAddDiv={true}
                    onClick={(val) =>
                      setValues({
                        ...values,
                        cityId: val,
                        postIndexs: isSelectLondon(filters.cityId, data.cities)
                          ? values.postIndexs
                          : []
                      })
                    }
                  />
                </SideBarDropdown>
                {isSelectLondon(values.cityId, data.cities) ? (
                  <SideBarDropdown title={t("sideBarFilter.londonPostcodes")}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="allLondon"
                        checked={
                          !values.postIndexs.length ||
                          isSelectedAllPostCode(
                            data.postIndexs
                              .filter((x) => values.postIndexs.includes(x.id))
                              .map((x) => x.title)
                          )
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValues({
                              ...values,
                              postIndexs: data.postIndexs.map((x) => x.id)
                            });
                          } else {
                            setValues({
                              ...values,
                              postIndexs: []
                            });
                          }
                        }}
                      />
                      <label htmlFor="allLondon">
                        {t("sideBarFilter.allLondon")}
                      </label>
                    </div>
                    {data.postIndexs.map((x) => (
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          label={t(`postIndexs.${x.title}`)}
                          key={t(`postIndexs.${x.id}`)}
                          id={`postCode=${x.id}`}
                          value={x.id}
                          name={`postIndexs`}
                        />
                      </div>
                    ))}
                  </SideBarDropdown>
                ) : null}
                <SideBarDropdown title={t("sideBarFilter.numberOfRoom")}>
                  <div className="form-check-inline-wrap">
                    {numberOfRooms.map((x) => (
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          label={t(`${x.title}`)}
                          key={t(`numberOfRoom.${x.id}`)}
                          id={`numberOfRoom=${x.id}`}
                          value={`${x.id}`}
                          name={`numberOfRoom`}
                        />
                      </div>
                    ))}
                  </div>
                </SideBarDropdown>
                <div className="widget widget-class-additional show">
                  <div className="widget-content mt-0">
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        key={"announcementFromAgencies"}
                        value={"true"}
                        label={t("sideBarFilter.announcementFromAgencies")}
                        id={"announcementFromAgencies"}
                        name={`announcementFromAgencies`}
                      />
                    </div>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        key={"privateAds"}
                        value={"true"}
                        label={t("sideBarFilter.privateAds")}
                        id={"privateAds"}
                        name={`privateAds`}
                      />
                    </div>
                  </div>
                </div>
                <div className="widget widget-class-result show">
                  <p>
                    {totalPosts} {t("sideBarFilter.posts")}
                  </p>
                </div>
              </Form>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

RentPropertySideBar.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalPosts: PropTypes.number,
  maxPrice: PropTypes.number,
  typeActive: PropTypes.string
};

export default RentPropertySideBar;
