import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import SinglePostListOtherPosts from "./SinglePostListOtherPosts";

const SinglePostContacts = ({ post }) => {
  const posts = post.relatedToPost;
  const { t } = useTranslation();
  return (
    <>
      <SinglePostListOtherPosts
        posts={posts.otherPostsAuthor}
        title={t("otherPosts.otherPostsAuthor")}
        classnames="page-single-author"
      />
      <SinglePostListOtherPosts
        posts={posts.similarPosts}
        title={t("otherPosts.similarPosts")}
        classnames="page-single-advert-related"
      />
      <SinglePostListOtherPosts
        posts={posts.posts}
        title={t("otherPosts.posts")}
        classnames="page-single-advert-top"
      />
    </>
  );
};

SinglePostContacts.propTypes = {
  post: PropTypes.object
};
export default SinglePostContacts;
