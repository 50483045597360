/* eslint-disable no-restricted-properties */
/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import singleUpload from "../../../graphql/mutation/uploadImage";
import Hint from "../Hint";

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const UploadImageAndPreview = ({
  setFile,
  removeFile,
  imagesDefault = [],
  maxPhoto = 15
}) => {
  const { t } = useTranslation();
  const [uploadFile] = useMutation(singleUpload);
  const [isLodaderImage, setIsLoader] = useState(false);
  const [isAddetDefaultImages, setIsAddetDefaultImages] = useState(false);
  const [images, setImages] = useState([]);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (!isAddetDefaultImages)
      setImages(imagesDefault.map((i) => ({ ...i, imageId: i.id })));
    setIsAddetDefaultImages(true);
  }, [imagesDefault, isAddetDefaultImages]);

  const removeImage = (imageId) => {
    removeFile(imageId);
    const newImages = images.filter((i) => i.imageId !== imageId);
    setImages(newImages);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;
    const { name, size } = file;
    setIsLoader(true);
    const reader = new FileReader();

    reader.onload = async () => {
      const binaryStr = reader.result;
      setImages([...images, { name, size, image: binaryStr }]);

      const { data } = await uploadFile({
        variables: { file: binaryStr, size }
      });
      setFile(data.singleUpload.imageId);
      setImages([
        ...images,
        {
          name,
          size,
          image: binaryStr,
          imageId: data.singleUpload.imageId
        }
      ]);
      setIsLoader(false);
      return null;
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="col-12 mb-4">
        <label for="inputPhoto" className="form-label">
          {t("createPost.uploadAPhoto")}
          <Hint components={t("hint.uploadAPhoto")} />
          {isLodaderImage ? (
            <img
              src="/assets/img/loading-gif.gif"
              className="form-label-loading"
              alt="Loading"
            />
          ) : null}
        </label>
        <div className="form-field-images" style={{ zIndex: 0 }}>
          <div className="form-field-imagesNone">
            <span className="form-field-imagesNone__icon">
              <img src="images/cloud-upload.svg" alt="Cloud Upload" />
            </span>
            <p className="form-field-imagesNone__title">
              {t("createPost.uploadAPhoto")}
            </p>
            <p className="form-field-imagesNone__text">
              {t("createPost.chooseAPhoto")}
            </p>
          </div>
          <div className="form-field-imagesList">
            <div className="form-field-imagesList-items">
              {images.map((image) => (
                <div className="form-field-imagesList-item">
                  <span
                    className="form-field-imagesList-item__close"
                    onClick={() => removeImage(image.imageId)}
                  ></span>
                  <div className="form-field-imagesList-item__image">
                    <img src={image.image || image.src} alt="" />
                  </div>
                  <p className="form-field-imagesList-item__title text-break">
                    {image.name}
                  </p>
                  <p className="form-field-imagesList-item__weight">
                    {formatBytes(image.size)}
                  </p>
                </div>
              ))}
              {!(images.length >= maxPhoto) && !isLodaderImage ? (
                <div
                  className="form-field-imagesList-item form-field-imagesList-item__add"
                  style={{ height: "162px" }}
                  onClick={handleClick}
                >
                  <span>+</span>
                  <p> {t("createPost.addPhoto")}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        onChange={(e) => handleFileChange(e)}
        ref={hiddenFileInput}
        style={{ display: "none" }}
        accept="image/png, image/jpeg, image/gif, image/tiff, image/bmp, image/webp"
      />
    </>
  );
};
UploadImageAndPreview.propTypes = {
  setFile: PropTypes.func,
  setIsLoader: PropTypes.func,
  removeFile: PropTypes.func,
  title: PropTypes.string,
  imagesDefault: PropTypes.array,
  maxPhoto: PropTypes.number
};
export default UploadImageAndPreview;
