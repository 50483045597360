/* eslint-disable i18next/no-literal-string */
import PropTypes from "prop-types";

import {
  adminImportBlackListUrl,
  adminSettingTelegramChannelUrl
} from "../../../routes";
import LinkView from "../../layout/Link";
import Page from "../../layout/Page";

const CategoryWithTelegramChannel = () => (
  <Page pageName={"List Links Admin"} isClassNamePageSingle={false}>
    <div>
      <LinkView
        style={{ color: "#006289" }}
        to={adminSettingTelegramChannelUrl}
      >
        Налаштування qr code телеграму
      </LinkView>
    </div>
    <div>
      <LinkView style={{ color: "#006289" }} to={adminImportBlackListUrl}>
        Імпортувати чорний список
      </LinkView>
    </div>
  </Page>
);

CategoryWithTelegramChannel.propTypes = {
  filter: PropTypes.object
};
export default CategoryWithTelegramChannel;
