import { gql } from "@apollo/client";
import PropTypes from "prop-types";

import { classNames } from "../../../../../utility";

export const allDataQuery = gql`
  query allData {
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
  }
`;

const CloseLayout = ({ title, children }) => (
  <div className="row mb-4">
    <div className="col-lg-12 mb-2">
      <h5>{title}</h5>
    </div>
    <div className="col-lg-9 offset-lg-3">
      <div className={classNames("dynamical-fields")}>{children}</div>
    </div>
  </div>
);

CloseLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any
};
export default CloseLayout;
