import PropTypes from "prop-types";

import CityAndPostCode from "./Fields/CityAndPostCode";
import Description from "./Fields/Description";
import Email from "./Fields/Email";
import Messages from "./Fields/Messages";
import Phone from "./Fields/Phone";
import Title from "./Fields/Title";

export const fieldsAddPostTypes = {
  title: "title",
  description: "description",
  messages: "messages",
  phone: "phone",
  cityAndPostCode: "cityAndPostCode",
  email: "email"
};

const FieldsAddPost = ({
  errors,
  data,
  validateForm,
  setValues,
  values,
  type,
  height,
  valuesFormik,
  setValuesFormik,
  isMultiSelect = false
}) => {
  if (type === fieldsAddPostTypes.title)
    return <Title errors={errors} value={valuesFormik?.title || ""} />;
  if (type === fieldsAddPostTypes.description)
    return <Description {...{ errors, validateForm, setValues, values }} />;
  if (type === fieldsAddPostTypes.messages) return <Messages height={height} />;
  if (type === fieldsAddPostTypes.phone)
    return (
      <Phone
        errors={errors}
        setValuesFormik={setValuesFormik}
        valuesFormik={valuesFormik}
        value={valuesFormik?.phone || ""}
      />
    );
  if (type === fieldsAddPostTypes.email)
    return <Email errors={errors} value={valuesFormik?.email || ""} />;
  if (type === fieldsAddPostTypes.cityAndPostCode)
    return (
      <CityAndPostCode
        {...{ values, setValues, errors, validateForm, data, isMultiSelect }}
      />
    );
  return null;
};

FieldsAddPost.propTypes = {
  errors: PropTypes.object,
  data: PropTypes.object,
  valuesFormik: PropTypes.object,
  height: PropTypes.string,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  setValuesFormik: PropTypes.func,
  values: PropTypes.shape({
    description: PropTypes.string
  }),
  type: PropTypes.string.isRequired,
  isMultiSelect: PropTypes.object
};
export default FieldsAddPost;
