/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable react/prop-types */
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { classNames } from "../../utility";
import { lang } from "../../utility/config";
import { UserContext } from "../../utility/context/User";

const LanguageSelect = ({
  isShow,
  setIsShow,
  classname,
  setIsShowNoLangGB,
  setIsShowNoLangRO,
  setIsShowNoLangUA
}) => {
  const { updateUser, user: userContext } = useContext(UserContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const curentLocation = `${location.pathname + location.search}`;

  const changeLanguage = (langNew) => {
    updateUser({ lang: langNew });
  };
  if (i18n.resolvedLanguage !== userContext.lang) {
    setTimeout(() => i18n.changeLanguage(userContext.lang), 5);
  }
  return (
    <>
      <ul className={classNames("language-switcher-list", classname)}>
        <li
          className={isShow ? "active" : ""}
          onClick={() => setIsShow(!isShow)}
        >
          {userContext.lang === lang.en ? (
            <Link to={curentLocation} onClick={() => {}}>
              <img src="/assets/img/flag/uk.svg" alt="United Kingdom" />
            </Link>
          ) : null}
          {userContext.lang === lang.ua ? (
            <Link to={curentLocation} onClick={() => {}}>
              <img src="/assets/img/flag/ua.svg" alt="Ukraine" />
            </Link>
          ) : null}

          {userContext.lang === lang.ro ? (
            <Link to={curentLocation} onClick={() => {}}>
              <img src="/assets/img/flag/ro.svg" alt="Romania" />
            </Link>
          ) : null}

          {userContext.lang === lang.rus ? (
            <Link to={curentLocation} onClick={() => {}}>
              <img src="/assets/img/flag/ru.png" alt="Russia" />
            </Link>
          ) : null}
          <ul>
            {userContext.lang !== lang.rus ? (
              <li>
                <Link
                  to={curentLocation}
                  onClick={() => changeLanguage(lang.rus)}
                >
                  <img src="/assets/img/flag/ru.png" alt="Russia" />
                  {t("country.russia")}
                </Link>
              </li>
            ) : null}{" "}
            {userContext.lang !== lang.ua ? (
              <li>
                {/* <Link to={curentLocation} onClick={() => {}}> */}
                <Link
                  to="#"
                  onClick={() => {
                    setIsShowNoLangUA(true);
                  }}
                >
                  <img src="/assets/img/flag/ua.svg" alt="Ukraine" />
                  {t("country.ukraine")}
                </Link>
              </li>
            ) : null}{" "}
            {userContext.lang !== lang.en ? (
              <li>
                {/* <Link
                  to={curentLocation}
                  onClick={() => changeLanguage(lang.en)}
                > */}
                <Link to="#" onClick={() => setIsShowNoLangGB(true)}>
                  <img src="/assets/img/flag/uk.svg" alt="United Kingdom" />
                  {t("country.unitedKingdom")}
                </Link>
              </li>
            ) : null}
            {userContext.lang !== lang.ro ? (
              <li>
                {/* <Link to={curentLocation} onClick={() => {}}> */}
                <Link to="#" onClick={() => setIsShowNoLangRO(true)}>
                  <img src="/assets/img/flag/ro.svg" alt="Romania" />
                  {t("country.romania")}
                </Link>
              </li>
            ) : null}
          </ul>
        </li>
      </ul>
      <div
        style={{
          left: 0,
          top: 0,
          zIndex: 88,
          width: "99vw",
          height: "100vh",
          display: isShow ? "block" : "none"
        }}
        className="position-absolute"
        onClick={() => setIsShow(false)}
      ></div>
    </>
  );
};

export default LanguageSelect;
