import ComplaintSentBlackList from "../components/BlackList/ComplaintSentBlackList";
import Layout from "./Layout/Layout";

const ComplaintSentBlackListView = () => (
  <Layout>
    <ComplaintSentBlackList />
  </Layout>
);

export default ComplaintSentBlackListView;
