/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { postTypes } from "../../../graphql/enum";
import Page from "../../layout/Page";
import TabsPost from "../TabsPost/TabsPost";
import OfferVacancyTable from "./OfferVacancyTable/OfferVacancyTable";
import Sidebar from "./SideBar";

const OfferVacancy = () => {
  const { t } = useTranslation();
  const maxSum = 999;

  const parameters = new URLSearchParams(window.location.search);
  const cityId = parameters.getAll("cityId")[0];
  const postIndexs = parameters.getAll("postIndexs");
  const professionsId = parameters.getAll("professionsId");
  const findOfferForFamily = parameters.getAll("findOfferForFamily");
  const iWorkOnWeekends = parameters.getAll("iWorkOnWeekends");
  const isWorkForMinors = parameters.getAll("isWorkForMinors");
  const isAssistantJob = parameters.getAll("isAssistantJob");
  const womenCanAlsoWork = parameters.getAll("womenCanAlsoWork");
  const sum = parameters.getAll("sum");
  const announcementFromAgencies = parameters.getAll(
    "announcementFromAgencies"
  );
  const categoryProfessionId = parameters.getAll("categoryProfessionId");
  const privateAds = parameters.getAll("privateAds");
  const search = parameters.getAll("search")[0];
  const currentPage = parameters.getAll("currentPage")[0];

  const defaultFilter = {
    cityId,
    postIndexs,
    professionsId,
    findOfferForFamily,
    iWorkOnWeekends,
    isAssistantJob,
    isWorkForMinors,
    womenCanAlsoWork,
    announcementFromAgencies,
    privateAds,
    sum: sum.length ? sum : [0, maxSum],
    search,
    categoryProfessionId,
    currentPage: parseInt(currentPage, 10) || 1
  };
  const [filters, setFilters] = useState(defaultFilter);
  const [totalPosts, setTotalPosts] = useState(0);

  return (
    <Page
      sidebar={{
        mobileTextButton: t("filter"),
        render: () => (
          <Sidebar
            filters={filters}
            setFilters={setFilters}
            totalPosts={totalPosts}
            maxSum={maxSum}
            typeActive={postTypes.offerVacancy}
          />
        ),
        after: () => (
          <p className="works-count d-lg-none">{`${t(
            "post.totalPosts"
          )} ${totalPosts}`}</p>
        )
      }}
      beforeContent={
        <TabsPost
          typeActive={postTypes.offerVacancy}
          classname="type-menu-horizontal"
        />
      }
    >
      <OfferVacancyTable
        {...{
          filters,
          setTotalPosts,
          currentPage: filters.currentPage,
          setCurrentPage: (newPage) => {
            setFilters({ ...filters, currentPage: newPage });
          }
        }}
      />
    </Page>
  );
};

OfferVacancy.propTypes = {
  filter: PropTypes.object
};
export default OfferVacancy;
