import { gql } from "@apollo/client";

const createBlackList = gql`
  mutation createBlackList($input: CreateBlackListInput) {
    createBlackList(input: $input) {
      success
      code
      accessCode
    }
  }
`;

export default createBlackList;
