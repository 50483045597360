/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Field } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import singleUpload from "../../../graphql/mutation/uploadImage";

const UploadDocument = ({ setFile, label, deleteFile }) => {
  const fileInput = useRef(null);

  const [uploadFile] = useMutation(singleUpload);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = async () => {
      const binaryStr = reader.result;
      const { data } = await uploadFile({ variables: { file: binaryStr } });
      setFile(data.singleUpload.imageId);
      return null;
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <label className="form-label form-label-flex">
        {label}
        {fileInput?.current?.value ? (
          <button
            class="form-field-file-remove"
            onClick={() => {
              fileInput.current.value = "";
              deleteFile();
            }}
          />
        ) : null}
      </label>
      <Field
        type="file"
        onChange={(e) => handleFileChange(e)}
        className="form-control"
        name="files"
        innerRef={fileInput}
      />
    </>
  );
};
UploadDocument.propTypes = {
  setFile: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  deleteFile: PropTypes.func
};
export default UploadDocument;
