import PropTypes from "prop-types";
import { useState } from "react";

const Content = ({
  content,
  sidebar,
  isClassNamePageSingle,
  beforeSideBarInContent
}) => {
  const [isShow, setIsShow] = useState(false);
  return sidebar !== null ? (
    <div className={`page-works ${isShow ? "sidebar-active" : null}`}>
      {beforeSideBarInContent}
      <button
        className="sidebar-filter-btn d-lg-none"
        onClick={() => setIsShow(!isShow)}
      >
        <span className={sidebar.classNameMobileTextButton || ""}>
          {sidebar.mobileTextButton}
        </span>
      </button>
      <div className="sidebar">{sidebar.render()}</div>
      {sidebar.after ? sidebar?.after() : null}
      <div className="works-inner">{content}</div>
    </div>
  ) : (
    <div className={isClassNamePageSingle ? "page-single" : ""}>{content}</div>
  );
};
Content.propTypes = {
  content: PropTypes.any,
  sidebar: PropTypes.object,
  isClassNamePageSingle: PropTypes.bool,
  beforeSideBarInContent: PropTypes.any
};

Content.defaultProps = {
  sidebar: null,
  isClassNamePageSingle: true
};
export default Content;
