import QuestionnaireCreate from "../components/Questionnaire/QuestionnaireCreate";
import Layout from "./Layout/Layout";

const PermitWorkView = () => (
  <Layout>
    <QuestionnaireCreate />
  </Layout>
);

export default PermitWorkView;
