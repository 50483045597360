/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import Loading from "../../components/layout/Loading/Loading";
import { UserContext } from "../../utility/context/User";
import { loginUrl, myOfficeUrl } from "..";

export const AuthorizedRouteRedirect = ({ children }) => {
  const { user } = useContext(UserContext);

  return user?.id ? <Redirect to={myOfficeUrl} /> : children;
};
export const NonAuthorizedRouteRedirect = ({ children }) => {
  const { user } = useContext(UserContext);
  if (user.loading) return <Loading />;
  return user?.id ? children : <Redirect to={loginUrl} />;
};

export const ApproveInviteRedirect = ({ children }) => {
  const { user } = useContext(UserContext);
  return !user ? (
    <Redirect to={`/login?redirect_uri=${window.location.href}`} />
  ) : (
    children
  );
};

export const ApproveInviteRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <ApproveInviteRedirect>
        <Component {...routeRenderProps} />
      </ApproveInviteRedirect>
    )}
  />
);

export const PublicRouteRedirect = ({ children }) => children;

export const PublicAppRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <PublicRouteRedirect>
        <Component {...routeRenderProps} />
      </PublicRouteRedirect>
    )}
  />
);

export const NotAuthorizedAppRoute = ({
  component: Component,
  ...routeProps
}) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <NonAuthorizedRouteRedirect>
        <Component {...routeRenderProps} />
      </NonAuthorizedRouteRedirect>
    )}
  />
);
export const AuthorizedAppRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <AuthorizedRouteRedirect>
        <Component {...routeRenderProps} />
      </AuthorizedRouteRedirect>
    )}
  />
);
