import { gql } from "@apollo/client";

const createQuestionContact = gql`
  mutation createQuestionContact($input: CreateQuestionContactInput) {
    createQuestionContact(input: $input) {
      success
      code
    }
  }
`;

export default createQuestionContact;
