import PropTypes from "prop-types";

import ImageGalarey from "../../ImageGalarey";

const ImageGalareyPost = ({ images }) => <ImageGalarey images={images} />;

ImageGalareyPost.propTypes = {
  images: PropTypes.array
};
export default ImageGalareyPost;
