import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { classNames, getClassNameForInput } from "../../../utility";

const Select = ({
  disabled,
  options,
  value,
  onChange,
  id,
  label,
  error,
  classname,
  placeholder,
  isAddEmptyOption = true,
  isDisplayValid,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        {...rest}
        className={classNames(
          "form-select",
          classname,
          getClassNameForInput({ error, value, isDisplayValid })
        )}
        disabled={disabled}
        id={id}
        value={value || options[0]}
        aria-label="Default select example"
        onChange={(e) => onChange(e.target.value)}
      >
        {isAddEmptyOption ? (
          <option key="defaultVa" value="" selected>
            {placeholder || t("createPost.select")}
          </option>
        ) : null}
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </select>
    </>
  );
};
Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      title: PropTypes.any,
      value: PropTypes.any
    })
  ),
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.any,
  error: PropTypes.string,
  classname: PropTypes.string,
  disabled: PropTypes.bool,
  isAddEmptyOption: PropTypes.bool,
  placeholder: PropTypes.any,
  isDisplayValid: PropTypes.bool
};
Select.defaultProps = {
  options: [],
  disabled: false
};
export default Select;
