import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Maps from "../../layout/Maps";

const SinglePostMaps = ({ post }) => {
  const { t } = useTranslation();

  return (
    <div className="page-single-map page-single-wrap">
      <label className="page-single-label">{t("post.addressOnTheMap")}</label>
      <Maps coordinates={post.addressMap} />
    </div>
  );
};

SinglePostMaps.propTypes = {
  post: PropTypes.object
};
export default SinglePostMaps;
