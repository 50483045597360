import PropTypes from "prop-types";
import React, { useContext } from "react";

import { userRoles } from "../../../graphql/enum";
import { UserContext } from "../../../utility/context/User";

const AutorizeUser = ({
  notAutorizeComponent = null,
  autorizeComponent = null,
  isOnlyAdmin
}) => {
  const { user } = useContext(UserContext);

  const component = user?.id ? autorizeComponent : notAutorizeComponent;

  if (isOnlyAdmin && user?.role !== userRoles.admin) return null;

  return <>{component}</>;
};

AutorizeUser.propTypes = {
  notAutorizeComponent: PropTypes.any,
  autorizeComponent: PropTypes.any,
  isOnlyAdmin: PropTypes.bool
};

export default AutorizeUser;
