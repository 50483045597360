import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { classNames, handlePhobePress } from "../../../../utility";
import Hint from "../../../layout/Hint/Hint";
import Input from "../../../layout/Input/Input";
import UploadImage from "../../../layout/UploadImage/UploadImage";

const AddBlackListForm = ({
  values,
  setValues,
  errors,
  validateForm,
  valuesFormik,
  setValuesFormik
}) => {
  const { t } = useTranslation();

  return (
    <div className="section-add">
      <div className="row">
        <div className="col-lg-6">
          <h4>{t("blackList.companyOrIndividualData")}</h4>
          <div className="mb-4">
            <Input
              name="nameCompany"
              type="text"
              id="inputCompany"
              required="1"
              error={errors?.nameCompany}
              label={t("blackList.data")}
            />
          </div>
          <div className="mb-4">
            <Input
              name="phoneCompany"
              type="tel"
              id="inputPhone"
              required="1"
              maxLength="13"
              onKeyUp={handlePhobePress({ valuesFormik, setValuesFormik })}
              error={errors?.phoneCompany}
              placeholder="+"
              label={t("blackList.phoneNumberOfTheVompanyOrIndividual")}
            />
          </div>
          <div className="mb-4">
            <Input
              name="emailCompany"
              type="email"
              maxLength="50"
              id="inputEmail"
              label={t("blackList.emailOfTheVompanyOrIndividual")}
            />
          </div>
          <div className="row">
            <div className="col-lg-7 mb-4">
              <Input
                name="webSiteCompany"
                type="text"
                id="inputSite"
                label={t("blackList.webSite")}
              />
            </div>
            <div className="col-lg-5 mb-4">
              <UploadImage
                title={t("createPost.uploadAPhoto")}
                setFile={(fileId) =>
                  setValues({
                    ...values,
                    fileId
                  })
                }
                deleteFile={() => setValues({ ...values, fileId: null })}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <h4>{t("blackList.yourData")}</h4>
          <div className="mb-4">
            <Input
              name="name"
              type="text"
              id="inputName"
              required="1"
              error={errors?.name}
              label={t("blackList.yourName")}
            />
          </div>
          <div className="mb-4">
            <Input
              name="email"
              type="email"
              id="inputEmail"
              required="1"
              error={errors?.email}
              label={t("blackList.email")}
            />
          </div>
          <div className="mb-3">
            <Input
              name="phone"
              type="tel"
              id="inputPhone"
              required="1"
              maxLength="13"
              onKeyUp={handlePhobePress({ valuesFormik, setValuesFormik })}
              error={errors?.phone}
              placeholder="+"
              label={
                <>
                  {t("blackList.yourPhone")}
                  <Hint components={t("hint.phone")} />
                </>
              }
            />
          </div>
          <div className="mb-2">
            <div className="form-check form-check-inline">
              <Input
                name="isViber"
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                label={t("Viber")}
              />
            </div>
            <div className="form-check form-check-inline">
              <Input
                name="isTelegram"
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox2"
                label={t("Telegram")}
              />
            </div>
            <div className="form-check form-check-inline">
              <Input
                name="isWhatsapp"
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox3"
                label={t("WhatsApp")}
              />
            </div>
            <div className="form-check form-check-inline">
              <Input
                name="isAdmin"
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox4"
                label={t("blackList.addsAdmin")}
              />
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <label htmlFor="inputText" className="form-label">
            *{t("blackList.describeInDetailWhatTheFraudTookPlace")}
          </label>
          <textarea
            value={values?.description}
            onChange={(e) => {
              validateForm();
              setValues({ ...values, description: e.target.value });
            }}
            className={classNames(
              "form-control",
              errors?.description ? "is-invalid" : ""
            )}
            id="inputText"
            placeholder={t("createPost.min20")}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

AddBlackListForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};
export default AddBlackListForm;
