import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import signIn from "../../graphql/mutation/singIn";
import {
  aboutUrl,
  forgotPasswordUrl,
  homeUrl,
  registrationUrl
} from "../../routes";
import { UserContext } from "../../utility/context/User";
import Input from "../layout/Input/Input";
import LinkView from "../layout/Link";
import Page from "../layout/Page";

const About = () => {
  const { t } = useTranslation();
  const { login } = useContext(UserContext);
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "login",
      title: t("login.login"),
      link: aboutUrl
    }
  ];
  const [signInMutation] = useMutation(signIn);

  const validate = (valuesValidate) => {
    const errors = {};
    if (!valuesValidate.login) {
      errors.login = `validationErrors.required`;
    }
    if (!valuesValidate.password) {
      errors.password = `validationErrors.required`;
    }
    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    const { data } = await signInMutation({
      variables: {
        input: {
          login: valuesFormik.login,
          password: valuesFormik.password
        }
      }
    });
    if (data?.signIn.success) {
      await login(data.signIn.jwtToken);
      return;
    }
    setErrors({ password: "YTdthyj' bv" });
  };
  return (
    <Page
      pageName={t("login.login")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="account-form">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-md-6 col-xl-4">
            <h1 className="account-form-heading">
              {t("login.entranceToTheSystem")}
            </h1>
            <Formik
              initialValues={{
                login: "",
                password: ""
              }}
              onSubmit={onSave}
              validate={validate}
            >
              {({ errors }) => (
                <Form>
                  <div className="mb-3">
                    <Input
                      type="text"
                      label={t("login.nameOrEmail")}
                      id="login"
                      name="login"
                      required="1"
                      error={errors?.login}
                    />
                  </div>
                  <div className="mb-3">
                    <Input
                      type="text"
                      label={t("login.password")}
                      id="password"
                      name="password"
                      required="1"
                      error={errors?.password}
                    />
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-6 mb-3 mb-lg-0">
                      <LinkView
                        to={forgotPasswordUrl}
                        className="link-forgot-password"
                      >
                        {t("login.forgotPassword")}
                      </LinkView>
                    </div>
                    <div className="col-lg-6">
                      <button name="" className="btn btn-primary w-100">
                        {t("login.singIn")}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <p class="account-form-link-reg text-center">
              {t("login.notAccount")}{" "}
              <a href={registrationUrl}>
                <strong>{t("login.registration")}</strong>
              </a>
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
};

About.propTypes = {
  filter: PropTypes.object
};
export default About;
