import { gql } from "@apollo/client";

const createPayPost = gql`
  mutation createPayPost($code: String!, $input: CreatePayPostInput) {
    createPayPost(input: $input, code: $code) {
      success
      code
    }
  }
`;

export default createPayPost;
