import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { classNames } from "../../../utility";

/* eslint-disable max-statements */
const Pagination = ({ total, pageSize, currentPage, setCurrentPage }) => {
  const pagesPagination = [];
  const history = useHistory();
  const pages = Math.ceil(total / pageSize);
  const isPreviosArrow = currentPage - 1 > 0;
  const isNextArrow = currentPage + 1 <= pages;
  if (isPreviosArrow) {
    pagesPagination.push({
      page: currentPage - 1,
      text: "",
      className: "prev"
    });
  }
  const showPageLefrAndRight = 2;
  let startPage =
    currentPage - showPageLefrAndRight > 0
      ? currentPage - showPageLefrAndRight
      : 1;
  const endPage =
    startPage + 1 + showPageLefrAndRight * 2 < pages
      ? startPage + 1 + showPageLefrAndRight * 2
      : pages;
  while (startPage <= endPage) {
    pagesPagination.push({ page: startPage, text: startPage });
    startPage += 1;
  }

  if (endPage < pages) {
    pagesPagination.push({ text: "..." });
    pagesPagination.push({ page: pages, text: pages });
  }
  if (isNextArrow) {
    pagesPagination.push({
      page: currentPage + 1,
      text: "",
      className: "next"
    });
  }

  if (pagesPagination.length === 0) return null;

  return (
    <div className="pagination">
      {pagesPagination.map(({ page, text, className }, key) => (
        <li
          key={key}
          class={classNames(
            className,
            page === currentPage ? "active" : "",
            key !== 0 ? "ms-2" : ""
          )}
        >
          {page ? (
            <a
              href="#ClickPage"
              onClick={() => {
                const parameters = new URLSearchParams(window.location.search);
                parameters.set("currentPage", page);
                history.push({
                  pathname: window.location.pathname,
                  search: parameters.toString()
                });
                setCurrentPage(page);
              }}
            >
              {text}
            </a>
          ) : (
            <span>{text}</span>
          )}
        </li>
      ))}
    </div>
  );
};
Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired
};

export default Pagination;
