import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import LinkView from "../../../components/layout/Link";
import Modal from "../../../components/layout/Modal";
import { homeUrl } from "../../../routes";

const NoLangUA = (args) => {
  const { t } = useTranslation();
  return (
    <Modal {...args}>
      <div className="block-no-content__image text-center">
        <img
          src="/assets/img/flag-ua.svg"
          width="130"
          height="130"
          alt="У вас пока нет анкеты"
        />
      </div>
      <h2 className="block-no-content__title text-center">
        {t("modal.TheNightingaleSiteWillBeAvailableSoon")}
      </h2>
      <div className="block-no-content__button text-center">
        <LinkView to={homeUrl} className="btn btn-primary">
          {t("modal.toMain")}
        </LinkView>
      </div>
    </Modal>
  );
};
NoLangUA.propTypes = {
  otherData: PropTypes.object
};

export default NoLangUA;
