const developmentEnv = "development";

export const nodeEnv = process.env.REACT_APP_NODE_ENV || "production";

export const isDevelopmentEnv = nodeEnv === developmentEnv;

export const API_URL = process.env.REACT_APP_API_URL;
export const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const environment = process.env.REACT_APP_ENV || developmentEnv;

export const isDevelopment = environment === developmentEnv;

export const GRAPHQL_API_URL = `${API_URL}/graphql`;

export const GITHUB_AUTHORIZE_URL = `${API_URL}/github-authorize`;

export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const publicSite = process.env.REACT_APP_PUBLIC_SITE;

export const ftp = {
  host: process.env.FTP_HOST || "127.0.0.1",
  port: 21,
  user: process.env.FTP_USER || "root",
  password: process.env.FTP_PASSWORD || "123root123"
};
