import PrtopTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "../../../../../layout/Input/Input";

const Field = ({ height = "inherit" }) => {
  const { t } = useTranslation();

  return (
    <div className="form-check-inline-wrap" style={{ height: height || "" }}>
      <div className="form-check form-check-inline">
        <Input
          type="checkbox"
          id="inlineCheckbox1"
          name="viber"
          label={t("viber")}
        />
      </div>
      <div className="form-check form-check-inline">
        <Input
          type="checkbox"
          id="inlineCheckbox2"
          name="telegram"
          label={t("telegram")}
        />
      </div>
      <div className="form-check form-check-inline">
        <Input
          type="checkbox"
          label={t("whatsApp")}
          name="whatsApp"
          id="inlineCheckbox3"
        />
      </div>
    </div>
  );
};

Field.propTypes = {
  height: PrtopTypes.string
};
export default Field;
