import RentProperty from "../components/Posts/RentProperty";
import Layout from "./Layout/Layout";

const RentPropertyView = () => (
  <Layout>
    <RentProperty />
  </Layout>
);

export default RentPropertyView;
