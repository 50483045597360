/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getQuestionnaireUrl, homeUrl } from "../../../routes";
import { handleDownloadFromUrl } from "../../../utility";
import Loading from "../../layout/Loading/Loading";
import Page from "../../layout/Page";
import QuestionnaireFirst from "./QuestionnaireCreateFirst";

export const checkInsQuery = gql`
  query questionnaire($questionnaireId: ID!) {
    questionnaire(questionnaireId: $questionnaireId) {
      id
      name
      email
      phone
      age
      sex
      city {
        id
        title
      }
      resume {
        name
        src
      }
      note
      postIndexs {
        id
        title
      }
      professions {
        id
        title
      }
      categoryProfession {
        id
        title
      }
      isViber
      isTelegram
      isWhatsapp
      isYouCanWorkOnWeekends
      isWorkForAMarriedCouple
      isGotHheToolsToDoTheJob
      isNonSmoker
      isAssistantMaster
      isIHaveCar
      documentsForWorkInEngland
      constructionDocuments
      knowledgeOfEnglish
      driverLicense
      workExperience {
        placeOfWork
        workedByProfession {
          id
          title
        }
      }
      image {
        name
        src
      }
      education {
        education
        nameEducationalInstitution
      }
    }
  }
`;

const QuestionnaireSingle = () => {
  const { t } = useTranslation();

  const { questionnaireId } = useParams();
  const { loading, data } = useQuery(checkInsQuery, {
    variables: {
      questionnaireId
    }
  });

  if (loading) return <Loading />;
  if (!data?.questionnaire) return "NO DATA";

  const { questionnaire } = data;

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "questionnaireSingle",
      title: t("questionnaireSingle.myResume"),
      link: getQuestionnaireUrl(questionnaireId)
    }
  ];

  return (
    <Page
      pageName={t("questionnaireSingle.myResume")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="page-my-resume">
        <QuestionnaireFirst key="once_key" questionnaire={questionnaire} />
        <div className="section-my-resume">
          <div className="footer-my-resume">
            {questionnaire?.resume && (
              <div className="footer-col-my-resume">
                <div className="cv-my-resume">
                  <span
                    className="btn btn-secondary btn-icon btn-icon-small btn-icon-download"
                    onClick={() => handleDownloadFromUrl(questionnaire?.resume)}
                  >
                    {t("questionnaireSingle.downloadCv")}
                  </span>
                </div>
              </div>
            )}

            <div className="footer-col-my-resume">
              <div className="contacts-my-resume">
                {questionnaire.email && (
                  <p className="email-my-resume">
                    <label>{t("questionnaireSingle.email")}</label>
                    <a href={`mailto:${questionnaire.email}`}>
                      {questionnaire.email}
                    </a>
                  </p>
                )}
              </div>
            </div>
            <div className="footer-col-my-resume">
              <div className="contacts-my-resume contactsPhone-my-resume">
                <p className="phone-my-resume mb-3 mb-lg-0">
                  <label>{t("questionnaireSingle.phone")}</label>
                  <a href={`tel:${questionnaire.phone}`}>
                    {questionnaire.phone}
                  </a>
                </p>
                <ul className="soc-links page-resume-soc mb-3 mb-lg-0">
                  {questionnaire.isViber ? (
                    <li className="soc-links-viber">
                      <a
                        href={`viber://chat?number=${questionnaire.phone}`}
                        target="_blank"
                        rel="noreferrer"
                      />
                    </li>
                  ) : null}
                  {questionnaire.isWhatsapp ? (
                    <li className="soc-links-whatsapp">
                      <a
                        href={`https://wa.me/${questionnaire.phone}`}
                        target="_blank"
                        rel="noreferrer"
                      />
                    </li>
                  ) : null}
                  {questionnaire.isTelegram ? (
                    <li className="soc-links-telegram">
                      <a
                        href={`https://t.me/${questionnaire.phone}`}
                        target="_blank"
                        rel="noreferrer"
                      />
                    </li>
                  ) : null}
                </ul>
                <button
                  onClick={() => {
                    window.location.href = `tel:${questionnaire.phone}`;
                  }}
                  className="btn btn-primary btn-icon btn-icon-small btn-icon-phone"
                >
                  {t("questionnaireSingle.willCall")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

QuestionnaireSingle.propTypes = {};

QuestionnaireSingle.defaultProps = {};

export default QuestionnaireSingle;
