/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../layout/Input/Input";
import Loading from "../../../layout/Loading/Loading";
import Select from "../../../layout/Select";
import MultiSelect from "../../../layout/Select/MultiSelect";
import UploadDocument from "../../../layout/UploadDocument";

export const allDataQuery = gql`
  query allData {
    professions {
      id
      title
    }
    categoryProfession {
      id
      title
      professions {
        id
        title
      }
    }
  }
`;

const QuestionnaireCreateLookingBlock = ({
  values,
  setValues,
  validateForm,
  errors
}) => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(allDataQuery);
  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    if (!data?.categoryProfession) return;
    const categoryProfession = data?.categoryProfession.find(
      ({ id: categoryProfessionId }) =>
        categoryProfessionId === values?.categoryProfession
    );

    setProfessions(categoryProfession ? categoryProfession.professions : []);
    setValues({ ...values, profession: [] });
  }, [data?.categoryProfession, values?.categoryProfession]);

  if (loading) return <Loading />;

  return (
    <div className="section-add">
      <h4>{t("questionnaire.whatJobsAreYouLookingFor")}</h4>
      <div className="row">
        <div className="col-lg-6">
          <Select
            className="form-select"
            id="inputProfessionConstruction"
            key="inputProfessionConstruction"
            options={data.categoryProfession.map((p) => ({
              ...p,
              title: t(`categoryProfession.${p.title}`)
            }))}
            value={values.categoryProfession}
            onChange={async (val) => {
              await setValues({ ...values, categoryProfession: val });
              validateForm();
            }}
            aria-label="Default select example"
            label={<>*{t("createPost.categoryOfVacancies")}</>}
            error={errors?.categoryProfession}
          />
        </div>
        <div className="col-lg-6  mt-4 mt-md-5 mt-lg-0 ">
          <MultiSelect
            className="form-select"
            id="inputOtherProfession"
            key="inputOtherProfessions"
            options={professions.map((p) => ({
              ...p,
              title: t(`profession.${p.title}`)
            }))}
            value={values.professions}
            onChange={async (val) => {
              await setValues({ ...values, professions: val });
              validateForm();
            }}
            aria-label="Default select example"
            label={<>*{t("createPost.profession")}</>}
            error={errors?.professions}
          />
        </div>
        <div className="col-lg-12 mb-2">
          <label
            htmlFor="inputVacancieCheck"
            className="form-label d-none d-lg-block opacity-0"
          >
            {t("questionnaire.additionally")}
          </label>
          <div className="form-check form-check-inline mt-3">
            <Input
              label={t("questionnaire.onWeekends")}
              name="youCanWorkOnWeekends"
              type="checkbox"
              id="youCanWorkOnWeekends"
            />
          </div>
          <div className="form-check form-check-inline">
            <Input
              label={t("questionnaire.assistantMaster")}
              name="isAssistantMaster"
              type="checkbox"
              id="isAssistantMaster"
            />
          </div>
          <div className="form-check form-check-inline me-0">
            <Input
              label={t("questionnaire.forAMarriedCouple")}
              name="workForAMarriedCouple"
              type="checkbox"
              id="workForAMarriedCouple"
            />
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="input-group">
            <UploadDocument
              id="inputPhotoCV"
              label={t("createPost.uploadCV")}
              setFile={(fileId) =>
                setValues({
                  ...values,
                  fileId
                })
              }
              deleteFile={() => setValues({ ...values, fileId: null })}
            />
          </div>
          <small className="fw-normal mt-1">
            {t("post.fileFormats")} {"jpg, jpeg, doc, docx, pdf"}
          </small>
        </div>
      </div>
    </div>
  );
};

QuestionnaireCreateLookingBlock.propTypes = {
  values: PropTypes.shape({
    categoryProfession: PropTypes.string,
    professionConstruction: PropTypes.string,
    profession: PropTypes.string,
    professions: PropTypes.array
  }),
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  errors: PropTypes.object
};
export default QuestionnaireCreateLookingBlock;
