import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import Hint from "../../../../../layout/Hint";
import Input from "../../../../../layout/Input/Input";
import MinLength from "../../../../../layout/Input/MinLength";

const Field = ({ errors, value }) => {
  const { t } = useTranslation();
  const min = MinLength();
  return (
    <Input
      type="text"
      label={
        <>
          {t("createPost.title")} {min.getMin(10, value)}
          <Hint
            components={
              <Trans
                i18nKey="hint.title"
                components={{
                  bold: <strong />,
                  br: <br />,
                  span: <span className="text-danger" />
                }}
              />
            }
          />
        </>
      }
      id="inputTitle"
      placeholder={t("createPost.min10")}
      maxlength="80"
      name="title"
      required="1"
      value={value}
      error={errors?.title}
    />
  );
};

Field.propTypes = {
  errors: PropTypes.object,
  value: PropTypes.string
};
export default Field;
