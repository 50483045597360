import Carrier from "../components/Posts/Carrier";
import Layout from "./Layout/Layout";

const CarrierView = () => (
  <Layout>
    <Carrier />
  </Layout>
);

export default CarrierView;
