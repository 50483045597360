import Registration from "../components/Registration";
import Layout from "./Layout/Layout";

const RegistrationView = () => (
  <Layout>
    <Registration />
  </Layout>
);

export default RegistrationView;
