/* eslint-disable i18next/no-literal-string */
import PropTypes from "prop-types";

import { classNames } from "../../../../utility";

const SideBar = ({ tabs, setSelectedTab, selectedTab }) => (
  <div className="sidebar-block">
    <div className="widget">
      <ul className="type-menu">
        {tabs.map((tab) => (
          <li key={tab.id}>
            <button
              onClick={() => {
                setSelectedTab(tab);
              }}
              className={classNames(
                "btn btn-outline-primary w-100",
                tab.id === selectedTab.id ? "active" : ""
              )}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

SideBar.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.object,
  setSelectedTab: PropTypes.func
};

export default SideBar;
