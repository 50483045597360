import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import authorizationEmailCode from "../../graphql/mutation/authorizationEmailCode";
import { UserContext } from "../../utility/context/User";
import Loading from "../layout/Loading/Loading";
import Page from "../layout/Page";

const AuthorizationEmail = () => {
  const { t } = useTranslation();
  const { login } = useContext(UserContext);

  const parameters = new URLSearchParams(window.location.search);
  const code = parameters.get("code");

  const [authorizationEmailCodeMutation] = useMutation(authorizationEmailCode);
  useEffect(() => {
    if (!code) return null;
    const run = async () => {
      const { data } = await authorizationEmailCodeMutation({
        variables: {
          code
        }
      });
      if (data?.authorizationEmailCode.success) {
        await login(data.authorizationEmailCode.jwtToken);
      }
      return null;
    };
    run();
    return null;
  });

  return (
    <Page pageName={t("Autorize")} isClassNamePageSingle={false}>
      <Loading />
    </Page>
  );
};

AuthorizationEmail.propTypes = {
  filter: PropTypes.object
};
export default AuthorizationEmail;
