import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import restorePassword from "../../graphql/mutation/restorePassword";
import { homeUrl, restorePasswordUrl } from "../../routes";
import Input from "../layout/Input/Input";
import Page from "../layout/Page";

const RestorePassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const parameters = new URLSearchParams(window.location.search);
  const code = parameters.get("code");

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "restorePassword",
      title: t("restorePassword.restorePassword"),
      link: restorePasswordUrl
    }
  ];
  const [restorePasswordMutation] = useMutation(restorePassword);

  const validate = (valuesValidate) => {
    const errors = {};
    if (!valuesValidate.password) {
      errors.password = `validationErrors.required`;
    }
    if (valuesValidate.password.length < 7) {
      errors.password = `validationErrors.required`;
    }
    if (!valuesValidate.repeatPassword) {
      errors.repeatPassword = `validationErrors.required`;
    }
    if (valuesValidate.repeatPassword !== valuesValidate.password) {
      errors.repeatPassword = `validationErrors.required`;
    }
    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    const { data } = await restorePasswordMutation({
      variables: {
        code,
        input: {
          password: valuesFormik.password
        }
      }
    });
    if (data?.restorePassword.success) {
      const url = queryString.stringifyUrl({
        url: homeUrl,
        query: {}
      });
      history.push(url);
    }
    setErrors({
      password: `validationErrors.required`,
      repeatPassword: `validationErrors.required`
    });
  };
  return (
    <Page
      pageName={t("restorePassword.restorePassword")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="account-form">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-md-6 col-xl-4">
            <div className="account-form-logo">
              <img src="/assets/img/logo-dark.svg" alt="" />
            </div>
            <h1 className="account-form-heading">
              {t("restorePassword.restorePassword")}
            </h1>
            <Formik
              initialValues={{
                name: "",
                password: ""
              }}
              onSubmit={onSave}
              validate={validate}
            >
              {({ errors }) => (
                <Form>
                  <div className="mb-3">
                    <Input
                      type="text"
                      label={t("restorePassword.newPassword")}
                      id="password"
                      name="password"
                      required="1"
                      error={errors?.password}
                    />
                  </div>
                  <div className="mb-3">
                    <Input
                      type="text"
                      label={t("restorePassword.repeatPassword")}
                      id="repeatPassword"
                      name="repeatPassword"
                      required="1"
                      error={errors?.repeatPassword}
                    />
                  </div>
                  <div className="mb-0 text-center">
                    <button name="" className="btn btn-primary">
                      {t("registration.continue")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Page>
  );
};

RestorePassword.propTypes = {
  filter: PropTypes.object
};
export default RestorePassword;
