import PropTypes from "prop-types";

import Breadcrumbs from "./Breadcrumbs";
import Content from "./Content/Content";

const Page = ({
  children,
  pageName,
  breadcrumbs,
  beforeContent,
  sidebar,
  titleAfter,
  modals,
  isClassNamePageSingle,
  beforeSideBarInContent
}) => (
  <>
    <main className="main-content ">
      <div className="container">
        {" "}
        {pageName || titleAfter ? (
          <div className="page-header">
            <div className="row">
              <div className={titleAfter ? "col-lg-8" : "col-lg-12"}>
                {pageName ? <h1 className="page-title">{pageName}</h1> : null}
                <span />
              </div>
              {titleAfter ? (
                <div className="col-lg-4 text-lg-end pt-3 pt-lg-0">
                  {" "}
                  {titleAfter}{" "}
                </div>
              ) : null}
            </div>

            <Breadcrumbs items={breadcrumbs} />
          </div>
        ) : null}
        {beforeContent}
        <Content
          beforeSideBarInContent={beforeSideBarInContent}
          isClassNamePageSingle={isClassNamePageSingle}
          sidebar={sidebar}
          content={children}
        />
      </div>
    </main>
    {modals}
  </>
);
Page.propTypes = {
  children: PropTypes.any,
  pageName: PropTypes.any,
  headerRight: PropTypes.object,
  sidebar: PropTypes.object,
  modals: PropTypes.array,
  breadcrumbs: PropTypes.array,
  isClassNamePageSingle: PropTypes.bool,
  beforeContent: PropTypes.any,
  beforeSideBarInContent: PropTypes.any,
  titleAfter: PropTypes.any
};

export default Page;
