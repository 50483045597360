/* eslint-disable jsx-a11y/anchor-has-content */
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { aboutUrl, contactsUrl, homeUrl } from "../../routes";
import Page from "../layout/Page";

const PublicationRules = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "publicationRules",
      title: t("publicationRules.title"),
      link: aboutUrl
    }
  ];
  return (
    <Page pageName={t("publicationRules.title")} breadcrumbs={breadcrumbs}>
      <div className="page-section page-publication-rules">
        <p>{t("publicationRules.welcome")}</p>
        <h4>{t("publicationRules.rulesForPublishingAds")}</h4>
        <h5>{t("publicationRules.forbidden.title")}</h5>
        <ul>
          <li>{t("publicationRules.forbidden.1")}</li>
          <li>{t("publicationRules.forbidden.2")}</li>
          <li>{t("publicationRules.forbidden.3")}</li>
          <li>{t("publicationRules.forbidden.4")}</li>
          <li>{t("publicationRules.forbidden.5")}</li>
          <li>{t("publicationRules.forbidden.6")}</li>
          <li>{t("publicationRules.forbidden.7")}</li>
          <li>{t("publicationRules.forbidden.8")}</li>
          <li>{t("publicationRules.forbidden.9")}</li>
          <li>{t("publicationRules.forbidden.10")}</li>
          <li>{t("publicationRules.forbidden.11")}</li>
          <li>{t("publicationRules.forbidden.12")}</li>
        </ul>
        <h4>{t("publicationRules.publicationRules.title")}</h4>
        <ul>
          <li>{t("publicationRules.publicationRules.1")}</li>
          <li>{t("publicationRules.publicationRules.2")}</li>
          <li>{t("publicationRules.publicationRules.3")}</li>
          <li>{t("publicationRules.publicationRules.4")}</li>
          <li>{t("publicationRules.publicationRules.5")}</li>
          <li>{t("publicationRules.publicationRules.6")}</li>
          <li>{t("publicationRules.publicationRules.7")}</li>
          <li>{t("publicationRules.publicationRules.8")}</li>
          <li>{t("publicationRules.publicationRules.9")}</li>
        </ul>
        <h4>{t("publicationRules.applicants.title")}</h4>
        <ul>
          <li>{t("publicationRules.applicants.1")}</li>
          <li>{t("publicationRules.applicants.2")}</li>
          <li>{t("publicationRules.applicants.3")}</li>
          <li>{t("publicationRules.applicants.4")}</li>
          <li>{t("publicationRules.applicants.5")}</li>
        </ul>
        <h4>{t("publicationRules.resolutionOfConflictSituations.title")}</h4>
        <ul>
          <li>
            <Trans
              i18nKey="publicationRules.resolutionOfConflictSituations.1"
              components={{
                Link: <a className="forwarding" href={contactsUrl} />
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="publicationRules.resolutionOfConflictSituations.2"
              components={{
                Link: (
                  <a
                    className="forwarding"
                    href="mailto:emigrant.center.mail@gmail.com"
                  />
                )
              }}
            />
          </li>
        </ul>
        <h4>{t("publicationRules.generalProvisions.title")}</h4>
        <ul>
          <li>{t("publicationRules.generalProvisions.1")}</li>
          <li>{t("publicationRules.generalProvisions.2")}</li>
          <li>{t("publicationRules.generalProvisions.3")}</li>
          <li>{t("publicationRules.generalProvisions.4")}</li>
          <li>{t("publicationRules.generalProvisions.5")}</li>
        </ul>
        <h4 className="text-center mt-5">
          {t("publicationRules.byPublishingYourAd")}
        </h4>
        <p className="text-end mt-5">
          {t("publicationRules.updated")}
          <br />
          {t("publicationRules.sincerelyAdministration")}
        </p>
      </div>
    </Page>
  );
};

PublicationRules.propTypes = {
  filter: PropTypes.object
};
export default PublicationRules;
