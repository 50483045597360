/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { addPostUrl, homeUrl } from "../../routes";
import Loading from "../layout/Loading/Loading";
import Page from "../layout/Page";
import { postQuery } from "./CreatePostQuery";
import FirstStage from "./FirstStage/FirstStage";
import SecondStage from "./SecondStage/SecondStage";
import ThirdStage from "./ThirdStage/ThirdStage";

const CreatePost = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const parameters = new URLSearchParams(window.location.search);
  const code = parameters.get("code");
  const postId = parameters.get("postId");
  const edit = parameters.get("edit");
  const addPostSuccess = parameters.get("addPostSuccess");

  const variablesAccessEdit = {
    variables: {
      code: code || null,
      postId: postId || null
    },
    isEdit: !!((code || postId) && edit)
  };

  const { loading, data, refetch, error } = useQuery(postQuery, {
    variables: variablesAccessEdit.variables,
    skip: !code && !postId
  });

  if (loading) return <Loading />;

  if (error) {
    history.push(homeUrl);
    return null;
  }
  const breadcrumbsFirst = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "createPost",
      title: t("createPost.createPost"),
      link: addPostUrl
    }
  ];
  const breadcrumbsSecond = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "previewAndSendPost",
      title: (
        <>
          {t("createPost.previewAndSendPost")}{" "}
          {<span>{t(`createPost.${data?.post?.type}`)}</span>}
        </>
      ),
      link: "#"
    }
  ];
  if (addPostSuccess)
    return (
      <Page>
        <ThirdStage
          postId={data.post.id}
          postSeo={data.post.seo}
          variablesAccessEdit={variablesAccessEdit}
        />
      </Page>
    );

  if (variablesAccessEdit.isEdit || !data?.post)
    return (
      <Page
        isClassNamePageSingle={false}
        breadcrumbs={breadcrumbsFirst}
        pageName={
          parameters.get("activeTab") === "announcement" ? (
            t("createPost.createPost")
          ) : (
            <>
              {t("createPost.createPostDot")}{" "}
              {<span>{t(`createPost.${parameters.get("activeTab")}`)}</span>}
            </>
          )
        }
      >
        <FirstStage
          editPostData={data?.post}
          variablesAccessEdit={variablesAccessEdit}
          refetch={refetch}
        />
      </Page>
    );

  if (!data.post.isShow)
    return (
      <Page
        breadcrumbs={breadcrumbsSecond}
        pageName={
          <>
            {t("createPost.previewAndSendPost")}{" "}
            {<span>{t(`createPost.${data?.post?.type}`)}</span>}
          </>
        }
      >
        <SecondStage
          post={data.post}
          code={variablesAccessEdit.variables.code}
          refetch={refetch}
        />
      </Page>
    );

  return null;
};

CreatePost.propTypes = {
  filter: PropTypes.object
};
export default CreatePost;
