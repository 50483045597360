/* eslint-disable max-statements */

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { socialNetworks } from "../../../../../utility/constants";

const SubscriptionSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="thanks-wrap">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <h1 className="thanks-title">
            {t("subscription.youHaveSuccessfullySubscribedToTheNewsletter")}
          </h1>
          <div className="thanks-btn">
            <Link to="/" className="btn btn-primary">
              {" "}
              {t("subscription.toMain")}
            </Link>
          </div>
          <div className="thanks-image">
            <img src="images/emoji-subscribe-success.svg" alt="" />
          </div>
          <div className="thanks-soc">
            <p> {t("subscription.subscribeToOurGroups")}</p>
            <ul className="soc-links soc-links-thanks">
              <li className="soc-links-instagram">
                <Link to={socialNetworks.instagram} target="_blank"></Link>
              </li>
              <li className="soc-links-facebook">
                <Link to="#" target="_blank"></Link>
              </li>
              <li className="soc-links-telegram">
                <a href="tg://resolve?domain=+447599683751"> </a>
              </li>
              <li className="soc-links-whatsapp">
                <a href="whatsapp://send?phone=+447599683751"> </a>
              </li>
              <li className="soc-links-viber">
                <a href="viber://chat?number=+447599683751"> </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionSuccess.propTypes = {
  tabsHeader: PropTypes.any
};
export default SubscriptionSuccess;
