import PropTypes from "prop-types";

const ContainerAddPost = ({ children, title, description }) => (
  <div className="section-add">
    {title ? (
      <>
        <h4 className="mb-2 text-center text-lg-start">{title}</h4>
        <p className="mb-4 text-center text-lg-start">
          <small>{description}</small>
        </p>
      </>
    ) : null}
    <div className="row">{children}</div>
  </div>
);

ContainerAddPost.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string
};
export default ContainerAddPost;
