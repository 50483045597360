/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Input from "../../../layout/Input/Input";
import Loading from "../../../layout/Loading/Loading";
import Select from "../../../layout/Select";
import SubscriptionClearSuccess from "./SubscriptionClearSuccess";
import SubscriptionSuccess from "./SubscriptionSuccess";

export const allDataQuery = gql`
  query allData {
    categoryProfession {
      id
      title
      professions {
        id
        title
      }
    }
    postAnnouncementCategory {
      id
      title
      postAnnouncementRubric {
        id
        title
      }
    }
    postRentPropertyCategory {
      id
      title
      typeRooms {
        id
        title
        postRentPropertyCategoryId
      }
    }
    userSubscription {
      professionIds
      typeRoomsIds
      postAnnouncementRubricIds
      sendInDays
    }
  }
`;

const userSubscriptionUpdate = gql`
  mutation userSubscriptionUpdate($input: UserSubscriptionUpdateInput) {
    userSubscriptionUpdate(input: $input) {
      success
      code
    }
  }
`;

const encodeName = (key, id) => `${key}_${id}`;
const decodeName = (name) => {
  const [key, id] = name.split("_");
  return { key, id };
};

const Subscription = () => {
  const { t } = useTranslation();
  const sendInDaysEnum = [
    {
      id: "send-days-1",
      title: "1",
      value: 1
    },
    {
      id: "send-days-3",

      title: "3",
      value: 3
    },
    {
      id: "send-days-5",
      title: "5",
      value: 5
    },
    {
      id: "send-days-7",
      title: "7",
      value: 7
    }
  ];
  const typeOutPage = {
    successSubscripte: "successSubscripte",
    successDeSubscripte: "successDeSubscripte"
  };

  const [displayPage, setDisplayPage] = useState();
  const [sendInDays, setSendInDays] = useState(sendInDaysEnum[0].id);

  const { loading, data } = useQuery(allDataQuery);
  const [subscriptionUpdateMutation] = useMutation(userSubscriptionUpdate);
  useEffect(() => {
    if (!loading) {
      const { userSubscription } = data;
      const { sendInDays: sendInDaysDB } = userSubscription;
      const sendInDaysEnumFind = sendInDaysEnum.find(
        (item) => item.value === sendInDaysDB
      );
      if (sendInDaysEnumFind) {
        setSendInDays(sendInDaysEnumFind.id);
      }
    }
  }, [data, loading]);
  if (loading) return <Loading />;
  if (!data) return null;

  const subscriptionUpdateSave = async (input) => {
    const sendInDaysEnumFind = sendInDaysEnum.find(
      (item) => item.id === sendInDays
    );

    const dataMutation = await subscriptionUpdateMutation({
      variables: {
        input: { sendInDays: sendInDaysEnumFind.value, ...input }
      }
    });
    return dataMutation;
  };

  const onSave = async (values) => {
    const professionIds = [];
    const typeRoomsIds = [];
    const postAnnouncementRubricIds = [];

    Object.keys(values).map((key) => {
      if (!values[key]) return null;
      const currentFiled = decodeName(key);
      if (currentFiled.key === "professionId") {
        professionIds.push(currentFiled.id);
      }
      if (currentFiled.key === "postAnnouncementRubricId") {
        postAnnouncementRubricIds.push(currentFiled.id);
      }
      if (currentFiled.key === "typeRoomId") {
        typeRoomsIds.push(currentFiled.id);
      }
      return null;
    });
    const { data: subscriptionUpdateData } = await subscriptionUpdateSave({
      professionIds,
      typeRoomsIds,
      postAnnouncementRubricIds
    });
    if (subscriptionUpdateData?.userSubscriptionUpdate?.success) {
      setDisplayPage(typeOutPage.successSubscripte);
    }
  };

  const saveEmtyData = async () => {
    const { data: subscriptionUpdateData } = await subscriptionUpdateSave({
      professionIds: [],
      typeRoomsIds: [],
      postAnnouncementRubricIds: []
    });
    if (subscriptionUpdateData?.userSubscriptionUpdate?.success) {
      setDisplayPage(typeOutPage.successDeSubscripte);
    }
  };
  const saveAllData = async (setValues) => {
    const professionIds = [];
    const typeRoomsIds = [];
    const postAnnouncementRubricIds = [];
    data.categoryProfession.map((categoryProfession) =>
      categoryProfession.professions.map(({ id }) => {
        professionIds.push(id);
        return null;
      })
    );
    data.postRentPropertyCategory.map((x) =>
      x.typeRooms.map(({ id }) => {
        typeRoomsIds.push(id);
        return null;
      })
    );
    data.postAnnouncementCategory.map((x) =>
      x.postAnnouncementRubric.map(({ id }) => {
        postAnnouncementRubricIds.push(id);
        return null;
      })
    );

    const initialValues = {};
    professionIds.map((professionId) => {
      initialValues[encodeName("professionId", professionId)] = true;
      return null;
    });
    postAnnouncementRubricIds.map((postAnnouncementRubricId) => {
      initialValues[
        encodeName("postAnnouncementRubricId", postAnnouncementRubricId)
      ] = true;
      return null;
    });
    typeRoomsIds.map((typeRoomId) => {
      initialValues[encodeName("typeRoomId", typeRoomId)] = true;
      return null;
    });
    setValues(initialValues);
    // const { data: subscriptionUpdateData } = await subscriptionUpdateSave({
    //   professionIds,
    //   typeRoomsIds,
    //   postAnnouncementRubricIds
    // });
    // if (subscriptionUpdateData?.userSubscriptionUpdate?.success) {
    //   setDisplayPage(typeOutPage.successSubscripte);
    // }
  };

  const initialValues = {};
  data.userSubscription.professionIds.map((professionId) => {
    initialValues[encodeName("professionId", professionId)] = true;
    return null;
  });
  data.userSubscription.postAnnouncementRubricIds.map(
    (postAnnouncementRubricId) => {
      initialValues[
        encodeName("postAnnouncementRubricId", postAnnouncementRubricId)
      ] = true;
      return null;
    }
  );
  data.userSubscription.typeRoomsIds.map((typeRoomId) => {
    initialValues[encodeName("typeRoomId", typeRoomId)] = true;
    return null;
  });
  if (displayPage === typeOutPage.successDeSubscripte)
    return <SubscriptionClearSuccess />;
  if (displayPage === typeOutPage.successSubscripte)
    return <SubscriptionSuccess />;
  return (
    <div className="section-add">
      <h4>{t(`office.selectTheCategoryYouAreInterestedIn`)}</h4>
      <Formik initialValues={initialValues} onSubmit={onSave}>
        {({ setValues }) => (
          <Form key={"form_suns"}>
            <div className="form-check-wrap mb-3 flex">
              <div className="mb-3">
                <Trans
                  i18nKey="subscription.send"
                  components={{
                    select: (
                      <Select
                        value={sendInDays}
                        options={sendInDaysEnum}
                        style={{
                          width: "120px",
                          marginRight: "5px",
                          marginLeft: "5px"
                        }}
                        id="typeQuestion"
                        onChange={(val) => setSendInDays(val)}
                      />
                    ),
                    div: <div style={{ display: "flex" }} />,
                    span: <span className="my-auto" />
                  }}
                />
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox10"
                  value="option10"
                  onClick={() => saveAllData(setValues)}
                />
                <label className="form-check-label" for="inlineCheckbox10">
                  {t(`office.selectAll`)}
                </label>
              </div>
              <div className="ms-4">
                {data.categoryProfession.map((cat) => (
                  <>
                    <h5 className="mt-4">
                      {t(`categoryProfession.${cat.title}`)}
                    </h5>
                    {cat.professions.map((prof) => (
                      <div className="form-check" key={prof.id}>
                        <Input
                          name={encodeName("professionId", prof.id)}
                          className="form-check-input"
                          type="checkbox"
                          id={encodeName("professionId", prof.id)}
                          label={t(`profession.${prof.title}`)}
                        />
                      </div>
                    ))}
                  </>
                ))}

                {data.postAnnouncementCategory.map((cat) => (
                  <>
                    <h5 className="mt-4">
                      {t(`announcementCategory.${cat.title}`)}
                    </h5>
                    {cat.postAnnouncementRubric.map((rubric) => (
                      <div className="form-check" key={rubric.id}>
                        <Input
                          name={encodeName(
                            "postAnnouncementRubricId",
                            rubric.id
                          )}
                          className="form-check-input"
                          type="checkbox"
                          id={encodeName("postAnnouncementRubricId", rubric.id)}
                          label={t(`announcementRubric.${rubric.title}`)}
                        />
                      </div>
                    ))}
                  </>
                ))}

                {data.postRentPropertyCategory.map((cat) => (
                  <>
                    <h5 className="mt-4">
                      {t(`postRentPropertyCategory.${cat.title}`)}
                    </h5>
                    {cat.typeRooms.map((typeRoom) => (
                      <div className="form-check" key={typeRoom.id}>
                        <Input
                          name={encodeName("typeRoomId", typeRoom.id)}
                          className="form-check-input"
                          type="checkbox"
                          id={encodeName("typeRoomId", typeRoom.id)}
                          label={t(`typeRoom.${typeRoom.title}`)}
                        />
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
            <div className="text-center text-sm-end mb-3 d-flex flex-column-reverse d-sm-block">
              <Link
                onClick={saveEmtyData}
                className="unsubscribe-link me-4 my-3 my-sm-0"
              >
                {t(`office.unsubscribeFromAllNews`)}
              </Link>
              <button type="submit" className="btn btn-primary">
                {t(`office.confirm`)}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

Subscription.propTypes = {
  tabsHeader: PropTypes.any
};
export default Subscription;
