import UserOffice from "../components/UserOffice";
import Layout from "./Layout/Layout";

const UserOfficeView = () => (
  <Layout>
    <UserOffice />
  </Layout>
);

export default UserOfficeView;
