import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { homeUrl } from "../../../../routes";
import Button from "../../../layout/Button/Button";
import IsRemovePostModal from "../../../SinglePost/Modals/IsRemovePostModal";

const ManageBlock = ({
  deletePostByCode,
  showPostByCodeFun,
  editPostByCode
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isShowDeletePost, setIsShowDeletePost] = useState(false);
  return (
    <div className="page-preview-wrap mb-3 mb-lg-0">
      <div className="preview-buttons">
        <Button
          layout="primary-outline"
          className="btn-icon btn-icon-edit"
          onClick={() => editPostByCode()}
        >
          {t("postManageButton.edit")}
        </Button>
        {<span> </span>}
        <Button
          layout="primary-outline"
          className="btn-icon btn-icon-remove"
          onClick={() => setIsShowDeletePost(true)}
        >
          {t("postManageButton.remove")}
        </Button>
        {<span> </span>}
        <Button
          layout="primary"
          onClick={() => {
            showPostByCodeFun();
          }}
        >
          {t("postManageButton.publish")}{" "}
          <span className="d-none d-lg-inline">{` (${t(
            "postManageButton.free"
          )})`}</span>
        </Button>
      </div>
      <IsRemovePostModal
        isShow={isShowDeletePost}
        onClose={() => setIsShowDeletePost(false)}
        deletePostFun={deletePostByCode}
        afterSuccessDeleteFun={() => {
          history.push(homeUrl);
        }}
      />
    </div>
  );
};

ManageBlock.propTypes = {
  deletePostByCode: PropTypes.func,
  showPostByCodeFun: PropTypes.func,
  editPostByCode: PropTypes.func
};
export default ManageBlock;
