import { useMutation } from "@apollo/client";
import { Field } from "formik";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import singleUpload from "../../../graphql/mutation/uploadImage";

const UploadImage = ({ setFile, title, deleteFile }) => {
  const [uploadFile] = useMutation(singleUpload);
  const [isLoaderImage, setIsLoader] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const fileInput = useRef(null);

  const handleFileRemove = async (event) => {
    event.preventDefault();
    await deleteFile();
    fileInput.current.value = "";
    setHasFile(false);
    setIsLoader(false);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    setIsLoader(true);
    setHasFile(true);

    const reader = new FileReader();

    reader.onload = async () => {
      const binaryStr = reader.result;

      const { data } = await uploadFile({ variables: { file: binaryStr } });
      setFile(data.singleUpload.imageId);
      setIsLoader(false);

      setIsLoader(false);
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <label htmlFor="inputPhoto" className="form-label form-label-flex">
        <span>
          {title}
          {isLoaderImage ? (
            <img
              src="/assets/img/loading-gif.gif"
              className="form-label-loading"
              alt="Loading"
            />
          ) : null}
        </span>
        {hasFile && (
          <button
            className="form-field-file-remove"
            onClick={handleFileRemove}
          />
        )}
      </label>
      <div className="input-group">
        <Field
          type="file"
          onChange={(e) => handleFileChange(e)}
          className="form-control"
          name="files"
          id="inputPhoto"
          innerRef={fileInput}
          accept="image/png, image/jpeg, image/gif, image/tiff, image/bmp, image/webp"
        />
      </div>
    </>
  );
};

UploadImage.propTypes = {
  setFile: PropTypes.func,
  title: PropTypes.string,
  deleteFile: PropTypes.func
};

export default UploadImage;
