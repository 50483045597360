import "moment/locale/ru";

import { t } from "i18next";
import PropTypes from "prop-types";
import { useContext } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { UserContext } from "../../../utility/context/User";
import Pagination from "../Pagination";
import ListPost from "./ListPost";

const PostTable = ({
  posts,
  total,
  isAsyncScroll,
  currentPage,
  setCurrentPage,
  pageSize,
  loading,
  refetch
}) => {
  const { user } = useContext(UserContext);
  return (
    <>
      <div className="works-items">
        <div className="works-itemuser works-head">
          <div className="works-item-inner">
            <div className="works-item-title works-item-title-2">
              {t("typeOfAd")}
            </div>
            <div className="works-item-city">{t("post.city")}</div>
            <div className="works-item-date">{t("post.date")}</div>
          </div>
        </div>

        {isAsyncScroll ? (
          <InfiniteScroll
            pageStart={currentPage}
            loadMore={async (d) => {
              if (loading) return false;
              await setTimeout(() => setCurrentPage(d), 1000);
              return true;
            }}
            hasMore={total / pageSize > currentPage}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {posts.map((post, index) => (
              <ListPost
                user={user}
                posts={posts}
                post={post}
                index={index}
                key={post.id}
                refetch={refetch}
                setPosts={() => {}}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <div>
            {posts.map((post, index) => (
              <ListPost
                user={user}
                posts={posts}
                post={post}
                index={index}
                key={post.id}
                refetch={refetch}
                setPosts={() => {}}
              />
            ))}
          </div>
        )}
      </div>
      {!isAsyncScroll ? (
        <Pagination
          total={total}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : null}
    </>
  );
};

PostTable.propTypes = {
  posts: PropTypes.array,
  total: PropTypes.number,
  isAsyncScroll: PropTypes.bool,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  setPosts: PropTypes.func
};
PostTable.defaultProps = {
  loading: false
};
export default PostTable;
