import PropTypes from "prop-types";
import Select from "react-select";

import { classNames } from "../../../../utility";

const MultiSelect = ({
  options,
  value,
  onChange,
  id,
  label,
  error,
  placeholder,
  disabled,
  isAddOptionAll,
  titleSelectAll
}) => {
  const fullOptions = [...options];

  const selectAllOption = {
    id: null,
    value: null,
    title: titleSelectAll,
    checked: value?.length === options.length
  };

  const newOption = isAddOptionAll
    ? [selectAllOption, ...fullOptions]
    : fullOptions;

  const newOptions = newOption.map((option) => ({
    value: option.id,
    label: (
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={
            option.title === titleSelectAll
              ? value?.length === options.length
              : value?.includes(option.id)
          }
        />
        {option.title}
      </div>
    )
  }));

  const selected = options
    .map((option) => ({
      value: option.id,
      label: option.title
    }))
    .filter((option) => {
      if (!value) return false;
      const isSelected = value.find((v) => option.value === v);
      return isSelected;
    });

  const styles = {
    backgroundColor: "#E6E6E6",
    label: "option",
    cursor: "pointer",
    display: "block",
    fontSize: "inherit",
    width: "100%",
    userSelect: "none",
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    color: "inherit",
    padding: "8px 12px",
    ":active": {
      backgroundColor: "#7ab4ff"
    },
    ":hover": {
      backgroundColor: "#d1e5ff"
    },
    boxSizing: "border-box"
  };

  return (
    <>
      <label
        htmlFor={id}
        className="form-label"
        style={{
          fontSize: "16px"
        }}
      >
        {label}
      </label>
      <Select
        styles={{
          option: () => ({
            ...styles,
            fontSize: "16px",
            zIndex: 100
          }),
          menu: (e) => ({
            ...e,
            backgroundColor: "rgba(0, 0, 0, 1)",
            zIndex: 10000
          }),
          control: () => ({
            alignItems: "center",
            backgroundColor: "#e6e6e600",
            borderColor: "#e6e6e600",
            borderRadius: "5px",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "pointer",
            display: "flex",
            padding: "2px 10px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            minHeight: "38px",
            position: "relative",
            transition: "all 100ms",
            boxSizing: "border-box",
            fontSize: "16px"
          }),
          multiValueLabel: (styless) => ({ ...styless, fontSize: "16px" }),
          indicatorSeparator: () => ({
            display: "none"
          })
        }}
        className={classNames(
          "multi-select form-control p-0 options-munu-mylti-select",
          error ? "is-invalid" : ""
        )}
        hideSelectedOptions={false}
        id={id}
        isDisabled={disabled}
        options={newOptions}
        isMulti={true}
        isSearchable={false}
        value={selected}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        placeholder={placeholder}
        onChange={(selectedOptions) => {
          const selectedValues = selectedOptions.map((option) => option.value);

          if (selectedValues.includes(null)) {
            const newValue =
              value?.length === options.length
                ? []
                : options.map((option) => option.id);
            onChange(newValue);
            return;
          }

          onChange(selectedValues);
        }}
      />
    </>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      title: PropTypes.any,
      value: PropTypes.any
    })
  ),
  value: PropTypes.array,
  onChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.any,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isAddOptionAll: PropTypes.bool,
  titleSelectAll: PropTypes.string
};

MultiSelect.defaultProps = {
  options: [],
  value: [],
  disabled: false
};

export default MultiSelect;
