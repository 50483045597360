import RestorePassword from "../components/RestorePassword";
import Layout from "./Layout/Layout";

const RestorePasswordView = () => (
  <Layout>
    <RestorePassword />
  </Layout>
);

export default RestorePasswordView;
