import PropTypes from "prop-types";

import { classNames } from "../../../utility";

const Modal = ({ children, isShow, onClose }) => (
  <>
    <div
      className={classNames("modal modalInfo fade", isShow ? "show" : "")}
      tabIndex="-1"
      aria-labelledby="modalInfoBlockLabel"
      aria-modal="true"
      role="dialog"
      style={{ display: isShow ? "block" : "none", zIndex: 1000 }}
      onClick={() => onClose()}
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="block-no-content block-no-content-lg">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose()}
            ></button>
            {children}
          </div>
        </div>
      </div>
    </div>
    <div
      className={classNames("modal-backdrop fade", isShow ? "show" : "")}
      style={{ display: isShow ? "block" : "none", zIndex: 990 }}
    ></div>
  </>
);
Modal.propTypes = {
  children: PropTypes.node,
  isShow: PropTypes.bool,
  onClose: PropTypes.func
};

export default Modal;
