import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { numberOfBathrooms } from "../../../../../graphql/enum";
import Hint from "../../../../layout/Hint";
import Input from "../../../../layout/Input/Input";
import Select from "../../../../layout/Select";
import UploadImageAndPreview from "../../../../layout/UploadImageAndPreview/UploadImageAndPreview";

const SecondBlock = ({
  data,
  values,
  errors,
  setValues,
  validateForm,
  images,
  valuesFormik
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-4 mb-4">
        <Select
          className="form-select"
          id="inputRent"
          key="inputRent"
          aria-label="Default select example"
          options={numberOfBathrooms}
          value={values.numberOfBathroom}
          onChange={async (val) => {
            await setValues({ ...values, numberOfBathroom: val });
            validateForm();
          }}
          label={t("createPost.numberOfBathrooms")}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <Select
          key="rentalPeriod"
          value={values.rentalPeriod}
          options={data.rentalPeriods.map((rentalPeriod) => ({
            ...rentalPeriod,
            title: t(`rentalPeriod.${rentalPeriod.title}`)
          }))}
          id="rentalPeriod"
          label={
            <>
              {t("createPost.rentalPeriod")}
              <Hint components={t("hint.rentalPeriod")} />
            </>
          }
          error={errors?.rentalPeriod}
          onChange={async (val) => {
            await setValues({ ...values, rentalPeriod: val });
            validateForm();
          }}
        />
      </div>
      <div className="col-lg-4 mb-4">
        <Input
          type="text"
          label={t("createPost.price")}
          id="price"
          placeholder=""
          name="price"
          value={valuesFormik?.price}
          error={errors?.price}
        />
      </div>
      <div className="col-12 mb-4 mb-lg-3">
        <div className="form-check form-check-inline">
          <Input
            className="form-check-input"
            type="checkbox"
            id="isGarden"
            label={t("createPost.garden")}
            name="isGarden"
          />
        </div>
        <div className="form-check form-check-inline">
          <Input
            className="form-check-input"
            type="checkbox"
            id="isCarParking"
            label={t("createPost.carParking")}
            name="isCarParking"
          />
        </div>
        <div className="form-check form-check-inline">
          <Input
            className="form-check-input"
            type="checkbox"
            id="isUtilitiesIncluded"
            label={t("createPost.utilitiesIncluded")}
            name="isUtilitiesIncluded"
          />
        </div>
        <div className="form-check form-check-inline">
          <Input
            className="form-check-input"
            type="checkbox"
            id="isWifi"
            label={t("createPost.internetAtHome")}
            name="isWifi"
          />
        </div>
        <div className="form-check form-check-inline">
          <Input
            className="form-check-input"
            type="checkbox"
            id="isBicycleSpace"
            label={t("createPost.bicycleSpace")}
            name="isBicycleSpace"
          />
        </div>
      </div>

      <div className="col-12 mb-4">
        <UploadImageAndPreview
          title={t("createPost.uploadAPhotoInGalarey")}
          imagesDefault={images}
          setFile={(fileId) =>
            setValues({
              ...values,
              imagesIds: [...values.imagesIds, fileId]
            })
          }
          removeFile={(fileId) => {
            const newImages = values.imagesIds.filter((i) => i !== fileId);
            setValues({
              ...values,
              imagesIds: newImages
            });
          }}
        />
      </div>
    </>
  );
};
SecondBlock.defaultValues = {
  validateForm: () => {}
};

SecondBlock.propTypes = {
  values: PropTypes.shape({
    imagesIds: PropTypes.array,
    description: PropTypes.string,
    rentalPeriod: PropTypes.string,
    numberOfBathroom: PropTypes.string
  }),
  data: PropTypes.object,
  setValues: PropTypes.func,
  errors: PropTypes.object,
  validateForm: PropTypes.func,
  images: PropTypes.array,
  valuesFormik: PropTypes.object
};
export default SecondBlock;
