import PropTypes from "prop-types";

import ListInformationPost from "../ListInformationPost/ListInformationPost";

const ListData = (props) => (
  <ul className="preview-list">
    <ListInformationPost {...{ ...props }} />
  </ul>
);

ListData.propTypes = {
  filter: PropTypes.object
};
export default ListData;
