/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Hint from "../../../../layout/Hint";
import Select from "../../../../layout/Select";
import MultiSelect from "../../../../layout/Select/MultiSelect";
import FieldsAddPost, {
  fieldsAddPostTypes
} from "../../CreatePostLayout/FieldsAddPost";

const FirstBlock = ({
  data,
  errors,
  values,
  setValues,
  validateForm,
  valuesFormik,
  setValuesFormik
}) => {
  const { t } = useTranslation();
  const [professions, setProfessions] = useState([]);
  const [isFirstRenter, setIsFirstRenter] = useState(true);
  useEffect(() => {
    if (!data?.categoryProfession) return;
    const categoryProfession = data?.categoryProfession.find(
      ({ id: categoryProfessionId }) =>
        categoryProfessionId === values?.categoryProfession
    );

    setProfessions(categoryProfession ? categoryProfession.professions : []);
    if (!isFirstRenter) {
      setValues({ ...values, profession: [] });
    }
    setIsFirstRenter(false);
    validateForm();
  }, [data?.categoryProfession, values?.categoryProfession]);
  return (
    <>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          valuesFormik={valuesFormik}
          errors={errors}
          type={fieldsAddPostTypes.title}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <Select
          className="form-select"
          id="inputCategory"
          key="inputCategory"
          aria-label="Default select example"
          options={data.categoryProfession.map((p) => ({
            ...p,
            title: t(`categoryProfession.${p.title}`)
          }))}
          value={values.categoryProfession}
          onChange={async (val) => {
            await setValues({ ...values, categoryProfession: val });
            validateForm();
          }}
          label={
            <>
              *{t("createPost.categoryOfVacancies")}
              <Hint components={t("hint.categoryOfVacancies")} />
            </>
          }
          error={errors?.categoryProfession}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <MultiSelect
          className="form-select"
          id="inputOtherProfession"
          key="inputOtherProfessions"
          placeholder={t("createPost.select")}
          options={professions.map((p) => ({
            ...p,
            title: t(`profession.${p.title}`)
          }))}
          value={values.profession}
          onChange={async (val) => {
            await setValues({ ...values, profession: val });
            validateForm();
          }}
          aria-label="Default select example"
          label={
            <>
              *{t("createPost.profession")}{" "}
              <Hint components={t("hint.profession")} />
            </>
          }
          error={errors?.profession}
          disabled={!values.categoryProfession}
        />
      </div>
      <div className="col-lg-12">
        <div className="row">
          <FieldsAddPost
            {...{
              values,
              setValues,
              errors,
              validateForm,
              data,
              isRequairedCity: false,
              type: fieldsAddPostTypes.cityAndPostCode
            }}
          />
        </div>
      </div>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          {...{
            errors,
            validateForm,
            setValues,
            values,
            type: fieldsAddPostTypes.description
          }}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <div className="mb-3">
          <FieldsAddPost
            valuesFormik={valuesFormik}
            type={fieldsAddPostTypes.phone}
            errors={errors}
            setValuesFormik={setValuesFormik}
          />
        </div>
        <div className="form-check-inline-wrap" style={{ height: "inherit" }}>
          <FieldsAddPost type={fieldsAddPostTypes.messages} height="d" />{" "}
        </div>
      </div>
      <div className="col-lg-6 mb-4">
        <FieldsAddPost
          valuesFormik={valuesFormik}
          type={fieldsAddPostTypes.email}
          errors={errors}
        />
      </div>
    </>
  );
};

FirstBlock.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  data: PropTypes.object,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};
export default FirstBlock;
