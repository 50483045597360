import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Hint from "../../../../layout/Hint";
import Input from "../../../../layout/Input/Input";
import MultiSelect from "../../../../layout/Select/MultiSelect";
import UploadImageAndPreview from "../../../../layout/UploadImageAndPreview/UploadImageAndPreview";

const SecondBlock = ({
  data,
  values,
  setValues,
  errors,
  validateForm,
  images,
  valuesFormik
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-3 mb-4">
        <MultiSelect
          value={values.countrysAlongRoute}
          className="form-select"
          id="inputOthercarrier"
          key="inputOthercarrier"
          options={data.carrierCountrys.map((p) => ({
            ...p,
            title: t(`carrierCountry.${p.title}`)
          }))}
          aria-label="Default select example"
          label={
            <>
              {t("createPost.carrier.countrysAlongRoute")}
              <Hint
                components={
                  <>
                    {t("hint.countrysAlongRoute")} <br />
                    {t("hint.countrysAlongRoute2")}
                  </>
                }
              />
            </>
          }
          onChange={async (val) => {
            await setValues({ ...values, countrysAlongRoute: val });
            validateForm();
          }}
        />
      </div>
      <div className="col-lg-3 mb-4">
        <Input
          type="text"
          name="cityDeparture"
          id="cityDeparture"
          label={
            <>
              {t("createPost.carrier.cityDeparture")}
              <Hint
                components={
                  <>
                    {t("hint.cityDeparture")} <br />
                    {t("hint.cityDeparture2")}
                  </>
                }
              />
            </>
          }
          error={errors?.cityDeparture}
          value={valuesFormik.cityDeparture}
        />
      </div>

      <div className="col-lg-3 mb-4">
        <Input
          type="text"
          name="cityArrival"
          id="cityArrival"
          label={
            <>
              {t("createPost.carrier.cityArrival")}
              <Hint components={t("hint.cityArrival")} />
            </>
          }
          error={errors?.cityArrival}
          value={valuesFormik.cityArrival}
        />
      </div>
      <div className="col-lg-3 mb-4">
        <Input
          type="text"
          label={
            <>
              {t("createPost.carrier.cityAlongRoute")}
              <Hint components={t("hint.cityAlongRoute")} />
            </>
          }
          id="cityAlongRoute"
          name="cityAlongRoute"
          value={valuesFormik.cityAlongRoute}
        />
      </div>
      <div className="col-12 mb-4">
        <UploadImageAndPreview
          title={t("createPost.uploadAPhotoInGalarey")}
          imagesDefault={images}
          setFile={async (fileId) => {
            await setValues({
              ...values,
              imagesIds: [...values.imagesIds, fileId]
            });
          }}
          removeFile={(fileId) => {
            const newImages = values.imagesIds.filter((i) => i !== fileId);
            setValues({
              ...values,
              imagesIds: newImages
            });
          }}
        />
      </div>
    </>
  );
};

SecondBlock.defaultValues = {
  validateForm: () => {}
};

SecondBlock.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  errors: PropTypes.object,
  validateForm: PropTypes.func,
  afterDescription: PropTypes.any,
  data: PropTypes.object,
  images: PropTypes.array,
  valuesFormik: PropTypes.object
};
export default SecondBlock;
