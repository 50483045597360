import PermitWork from "../components/PermitWork";
import Layout from "./Layout/Layout";

const PermitWorkView = () => (
  <Layout>
    <PermitWork />
  </Layout>
);

export default PermitWorkView;
