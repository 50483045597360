import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { typeField } from "../../../../../graphql/enum";
import ListInformationPosteElement from "../ListInformationPosteElement";

const ListInformationPostCarrier = ({ post }) => {
  const { t } = useTranslation();
  return (
    <ul className="page-single-list page-single-list-half">
      <ListInformationPosteElement
        element={`${post.cityDeparture ? `${post.cityDeparture}, ` : ""}${
          post.carrierCountryDeparture
            ? t(`carrierCountry.${post.carrierCountryDeparture.title}`)
            : ""
        }`}
        type={typeField.departure}
      />
      <ListInformationPosteElement
        element={`${post.cityArrival ? `${post.cityArrival}, ` : ""}${
          post.carrierCountryArrival
            ? t(`carrierCountry.${post.carrierCountryArrival.title}`)
            : ""
        }`}
        type={typeField.arrival}
      />
      <ListInformationPosteElement
        element={post.countryAlongRoute}
        type={typeField.countryAlongRoute}
      />{" "}
      <ListInformationPosteElement
        element={post.cityAlongRoute}
        type={typeField.cityAlongRoute}
      />
      <ListInformationPosteElement
        element={post.dateDeparture}
        type={typeField.dateDeparture}
      />
    </ul>
  );
};

ListInformationPostCarrier.propTypes = {
  post: PropTypes.object
};
export default ListInformationPostCarrier;
