import { gql } from "@apollo/client";

const signIn = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      success
      code
      jwtToken
    }
  }
`;

export default signIn;
