import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { userRoles } from "../../../../graphql/enum";
import { addPostUrl, getPostUrl } from "../../../../routes";
import IsRemovePostModal from "../../../SinglePost/Modals/IsRemovePostModal";
import Link from "../../Link";

const IsPermisionEdit = ({
  children,
  post,
  user,
  posts,
  setPosts,
  setIsShowAction
}) => {
  const history = useHistory();
  const [isShowDeletePost, setIsShowDeletePost] = useState(false);

  const editHendler = async () => {
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        postId: post.id,
        edit: true,
        activeTab: post.type
      }
    });
    history.push(url);
  };
  const afterSuccessDeleteFun = () => {
    const newPosts = posts.filter((item) => item.id !== post.id);
    setPosts(newPosts);
  };

  if (user && user.role === userRoles.admin)
    return (
      <>
        <div className="works-item-inner" onClick={() => setIsShowAction(true)}>
          {children}
        </div>
        <div onClick={() => setIsShowAction(false)} className="post-item-btn">
          <Link
            to={getPostUrl(post.id, post?.seo)}
            className="post-item-btn_view"
          />
          <Link onClick={editHendler} className="post-item-btn_edit" />
          <Link
            onClick={() => setIsShowDeletePost(true)}
            className="post-item-btn_delete"
          />
        </div>
        <IsRemovePostModal
          isShow={isShowDeletePost}
          onClose={() => setIsShowDeletePost(false)}
          postId={post.id}
          afterSuccessDeleteFun={afterSuccessDeleteFun}
        />
      </>
    );
  return (
    <Link to={getPostUrl(post.id, post?.seo)} className="works-item-inner">
      {children}
    </Link>
  );
};

IsPermisionEdit.propTypes = {
  children: PropTypes.any,
  post: PropTypes.object,
  user: PropTypes.object,
  refetch: PropTypes.func,
  posts: PropTypes.array,
  setPosts: PropTypes.func,
  setIsShowAction: PropTypes.func
};

IsPermisionEdit.defaultProps = {
  user: {}
};
export default IsPermisionEdit;
