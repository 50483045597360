import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getSiteArticleUrl, homeUrl, siteArticlesUrl } from "../../../routes";
import LinkView from "../../layout/Link";
import Loading from "../../layout/Loading/Loading";
import Page from "../../layout/Page";

export const siteArticlesQuery = gql`
  query siteArticles {
    siteArticles {
      id
      title
      subTitle
      description
      image {
        src
      }
    }
  }
`;

const SiteArticlesList = () => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(siteArticlesQuery, {
    variables: {}
  });

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "siteArticles",
      title: t("header.siteArticles"),
      link: siteArticlesUrl
    }
  ];

  if (loading) return <Loading />;

  return (
    <Page
      pageName={t("header.siteArticles")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      <div className="articles-items">
        {data.siteArticles.map(
          ({ id, title, subTitle, description, image }) => (
            <div className="articles-item">
              <div className="articles-item__image">
                <LinkView to={getSiteArticleUrl(id)}>
                  <img src={image?.src} width="397" height="228" alt={title} />
                </LinkView>
              </div>
              <div
                className="articles-item__info"
                style={{
                  width: "100%",
                  display: "block"
                }}
              >
                <div className="mb-2 d-grid">
                  <h3
                    className="articles-item__title"
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}
                  >
                    <LinkView to={getSiteArticleUrl(id)}>{title}</LinkView>
                  </h3>
                </div>
                <p className="articles-item__cat">{subTitle}</p>
                <div className="articles-item__content">{description}</div>
                <div className="articles-item__more">
                  {" "}
                  <LinkView to={getSiteArticleUrl(id)}>
                    {t("siteArticles.readMore")}
                  </LinkView>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </Page>
  );
};

SiteArticlesList.propTypes = {
  filter: PropTypes.object
};
export default SiteArticlesList;
