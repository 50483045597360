import PropTypes from "prop-types";

import { classNames } from "../../../utility";

const Div = ({ isAddDiv = false, children }) =>
  isAddDiv ? <div>{children}</div> : children;

const Radio = ({ isAddDiv, options, value, onClick, id, label }) => (
  <>
    {label && label.trim() !== "" && (
      <label htmlFor={id} className="form-label">
        {label}
      </label>
    )}
    {options.map((option, i) => (
      <Div isAddDiv={isAddDiv} key={option.value}>
        <span
          onClick={() => {
            onClick(option.value);
          }}
        >
          <div className="form-check form-check-inline">
            {option.value === value ? (
              <input
                type="radio"
                className="form-check-input"
                name="options-outlined"
                id={`success-outlined-${option.value}`}
                value={option.value}
                checked
              />
            ) : (
              <input
                type="radio"
                className="form-check-input"
                name="options-outlined"
                id={`success-outlined-${option.value}`}
                value={option.value}
              />
            )}
            <label
              className={classNames(!i ? "" : "", "form-check-label")}
              htmlFor={`success-outlined-${option.value}`}
            >
              {option.title}
            </label>
          </div>
        </span>
      </Div>
    ))}
  </>
);

Radio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  value: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  isAddDiv: PropTypes.bool
};

Radio.defaultProps = {
  options: []
};

Div.propTypes = {
  isAddDiv: PropTypes.bool,
  children: PropTypes.node
};

export default Radio;
