import BlackList from "../components/BlackList/BlackList";
import Layout from "./Layout/Layout";

const BlackListView = () => (
  <Layout>
    <BlackList />
  </Layout>
);

export default BlackListView;
