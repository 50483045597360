import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { isSelectLondon } from "../../../../../../utility";
import Hint from "../../../../../layout/Hint";
import Select from "../../../../../layout/Select";
import MultiSelect from "../../../../../layout/Select/MultiSelect";

const Field = ({
  values,
  setValues,
  errors,
  validateForm,
  isRequairedCity,
  data
}) => {
  const { t } = useTranslation();

  if (!values.cityInEngland) {
    const london = data.cities.find(({ title }) => title === "London");
    setValues({ ...values, cityInEngland: london.id });
  }

  return (
    <>
      <div className="col-lg-6 mb-4">
        <Select
          isAddEmptyOption={false}
          value={values.cityInEngland}
          options={data.cities.map((city) => ({
            ...city,
            title: t(`city.${city.title}`)
          }))}
          id="inputCity"
          error={errors?.cityInEngland}
          label={
            <>
              {isRequairedCity ? "*" : ""}
              {t("createPost.chooseACityInEngland")}
              <Hint components={t("hint.cityInEngland")} />
            </>
          }
          onChange={async (val) => {
            const postIndexIds = isSelectLondon(val, data.cities)
              ? values.postIndexIds
              : null;
            await setValues({ ...values, cityInEngland: val, postIndexIds });

            validateForm();
          }}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <MultiSelect
          options={data.postIndexs.map((p) => ({
            ...p,
            title: t(`postIndexs.${p.title}`)
          }))}
          value={values.postIndexIds}
          isAddOptionAll={true}
          titleSelectAll={t("sideBarFilter.allLondon")}
          onChange={async (val) => {
            await setValues({ ...values, postIndexIds: val });
            validateForm();
          }}
          id="londonPostcodes"
          disabled={!isSelectLondon(values.cityInEngland, data.cities)}
          error={errors?.postIndexIds}
          placeholder={t("createPost.select")}
          label={
            <>
              {t("createPost.londonPostcodes")}
              <Hint
                components={
                  <Trans
                    i18nKey="hint.londonPostcodes"
                    components={{ br: <br /> }}
                  />
                }
              />
            </>
          }
        />
      </div>
    </>
  );
};

Field.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  data: PropTypes.object,
  isRequairedCity: PropTypes.bool,
  isMultiSelect: PropTypes.bool
};

export default Field;
