/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import createBlackList from "../../../graphql/mutation/createBlackList";
import updateBlackList from "../../../graphql/mutation/updateBlackList";
import {
  addBlackListUrl,
  getComplaintSentBlackListUrl,
  homeUrl
} from "../../../routes";
import {
  clearHTMLTags,
  getValueId,
  getValues,
  getValuesBoolean,
  removeSpaces,
  validatePhone
} from "../../../utility";
import Page from "../../layout/Page";
import AddBlackListForm from "./AddBlackListForm";

const AddBlackList = ({ blackList, refetch, code }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    description: getValues(blackList?.description, code),
    typeQuestion: getValueId(blackList?.typeQuestion, code),
    fileId: code ? blackList?.fileId : null
  });
  const history = useHistory();

  const [isValidate, setIsValidate] = useState(false);
  const [createQuestionMutation] = useMutation(createBlackList);
  const [updateBlackListMutation] = useMutation(updateBlackList);

  const validate = (valuesValidate) => {
    const errors = {};
    if (!valuesValidate.nameCompany) {
      errors.nameCompany = `validationErrors.required`;
    }
    if (!validatePhone(valuesValidate.phoneCompany)) {
      errors.phoneCompany = `validationErrors.required`;
    }
    if (removeSpaces(valuesValidate.title).length < 10) {
      errors.title = `validationErrors.min10`;
    }

    if (!valuesValidate.name) {
      errors.name = `validationErrors.required`;
    }
    if (!valuesValidate.email) {
      errors.email = `validationErrors.required`;
    }
    if (!validatePhone(valuesValidate.phone)) {
      errors.phone = `validationErrors.required`;
    }
    if (removeSpaces(clearHTMLTags(values?.description)).length < 20) {
      errors.description = `validationErrors.min20`;
    }
    if (clearHTMLTags(values?.description)?.length > 500) {
      errors.description = `validationErrors.max500`;
    }
    if (!values.description) {
      errors.description = `validationErrors.required`;
    }

    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    setIsValidate(true);
    const valid = validate(valuesFormik);
    setErrors(valid);
    if (Object.keys(valid).length !== 0) {
      window.scrollTo(0, 0);
      return;
    }
    const input = {
      nameCompany: valuesFormik.nameCompany,
      phoneCompany: valuesFormik.phoneCompany,
      emailCompany: valuesFormik.emailCompany,
      webSiteCompany: valuesFormik.webSiteCompany,
      name: valuesFormik.name,
      email: valuesFormik.email,
      phone: valuesFormik.phone,
      isViber: !!valuesFormik.viber,
      isTelegram: !!valuesFormik.telegram,
      isWhatsapp: !!valuesFormik.whatsApp,
      isAdmin: !!valuesFormik.isAdmin,
      description: values.description,
      fileId: values.fileId
    };
    if (code) {
      await updateBlackListMutation({
        variables: {
          code,
          input
        }
      });
      const url = queryString.stringifyUrl({
        url: getComplaintSentBlackListUrl(code)
      });
      await refetch();
      history.push(url);
      return;
    }
    const { data } = await createQuestionMutation({
      variables: {
        input
      }
    });
    const url = queryString.stringifyUrl({
      url: getComplaintSentBlackListUrl(data.createBlackList.accessCode),
      query: {}
    });
    history.push(url);
  };
  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "blackList",
      title: t("blackList.title"),
      link: addBlackListUrl
    }
  ];
  return (
    <Page pageName={t("blackList.titlePage")} breadcrumbs={breadcrumbs}>
      <div className="page-add">
        <Formik
          initialValues={{
            nameCompany: getValues(blackList?.nameCompany, code),
            phoneCompany: getValues(blackList?.phoneCompany, code),
            emailCompany: getValues(blackList?.emailCompany, code),
            webSiteCompany: getValues(blackList?.webSiteCompany, code),
            name: getValues(blackList?.name, code),
            email: getValues(blackList?.email, code),
            phone: getValues(blackList?.phone, code),
            isViber: getValuesBoolean(blackList?.isViber, code),
            isTelegram: getValuesBoolean(blackList?.isTelegram, code),
            isWhatsapp: getValuesBoolean(blackList?.isWhatsapp, code),
            isAdmin: getValuesBoolean(blackList?.isAdmin, code)
          }}
          onSubmit={onSave}
          validate={isValidate ? validate : () => ({})}
        >
          {({
            errors,
            validateForm,
            setFieldValue,
            values: valuesFormik,
            setValues: setValuesFormik
          }) => (
            <Form>
              <AddBlackListForm
                {...{
                  values,
                  setValues,
                  errors,
                  validateForm,
                  setFieldValue,
                  valuesFormik,
                  setValuesFormik
                }}
              />
              <div className="section-add">
                <div className="row">
                  <div className="col-lg-12 mb-4 text-lg-end">
                    <button name="" className="btn btn-primary">
                      {t("blackList.send")}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Page>
  );
};

AddBlackList.propTypes = {
  blackList: PropTypes.object,
  refetch: PropTypes.func,
  code: PropTypes.string
};
export default AddBlackList;
