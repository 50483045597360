import PropTypes from "prop-types";

import DropDownBlockItem from "./DropDownBlockItem/DropDownBlockItem";

const DropDownBlock = ({ array }) => (
  <div className="faq-items">
    {array.map(({ title, description }, idx) => (
      <DropDownBlockItem key={idx} {...{ title, description }} />
    ))}
  </div>
);

DropDownBlock.propTypes = {
  array: PropTypes.array
};
export default DropDownBlock;
