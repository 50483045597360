/* eslint-disable i18next/no-literal-string */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { lookingForAJobUrl } from "../../../../routes";
import {
  getArrayIfNotEmpty,
  isSelectedAllPostCode,
  isSelectLondon,
  setNewFilters,
  sortByTitle
} from "../../../../utility";
import Input from "../../../layout/Input/Input";
import Link from "../../../layout/Link";
import Loading from "../../../layout/Loading/Loading";
import Radio from "../../../layout/Radio/Radio";
import SideBarDropdown from "../../../layout/SideBarDropdown/SideBarDropdown";

export const allDataQuery = gql`
  query allData {
    categoryProfession {
      id
      title
      professions {
        id
        title
      }
    }
    professions {
      id
      title
    }
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
  }
`;

const LookingJobSideBar = ({ filters, setFilters, totalPosts }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [professions, setProfessions] = useState([]);

  const { loading, data } = useQuery(allDataQuery);

  if (loading) return <Loading />;
  if (!data) return null;
  const validate = async (values) => {
    if (!data) return;
    const cat = getArrayIfNotEmpty(values.categoryProfessionId);

    const listCategory = cat
      ? data.categoryProfession.filter((c) => cat.includes(c.id))
      : data.categoryProfession;
    const newProfesion = [];
    listCategory.map((c) => {
      newProfesion.push(...c.professions);
      return null;
    });

    const newProfessionIds = newProfesion.map((g) => g.id);
    const filterProfesion = (values.professionsId || []).filter((r) =>
      newProfessionIds.includes(r)
    );

    await setProfessions(newProfesion);

    setNewFilters({
      filters,
      newFilters: {
        ...values,
        professionsId: filterProfesion
      },
      setFilters,
      history,
      url: lookingForAJobUrl
    });
  };

  return (
    <>
      <Formik initialValues={filters} validate={validate}>
        {({ setValues, values }) => (
          <>
            <div className="sidebar-block">
              <h6 className="sidebar-title sidebar-title-clear">
                <span>{t("filter")}</span>
                <Link
                  to="#"
                  onClick={async () => {
                    const emptyData = {
                      categoryProfessionId: [],
                      professionsId: [],
                      cityId: null,
                      postIndexs: [],
                      nationalInsurance: [],
                      UTR: [],
                      tools: [],
                      driverLicense: [],
                      cars: [],
                      womenCanAlsoWork: [],
                      findOfferForFamily: [],
                      isAssistantJob: [],
                      iWorkOnWeekends: [],
                      announcementFromAgencies: [],
                      privateAds: [],
                      isLookingJobMinors: [],
                      search: undefined,
                      currentPage: 1
                    };
                    await setFilters(emptyData);
                    setValues(emptyData);
                  }}
                >
                  {t("sideBarFilter.cleanItUp")}
                </Link>
              </h6>
              <Form>
                <SideBarDropdown
                  key="vacancyq"
                  title={t(`sideBarFilter.categoryOfVacancies`)}
                >
                  {data.categoryProfession.map((x, key) => (
                    <div className="form-check" key={key}>
                      <Input
                        type="checkbox"
                        label={t(`categoryProfession.${x.title}`)}
                        key={`categoryProfession.${x.id}`}
                        id={`categoryProfessionId=${x.id}`}
                        value={x.id}
                        name={`categoryProfessionId`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown
                  key="vacancy"
                  title={t(`sideBarFilter.vacancy`)}
                >
                  {sortByTitle(
                    professions.length ? professions : data.professions
                  ).map((x) => (
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        label={t(`profession.${x.title}`)}
                        key={`professions.${x.id}`}
                        id={`professionsId=${x.id}`}
                        value={x.id}
                        name={`professionsId`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown title={t("sideBarFilter.cityInEngland")}>
                  <Radio
                    key="input-checbox"
                    options={data.cities.map((c) => ({
                      id: c.id,
                      value: c.id,
                      title: t(`city.${c.title}`)
                    }))}
                    isAddDiv={true}
                    name="cityInEngland"
                    value={filters?.cityId}
                    onClick={(val) =>
                      setValues({
                        ...values,
                        cityId: val,
                        postIndexs: isSelectLondon(filters.cityId, data.cities)
                          ? values.postIndexs
                          : []
                      })
                    }
                  />
                </SideBarDropdown>
                {isSelectLondon(values.cityId, data.cities) ? (
                  <SideBarDropdown title={t("sideBarFilter.londonPostcodes")}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="allLondon"
                        checked={
                          !values.postIndexs.length ||
                          isSelectedAllPostCode(
                            data.postIndexs
                              .filter((x) => values.postIndexs.includes(x.id))
                              .map((x) => x.title)
                          )
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValues({
                              ...values,
                              postIndexs: data.postIndexs.map((x) => x.id)
                            });
                          } else {
                            setValues({
                              ...values,
                              postIndexs: []
                            });
                          }
                        }}
                      />
                      <label htmlFor="allLondon">
                        {t("sideBarFilter.allLondon")}
                      </label>
                    </div>
                    {data.postIndexs.map((x) => (
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          label={t(`postIndexs.${x.title}`)}
                          key={t(`postIndexs.${x.id}`)}
                          id={`postCode=${x.id}`}
                          value={x.id}
                          name={`postIndexs`}
                        />
                      </div>
                    ))}
                  </SideBarDropdown>
                ) : null}

                <SideBarDropdown
                  isOpenDefault={true}
                  isStaticBlock={true}
                  title={t("sideBarFilter.forEmploymentThereIs")}
                >
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"nationalInsurance"}
                      value={"true"}
                      label={t("sideBarFilter.nationalInsurance")}
                      id={"nationalInsurance"}
                      name={`nationalInsurance`}
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"UTR"}
                      value={"true"}
                      label={t("sideBarFilter.UTR")}
                      id={"UTR"}
                      name={`UTR`}
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"tools"}
                      value={"true"}
                      label={t("sideBarFilter.tools")}
                      id={"tools"}
                      name={`tools`}
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"driverLicense"}
                      value={"true"}
                      label={t("sideBarFilter.driverLicense")}
                      id={"driverLicense"}
                      name={`driverLicense`}
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"cars"}
                      value={"true"}
                      label={t("sideBarFilter.cars")}
                      id={"cars"}
                      name={`cars`}
                    />
                  </div>
                </SideBarDropdown>
                <SideBarDropdown
                  isOpenDefault={true}
                  isStaticBlock={true}
                  title={t("sideBarFilter.lookingForAJob")}
                >
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"findOfferForFamily"}
                      value={"true"}
                      label={t("sideBarFilter.findOfferForFamily")}
                      id={"findOfferForFamily"}
                      name={`findOfferForFamily`}
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"isAssistantJob"}
                      value={"true"}
                      label={t("sideBarFilter.lookingAssistant")}
                      id={"isAssistantJob"}
                      name={`isAssistantJob`}
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      key={"iWorkOnWeekends"}
                      value={"true"}
                      label={t("sideBarFilter.iWorkOnWeekends")}
                      id={"iWorkOnWeekends"}
                      name={`iWorkOnWeekends`}
                    />
                  </div>
                </SideBarDropdown>

                <div className="widget widget-class-additional show">
                  <div className="widget-content mt-0">
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        key={"announcementFromAgencies"}
                        value={"true"}
                        label={t("sideBarFilter.announcementFromAgencies")}
                        id={"announcementFromAgencies"}
                        name={`announcementFromAgencies`}
                      />
                    </div>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        key={"privateAds"}
                        value={"true"}
                        label={t("sideBarFilter.privateAds")}
                        id={"privateAds"}
                        name={`privateAds`}
                      />
                    </div>
                  </div>
                </div>
                <div className="widget widget-class-result show">
                  <p>
                    {totalPosts} {t("sideBarFilter.posts")}
                  </p>
                </div>
              </Form>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

LookingJobSideBar.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalPosts: PropTypes.number,
  typeActive: PropTypes.string
};

export default LookingJobSideBar;
