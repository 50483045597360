/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import createQuestionContact from "../../../graphql/mutation/createQuestionContact";
import { validateEmail } from "../../../utility";
import { socialNetworks, telegramGropUrls } from "../../../utility/constants";
import Modal from "../../layout/Modal";
import SendQuestionContactsForm from "./SendQuestionContactsForm/SendQuestionContacts";

const SendQuestionContacts = () => {
  const [values, setValues] = useState({
    description: "",
    typeQuestion: null
  });
  const { t } = useTranslation();
  const [isPublish, setIsPublish] = useState(false);

  const [createQuestionContactMutation] = useMutation(createQuestionContact);

  const validate = (valuesValidate) => {
    const errors = {};

    if (!values.typeQuestion) {
      errors.typeQuestion = `validationErrors.required`;
    }
    if (!valuesValidate.email || !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }
    return errors;
  };
  const onSave = async (valuesFormik, { setErrors, resetForm }) => {
    const valid = validate(valuesFormik);
    setErrors(valid);

    if (Object.keys(valid).length !== 0) {
      window.scrollTo(0, 0);
      return;
    }

    await createQuestionContactMutation({
      variables: {
        input: {
          link: valuesFormik.link,
          name: valuesFormik.name,
          email: valuesFormik.email,
          phone: valuesFormik.phone,
          isViber: !!valuesFormik.viber,
          isTelegram: !!valuesFormik.telegram,
          isWhatsapp: !!valuesFormik.whatsApp,
          message: values.description,
          title: values.typeQuestion
        }
      }
    });
    resetForm();

    await setValues({
      description: "",
      typeQuestion: null
    });
    setIsPublish(true);
  };

  return (
    <div className="page-section">
      <Formik
        initialValues={{
          link: "",
          name: "",
          email: "",
          phone: "",
          isViber: false,
          isTelegram: false,
          isWhatsapp: false
        }}
        onSubmit={onSave}
        validate={validate}
      >
        {({
          errors,
          validateForm,
          values: valuesFormik,
          setValues: setValuesFormik
        }) => (
          <Form>
            <SendQuestionContactsForm
              {...{
                values,
                setValues,
                errors,
                validateForm,
                valuesFormik,
                setValuesFormik
              }}
            />
          </Form>
        )}
      </Formik>
      <Modal
        isShow={isPublish}
        onClose={() => {
          setIsPublish(false);
        }}
      >
        <div className="block-no-content__image text-center">
          <img
            src="/assets/img//emoji-subscribe-success.svg"
            width="130"
            height="130"
            alt={t("modal.afterAddBlackList")}
          />
        </div>
        <h1 className="thanks-title">{t("modal.messageSent")}</h1>
        <div className="thanks-btn">
          <a href="/" className="btn btn-primary" aria-label={t("modal.home")}>
            {t("modal.home")}
          </a>
        </div>
        <div className="thanks-soc">
          <p>{t("modal.joinGroups")}</p>
          <ul className="soc-links soc-links-thanks">
            <li className="soc-links-instagram">
              <a
                href={socialNetworks.instagram}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-facebook">
              <a
                href={socialNetworks.facebook}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-telegram">
              <a
                href={telegramGropUrls.announcements}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-whatsapp">
              <a
                href="whatsapp://send?phone=+447599683751"
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
            <li className="soc-links-viber">
              <a
                href={socialNetworks.viber}
                target="_blank"
                aria-label={t("modal.socialMedia")}
                rel="noreferrer"
              ></a>
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

SendQuestionContacts.propTypes = {
  editPostData: PropTypes.shape({
    description: PropTypes.string,
    imagesIds: PropTypes.array,
    isAgency: PropTypes.bool,
    title: PropTypes.string,
    linkYouTube: PropTypes.string,
    isSendToEmail: PropTypes.bool,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      isViber: PropTypes.bool,
      isTelegram: PropTypes.bool,
      isWhatsapp: PropTypes.bool
    }),
    announcement: PropTypes.shape({
      cityId: PropTypes.string,
      cityInEngland: PropTypes.string
    })
  }),
  code: PropTypes.string,
  refetch: PropTypes.func
};

SendQuestionContacts.defaultProps = {
  editPostData: {}
};

export default SendQuestionContacts;
