/* eslint-disable max-lines */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { documentsForWorkInEnglandOptions } from "../../../Contants";
import Input from "../../../layout/Input/Input";
import Select from "../../../layout/Select";
import MultiSelect from "../../../layout/Select/MultiSelect";
import CloseLayout from "./CloseLayout";
import DenamicFieldsLayout from "./DenamicFieldsLayout";
import QuestionnaireBasicAndAdditionalEducation from "./QuestionnaireFields/QuestionnaireBasicAndAdditionalEducation";
import QuestionnaireWriteYourProfessionsAndpPlacesOfWork from "./QuestionnaireFields/QuestionnaireWriteYourProfessionsAndpPlacesOfWork";

const QuestionnaireCreateDetaly = ({
  values,
  setValues,
  valuesFormik,
  errors,
  validateForm,
  setValuesFormik
}) => {
  const { t } = useTranslation();
  const constructionDocumentsOptions = [
    {
      id: "nationalInsurance",
      values: "nationalInsurance",
      title: t("questionnaire.nationalInsurance")
    },
    {
      id: "selfEmployedUTR",
      values: "selfEmployedUTR",
      title: t("questionnaire.selfEmployedUTR")
    },
    {
      id: "cscs",
      values: "cscs",
      title: t("questionnaire.cscs")
    },
    {
      id: "ipaf",
      values: "ipaf",
      title: t("questionnaire.ipaf")
    },
    {
      id: "dbs",
      values: "dbs",
      title: t("questionnaire.dbs")
    },
    {
      id: "otherConstruction",
      values: "otherConstruction",
      title: t("questionnaire.otherConstruction")
    }
  ];
  const knowledgeOfEnglishOptions = [
    {
      id: "A1",
      values: "A1",
      title: t("questionnaire.A1")
    },
    {
      id: "А2",
      values: "А2",
      title: t("questionnaire.А2")
    },
    {
      id: "В1",
      values: "В1",
      title: t("questionnaire.В1")
    },
    {
      id: "B2",
      values: "B2",
      title: t("questionnaire.B2")
    },
    {
      id: "C1",
      values: "C1",
      title: t("questionnaire.C1")
    },
    {
      id: "C2",
      values: "C2",
      title: t("questionnaire.C2")
    }
  ];
  const driverLicenseOptions = [
    {
      id: "AM",
      values: "AM",
      title: t("questionnaire.AM")
    },
    {
      id: "A1",
      values: "A1",
      title: t("questionnaire.A1")
    },
    {
      id: "A2",
      values: "A2",
      title: t("questionnaire.A2")
    },
    {
      id: "A",
      values: "A",
      title: t("questionnaire.A")
    },
    {
      id: "B1",
      values: "B1",
      title: t("questionnaire.B1")
    },
    {
      id: "B",
      values: "B",
      title: t("questionnaire.B")
    },
    {
      id: "C1",
      values: "C1",
      title: t("questionnaire.C1")
    },
    {
      id: "C",
      values: "C",
      title: t("questionnaire.C")
    },
    {
      id: "D1",
      values: "D1",
      title: t("questionnaire.D1")
    },
    {
      id: "D",
      values: "D",
      title: t("questionnaire.D")
    },
    {
      id: "BE",
      values: "BE",
      title: t("questionnaire.BE")
    },

    {
      id: "C1E",
      values: "C1E",
      title: t("questionnaire.C1E")
    },
    {
      id: "CE",
      values: "CE",
      title: t("questionnaire.CE")
    },
    {
      id: "D1E",
      values: "D1E",
      title: t("questionnaire.D1E")
    },
    {
      id: "DE",
      values: "DE",
      title: t("questionnaire.DE")
    },
    {
      id: "cod95",
      values: "cod95",
      title: t("questionnaire.cod95")
    }
  ];

  return (
    <div className="section-add">
      <CloseLayout
        title={t("questionnaire.workExperience")}
        description={t("questionnaire.writeYourProfessionsAndpPlacesOfWork")}
      >
        <DenamicFieldsLayout
          values={values}
          errors={errors}
          validateForm={validateForm}
          keyValues="workExperience"
          keyValuesFormik="placeOfWork"
          setValues={setValues}
          valuesFormik={valuesFormik}
          setValuesFormik={setValuesFormik}
          Component={QuestionnaireWriteYourProfessionsAndpPlacesOfWork}
        />
      </CloseLayout>
      <CloseLayout
        title={t("questionnaire.education")}
        description={t("questionnaire.basicAndAdditionalEducation")}
      >
        <DenamicFieldsLayout
          values={values}
          errors={errors}
          validateForm={validateForm}
          keyValues="education"
          keyValuesFormik="nameEducationalInstitution"
          setValues={setValues}
          valuesFormik={valuesFormik}
          Component={QuestionnaireBasicAndAdditionalEducation}
          setValuesFormik={setValuesFormik}
        />
      </CloseLayout>
      <CloseLayout
        title={t("questionnaire.advantageOfMyCandidacy")}
        description={t("questionnaire.additionalInformationAboutYou")}
      >
        <div className="dynamical-field">
          <div className="row align-items-end">
            <div className="col-lg-6 mb-4">
              <Select
                id="inpuknowledgeOfEnglish2"
                aria-label="Default select example"
                label={t("questionnaire.knowledgeOfEnglish")}
                options={knowledgeOfEnglishOptions}
                value={values.knowledgeOfEnglish}
                onChange={(val) =>
                  setValues({ ...values, knowledgeOfEnglish: val })
                }
              />
            </div>
            <div className="col-lg-6 mb-4">
              <MultiSelect
                className="form-select"
                id="inputEducation2"
                aria-label="Default select example"
                placeholder={t("createPost.select")}
                label={t("questionnaire.driverLicense")}
                options={driverLicenseOptions}
                value={values.driverLicense}
                onChange={(val) => setValues({ ...values, driverLicense: val })}
              />
            </div>
            <div className="col-lg-6 mb-4">
              <Select
                className="form-select"
                id="inputEducation2"
                aria-label="Default select example"
                label={t("questionnaire.documentsForWorkInEngland")}
                options={documentsForWorkInEnglandOptions.map((x) => ({
                  ...x,
                  title: t(x.title)
                }))}
                value={values.documentsForWorkInEngland}
                onChange={(val) =>
                  setValues({ ...values, documentsForWorkInEngland: val })
                }
              />
            </div>
            <div className="col-lg-6 mb-4">
              <MultiSelect
                id="constructionDocuments"
                aria-label="Default select example"
                label={t("questionnaire.constructionDocuments")}
                options={constructionDocumentsOptions}
                value={values.constructionDocuments}
                onChange={(val) =>
                  setValues({ ...values, constructionDocuments: val })
                }
              />
            </div>
            <div className="col-12 mb-4">
              <Input
                type="text"
                value={valuesFormik.note}
                id="inputNote"
                name="note"
                label={t("questionnaire.note")}
              />
            </div>
            <div className="col-12 mb-0">
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id="gotHheToolsToDoTheJob"
                  name="gotHheToolsToDoTheJob"
                  label={t("questionnaire.gotHheToolsToDoTheJob")}
                />
              </div>
              <div className="form-check form-check-inline">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id="iHaveCar"
                  name="iHaveCar"
                  label={t("questionnaire.iHaveCar")}
                />
              </div>
              <div className="form-check form-check-inline me-0">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id="isNonSmoker"
                  name="isNonSmoker"
                  label={t("questionnaire.isNonSmoker")}
                />
              </div>
            </div>
          </div>
        </div>
      </CloseLayout>
    </div>
  );
};

QuestionnaireCreateDetaly.propTypes = {
  values: PropTypes.shape({
    driverLicense: PropTypes.array,
    documentsForWorkInEngland: PropTypes.string,
    constructionDocuments: PropTypes.array,
    knowledgeOfEnglish: PropTypes.string
  }),
  errors: PropTypes.object,
  validateForm: PropTypes.func,
  setValues: PropTypes.func,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};
export default QuestionnaireCreateDetaly;
