import Home from "../components/Home";
import Layout from "./Layout/Layout";

const PeopleView = () => (
  <Layout>
    <Home />
  </Layout>
);

export default PeopleView;
