import parse from "html-react-parser";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getTextPreviwe } from "../../../utility/date";
import ContactBlock from "./ContactBlock/ContactBlock";
import ImageGalareyPost from "./ImageGalareyPost/ImageGalareyPost";
import ListData from "./ListData/ListData";
import MapsBlock from "./MapsBlock/MapsBlock";

const Post = ({ post }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-preview-wrap">
        <p className="preview-date">
          {getTextPreviwe(new Date(post.createdAt), t)}
        </p>
        <h1 className="preview-title">{post.title}</h1>
        <ListData post={post} />
        <div className="preview-desc text-break">
          <label className="page-single-label">{t("post.description")}</label>
          {parse(post.description)}
        </div>
        {post.images.length !== 0 ? (
          <div className="page-single-images">
            <ImageGalareyPost images={post.images} />
          </div>
        ) : null}
      </div>
      <MapsBlock address={post?.addressMap} />
      <ContactBlock post={post} />
    </>
  );
};

Post.propTypes = {
  post: PropTypes.object
};
export default Post;
