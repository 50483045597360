import { gql, useQuery } from "@apollo/client";

import Loading from "../../layout/Loading/Loading";
import AddBlackList from "./AddBlackList";

export const blackListByCodeQuery = gql`
  query blackListByCode($code: String) {
    blackListByCode(code: $code) {
      phoneCompany
      nameCompany
      emailCompany
      webSiteCompany
      name
      email
      phone
      isViber
      isTelegram
      isWhatsapp
      isAdmin
      description
      fileId
      createdAt
    }
  }
`;

const AddBlackListUrl = () => {
  const parameters = new URLSearchParams(window.location.search);
  const code = parameters.get("code");

  const { loading, data, refetch } = useQuery(blackListByCodeQuery, {
    variables: {
      code: code || null
    }
  });

  if (loading) return <Loading />;
  return (
    <AddBlackList
      blackList={data?.blackListByCode}
      code={code}
      refetch={refetch}
    />
  );
};

export default AddBlackListUrl;
