/* eslint-disable i18next/no-literal-string */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { questionnairesListUrl } from "../../../../routes";
import {
  getArrayIfNotEmpty,
  isSelectedAllPostCode,
  isSelectLondon,
  setNewFilters
} from "../../../../utility";
import { sexOptions } from "../../../Contants";
import Input from "../../../layout/Input/Input";
import Link from "../../../layout/Link";
import Loading from "../../../layout/Loading/Loading";
import Radio from "../../../layout/Radio/Radio";
import SideBarDropdown from "../../../layout/SideBarDropdown/SideBarDropdown";

export const allDataQuery = gql`
  query allData {
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
    professions {
      id
      title
    }
    categoryProfession {
      id
      title
      professions {
        id
        title
      }
    }
  }
`;

const QuestionnairesListSideBar = ({ filters, setFilters, totalPosts }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data } = useQuery(allDataQuery);
  const [professions, setProfessions] = useState([]);
  if (loading) return <Loading />;
  if (!data) return null;
  const validate = async (values) => {
    if (!data) return;
    const cat = getArrayIfNotEmpty(values.categoryProfessionId);

    const listCategory = cat
      ? data.categoryProfession.filter((c) => cat.includes(c.id))
      : data.categoryProfession;
    const newRubric = [];
    listCategory.map((c) => {
      newRubric.push(...c.professions);
      return null;
    });
    const newRubricIds = newRubric.map((g) => g.id);
    const filterRubruc = (values.professionId || []).filter((r) =>
      newRubricIds.includes(r)
    );
    await setProfessions(newRubric);

    setNewFilters({
      filters,
      newFilters: {
        ...values,
        professionId: filterRubruc
      },
      setFilters,
      history,
      url: questionnairesListUrl
    });
  };
  return (
    <>
      <Formik initialValues={filters} validate={validate} onSubmit={() => {}}>
        {({ setValues, values }) => (
          <>
            <div className="sidebar-block">
              <h6 className="sidebar-title sidebar-title-clear">
                <span>{t("filter")}</span>
                <Link
                  to="#"
                  onClick={async () => {
                    const emptyData = {
                      cityId: null,
                      professionIds: [],
                      categoryProfessionId: [],
                      postIndexIds: [],
                      documentsForWorks: [],
                      sex: [],
                      isCar: [],
                      isTool: [],
                      isWorksOnWeekends: [],
                      isAssistantMaster: [],
                      isDriverLicense: [],
                      isNonSmoker: [],
                      isKnowledgeOfEnglish: [],
                      lookingForJobForCouple: [],
                      lookingForJobForWomen: [],
                      currentPage: 1
                    };

                    await setFilters(emptyData);
                    setValues(emptyData);
                  }}
                >
                  {t("sideBarFilter.cleanItUp")}
                </Link>
              </h6>
              <Form>
                <SideBarDropdown
                  key="cityInEnglandSide"
                  title={t("sideBarFilter.cityInEnglandQuestionnaires")}
                >
                  <Radio
                    key="input-checbox"
                    options={data.cities.map((c) => ({
                      id: c.id,
                      value: c.id,
                      title: t(`city.${c.title}`)
                    }))}
                    isAddDiv={true}
                    name="cityId"
                    value={filters?.cityId}
                    onClick={(val) =>
                      setValues({
                        ...values,
                        cityId: val,
                        postIndexIds: isSelectLondon(
                          filters.cityId,
                          data.cities
                        )
                          ? values.postIndexIds
                          : []
                      })
                    }
                  />
                </SideBarDropdown>
                {isSelectLondon(values.cityId, data.cities) ? (
                  <SideBarDropdown title={t("sideBarFilter.londonPostcodes")}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="allLondon"
                        checked={
                          !values?.postIndexIds?.length ||
                          isSelectedAllPostCode(
                            data.postIndexs
                              .filter((x) =>
                                values?.postIndexIds?.includes(x.id)
                              )
                              .map((x) => x.title)
                          )
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValues({
                              ...values,
                              postIndexIds: data.postIndexs.map((x) => x.id)
                            });
                          } else {
                            setValues({
                              ...values,
                              postIndexIds: []
                            });
                          }
                        }}
                      />
                      <label htmlFor="allLondon">
                        {t("sideBarFilter.allLondon")}
                      </label>
                    </div>
                    {data.postIndexs.map((x) => (
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          label={t(`postIndexs.${x.title}`)}
                          key={t(`postIndexs.${x.id}`)}
                          id={`postCode=${x.id}`}
                          value={x.id}
                          name={`postIndexIds`}
                        />
                      </div>
                    ))}
                  </SideBarDropdown>
                ) : null}
                <SideBarDropdown
                  key="constructionSide"
                  title={t("sideBarFilter.categoryOfVacancies")}
                >
                  {data.categoryProfession.map((category) => (
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        label={t(`categoryProfession.${category.title}`)}
                        key={t(`categoryProfession.${category.id}`)}
                        id={`categoryProfessionId=${category.id}`}
                        value={category.id}
                        name={`categoryProfessionId`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown
                  key="otherProfessionsSide"
                  title={t("sideBarFilter.jobVacancy")}
                >
                  {professions.map((profession) => (
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        label={t(`profession.${profession.title}`)}
                        key={t(`otherProfession.${profession.id}`)}
                        id={`professionId=${profession.id}`}
                        value={profession.id}
                        name={`professionId`}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown key="sexSide" title={t("questionnaire.sex")}>
                  {sexOptions.map((sexOption) => (
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        label={t(sexOption.title)}
                        key={`sex.${sexOption.id}`}
                        id={`sexId=${sexOption.id}`}
                        value={sexOption.id}
                        name={"sex"}
                      />
                    </div>
                  ))}
                </SideBarDropdown>
                <SideBarDropdown
                  key="itHasSide"
                  isOpenDefault={true}
                  isStaticBlock={true}
                  title={t("questionnaire.forEmploymentThereIs")}
                >
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.tool")}
                      key={`toolFilter`}
                      id={`toolFilter`}
                      value="isTool"
                      name="isTool"
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.driverLicenseSideBar")}
                      key={`isDriverLicense`}
                      id={`isDriverLicense`}
                      value="isDriverLicense"
                      name="isDriverLicense"
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.Automobile")}
                      key={`carFilter`}
                      id={`carFilter`}
                      value="isCar"
                      name="isCar"
                    />
                  </div>
                </SideBarDropdown>
                <SideBarDropdown
                  isOpenDefault={true}
                  isStaticBlock={true}
                  key="additionallySide"
                  title={t("questionnaire.lookingForJob")}
                >
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.forTheFamily")}
                      key={`lookingForJobForCoupleFilter`}
                      id={`lookingForJobForCoupleFilter`}
                      value="lookingForJobForCouple"
                      name="lookingForJobForCouple"
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.assistantMaster")}
                      key={`isAssistantMaster`}
                      id={`isAssistantMaster`}
                      value="isAssistantMaster"
                      name="isAssistantMaster"
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.onWeekends")}
                      key={`worksOnEeekendsFilter`}
                      id={`worksOnEeekendsFilter`}
                      value="isWorksOnWeekends"
                      name="isWorksOnWeekends"
                    />
                  </div>
                </SideBarDropdown>
                <SideBarDropdown
                  isOpenDefault={true}
                  key="personalQualitiesSide"
                  title={t("questionnaire.personalQualities")}
                >
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.nonSmoker")}
                      key={`nonSmokerFilter`}
                      id={`nonSmokerFilter`}
                      value="isNonSmoker"
                      name="isNonSmoker"
                    />
                  </div>
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      label={t("questionnaire.knowledgeOfEnglish")}
                      key={`knowledgeOfEnglishFilter`}
                      id={`knowledgeOfEnglishFilter`}
                      value="isKnowledgeOfEnglish"
                      name="isKnowledgeOfEnglish"
                    />
                  </div>
                </SideBarDropdown>
              </Form>
              <div className="widget widget-class-result show d-none d-lg-block">
                <p>
                  {totalPosts} {t("questionnaire.summary")}
                </p>
              </div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
            <div className="sidebar-block">
              <div className="widget widget-banner">Реклама</div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

QuestionnairesListSideBar.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  totalPosts: PropTypes.number,
  maxPrice: PropTypes.number
};

export default QuestionnairesListSideBar;
