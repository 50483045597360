import moment from "moment";

export const hoursWithMinutesFormat = "HH:mm";

export const dateFormat = "YYYY-MM-DD";
export const timeIsoFormat = "HH:mm:ssZ";

export const convertToIsoTime = (date) => moment(date).format(timeIsoFormat);

export const convertToIsoDate = (date) => moment(date).format("YYYY/MM/DD");

export const getFromIsoDate = (date) =>
  date ? moment(date).format(dateFormat) : null;

export const getFromIsoTime = (date) => moment(date, timeIsoFormat);
