/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Hint from "../../../../layout/Hint";
import Select from "../../../../layout/Select";
import UploadImageAndPreview from "../../../../layout/UploadImageAndPreview/UploadImageAndPreview";
import FieldsAddPost, {
  fieldsAddPostTypes
} from "../../CreatePostLayout/FieldsAddPost";

const FirstBlock = ({
  data,
  errors,
  values,
  setValues,
  validateForm,
  valuesFormik,
  setValuesFormik,
  images
}) => {
  const { t } = useTranslation();
  const [rubrics, setRubrics] = useState([]);
  useEffect(() => {
    if (!data?.postAnnouncementCategory) return;
    validateForm();
    const category = data?.postAnnouncementCategory.find(
      ({ id: categoryId }) => categoryId === values?.postAnnouncementCategory
    );
    const rub = category ? category.postAnnouncementRubric : [];

    setRubrics(rub);
    if (!rub.map(({ id }) => id).includes(values.postAnnouncementRubric)) {
      setValues({ ...values, postAnnouncementRubric: null });
    }
  }, [data?.postAnnouncementCategory, values.postAnnouncementCategory]);
  return (
    <>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          errors={errors}
          valuesFormik={valuesFormik}
          type={fieldsAddPostTypes.title}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <Select
          value={values.postAnnouncementCategory}
          options={data.postAnnouncementCategory?.map(
            (postAnnouncementCategory) => ({
              ...postAnnouncementCategory,
              title: t(`announcementCategory.${postAnnouncementCategory.title}`)
            })
          )}
          id="announcementCategory"
          error={errors?.postAnnouncementCategory}
          label={
            <>
              *{t("createPost.announcementCategory")}
              <Hint components={t("hint.announcementCategory")} />
            </>
          }
          onChange={async (val) => {
            await setValues({ ...values, postAnnouncementCategory: val });
            validateForm();
          }}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <Select
          value={values.postAnnouncementRubric}
          options={rubrics.map((rubric) => ({
            ...rubric,
            title: t(`announcementRubric.${rubric.title}`)
          }))}
          id="announcementRubric"
          error={errors?.postAnnouncementRubric}
          label={
            <>
              *{t("createPost.announcementRubric")}
              <Hint components={t("hint.postAnnouncementRubric")} />
            </>
          }
          onChange={async (val) => {
            await setValues({ ...values, postAnnouncementRubric: val });
            validateForm();
          }}
          disabled={!values.postAnnouncementCategory}
        />
      </div>
      <div className="col-lg-12">
        <div className="row">
          <FieldsAddPost
            {...{
              values,
              setValues,
              errors,
              validateForm,
              data,
              isRequairedCity: false,
              type: fieldsAddPostTypes.cityAndPostCode
            }}
          />
        </div>
      </div>
      <div className="col-lg-12 mb-4">
        <FieldsAddPost
          {...{
            errors,
            validateForm,
            setValues,
            values,
            type: fieldsAddPostTypes.description
          }}
        />
      </div>
      <div className="col-lg-6 mb-4">
        <div className="mb-3">
          <FieldsAddPost
            valuesFormik={valuesFormik}
            type={fieldsAddPostTypes.phone}
            errors={errors}
            setValuesFormik={setValuesFormik}
          />
        </div>
        <div className="form-check-inline-wrap" style={{ height: "inherit" }}>
          <FieldsAddPost type={fieldsAddPostTypes.messages} height="d" />{" "}
        </div>
      </div>
      <div className="col-lg-6 mb-4">
        <FieldsAddPost
          valuesFormik={valuesFormik}
          type={fieldsAddPostTypes.email}
          errors={errors}
        />
      </div>
      <div className="col-12 mb-4">
        <UploadImageAndPreview
          title={t("createPost.uploadAPhotoInGalarey")}
          imagesDefault={images}
          setFile={(fileId) =>
            setValues({
              ...values,
              imagesIds: [...values.imagesIds, fileId]
            })
          }
          removeFile={(fileId) => {
            const newImages = values.imagesIds.filter((i) => i !== fileId);
            setValues({
              ...values,
              imagesIds: newImages
            });
          }}
        />
      </div>
    </>
  );
};

FirstBlock.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  validateForm: PropTypes.func,
  data: PropTypes.object,
  valuesFormik: PropTypes.object,
  images: PropTypes.array,
  setValuesFormik: PropTypes.func
};
export default FirstBlock;
